import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as fcl from "@onflow/fcl";
import { Button, Spinner, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FaGlobe } from "react-icons/fa";
import featuredDrops from "../mock/featured.json";
import { toast } from "react-toastify";
import TimerDescription from "../components/TimerDescription";
import "react-toastify/dist/ReactToastify.css";
import { PURCHASENFT_DAPPER } from '../cadence/script/LibraryPass/purchaseNFT_dapper';
import { PURCHASEGROUND_DAPPER } from '../cadence/script/GroundWork29/purchaseGround_dapper';
import { PURCHASEBOAST_DAPPER } from "../cadence/script/Boast/purchaseBoast_dapper";
import { PURCHASESYLLABLES_DAPPER } from "../cadence/script/Syllable/purchaseSyllables_dapper";
import { GET_PUBLISHEDS_WITH_DATA } from '../cadence/script/LibraryPass/getPublishedsWithData';
import { GET_LISTINGS_WITH_DATA } from "../cadence/script/LibraryPass/getListingsWithData";
import { GET_GROUND_WITH_DATA } from "../cadence/script/GroundWork29/getGroundWithData";
import { GET_BOAST_WITH_DATA } from "../cadence/script/Boast/getBoastWithData";
import { GET_Syllables_WITH_DATA } from "../cadence/script/Syllable/getSyllablesWithData";
import { metadataPurchase_ground } from "../cadence/script/GroundWork29/metadataPurchase_ground";

export default function Description() {
  const location = useLocation();
  const navigate = useNavigate();
  const [slide, setSlide] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSuccess, setIsSucess] = useState(false);
  const [show, setShow] = useState(false);
  const [wispShow, setWispShow] = useState(false);
  var countNFT = "";
  var buyButton = "";
  var assetDetail = "";
  var creatorName = "";
  var instagramURL = "";
  var twitterURL = "";
  var websiteURL = "";
  var openseaURL = "";
  var downloadLink = "";
  var ipfsLink = "";
  var proflileImg = "";
  var bookBtn = "";
  const [libraryPass, setLibraryPass] = useState([]);
  const [wisp, setWisp] = useState([]);
  const [nftSerialId, setNftSerialId] = useState(null);
  const [user, setUser] = useState({ loggedIn: null });
  const [isPass, setIsPass] = useState(false);
  const [groundData, setGroundData] = useState([]);
  const [groundShow, setGroundShow] = useState(false);
  const [boast, setBoast] = useState([]);
  const [boastShow, setBoastShow] = useState(false);
  const [syllableShow, setSyllableShow] = useState(false);
  const [syllable, setSyllable] = useState([]);

  let temp = location.pathname.split("/");

  useEffect(() => {
    fcl.currentUser.subscribe(setUser);
    getAccountInfoQuery();

    if (location.state) {
      setNftSerialId(location.state.nftSerialId);
    }

    featuredDrops.map((item) => {
      if (item.param === temp[2]) setSlide(item);
      return null;
    });
  }, []);

  useEffect(() => {
    // getNFTs();
  }, [user]);

  useEffect(() => {    
    // getNFTs();
    console.log("grounData - ", groundData);
  }, [groundData]);

  const getNFTs = async () => {
    if (user.loggedIn === null) return;

    // const res = await fcl.query({
    //   cadence: GET_PUBLISHEDS_WITH_DATA,
    //   args: (arg, t) => [arg(user?.addr, t.Address)],
    // });

    // res.map((r, index) => {
    //   if (r.type === "Genesis Library Pass") {
    //     setIsPass(true);
    //   }
    //   return null;
    // });    

    if(groundData.length > 0){
      const metadata = await fcl.query({
        cadence: metadataPurchase_ground,
        args: (arg, t) => [
          arg('0x90870362be46fb8d', t.Address),
          arg(groundData[0], t.UInt64),
          arg('0x90870362be46fb8d', t.Address),
        ],
      });
  
      console.log('metadata - ', metadata);
    } 
  };

  const getAccountInfoQuery = async () => {
    // const res = await fcl.query({
    //   cadence: GET_LISTINGS_WITH_DATA,
    //   args: (arg, t) => [arg("0x52cbea4e6f616b8e", t.Address)],
    // });

    // var libraryPassArray = [];
    // var wispArray = [];

    // if (res.length > 0) {
    //   res.map((item) => {
    //     if (item.type === "Genesis Library Pass") {
    //       libraryPassArray.push(item);
    //     } else if (item.type === "The Wisp") {
    //       wispArray.push(item);
    //     }
    //   })
    //   setLibraryPass(libraryPassArray);
    //   setWisp(wispArray);
    // }

    fcl.query({
      cadence: GET_GROUND_WITH_DATA,
      args: (arg, t) => [arg("0x00af2ab363fa49e4", t.Address)], //mainnet
    }).then(setGroundData).catch(console.log);

    // fcl.query({
    //   cadence: GET_BOAST_WITH_DATA,
    //   args: (arg, t) => [arg("0x52cbea4e6f616b8e", t.Address)], //mainnet
    // }).then(setBoast).catch(console.log);

    // fcl.query({
    //   cadence: GET_Syllables_WITH_DATA,
    //   args: (arg, t) => [arg("0x52cbea4e6f616b8e", t.Address)], //mainnet
    // }).then(setSyllable).catch(console.log);
  };

  const availableClick = () => {
    navigate("/marketplace");
  };

  async function buyItem() {

    try {
      setIsUploading(true);

      const transaction = await fcl.mutate({
        cadence: PURCHASENFT_DAPPER,
        limit: 999,
        args: (arg, t) => [
          arg('0x03599ddc5b277a6b', t.Address),
          arg('0x52cbea4e6f616b8e', t.Address),
          arg(libraryPass[0].listingId, t.UInt64),
          arg('25.0', t.UFix64)
        ],
        authorizations: [fcl.authz],
      });


      await fcl.tx(transaction).onceSealed().then((res) => {
        console.log(res);
        setIsUploading(false);
        setIsSucess(true);
        toast.success("Successfully purchased!");
        handleClose();
        // removeSaleID(libraryPass[0].listingId);
      }).catch((err) => {
        setIsSucess(false);
        console.log(err);
        setIsUploading(false);
        toast.error(err);
        handleClose();
      });

    } catch (ex) {
      setIsSucess(false);
      console.log(ex);
      toast.error(ex.message);
      handleClose();
      setIsUploading(false);
      setIsSucess(false);
    }

  }

  async function buyWispItem() {

    try {
      setIsUploading(true);

      const transaction = await fcl.mutate({
        cadence: PURCHASENFT_DAPPER,
        limit: 999,
        args: (arg, t) => [
          arg('0x03599ddc5b277a6b', t.Address),
          arg('0x52cbea4e6f616b8e', t.Address),
          arg(wisp[0].listingId, t.UInt64),
          arg('50.0', t.UFix64)
        ],
        authorizations: [fcl.authz],
      });


      await fcl.tx(transaction).onceSealed().then((res) => {
        console.log(res);
        setIsUploading(false);
        setIsSucess(true);
        toast.success("Successfully purchased!");
        handleClose();
        // removeSaleID(wisp[0].listingId);
      }).catch((err) => {
        setIsSucess(false);
        console.log(err);
        setIsUploading(false);
        toast.error(err);
        handleClose();
      });

    } catch (ex) {
      setIsSucess(false);
      console.log(ex);
      toast.error(ex.message);
      handleClose();
      setIsUploading(false);
      setIsSucess(false);
    }

  }

  async function buyGroundItem() {
    try {
      setIsUploading(true);

      const transaction = await fcl.mutate({
        cadence: PURCHASEGROUND_DAPPER,
        limit: 9999,
        args: (arg, t) => [
          arg('0x5b2c835c8f70ca12', t.Address),
          arg(groundData[0].listingId, t.UInt64),
          arg('0x5b2c835c8f70ca12', t.Address),
        ],
        authorizations: [fcl.authz],
      }).catch(err => console.error("Transaction failed: ", err));

      console.log("tx - ", transaction);

      await fcl.tx(transaction).onceSealed().then((res) => {
        console.log(res);
        setIsUploading(false);
        setIsSucess(true);
        toast.success("Successfully purchased!");
        handleClose();
      }).catch((err) => {
        setIsSucess(false);
        console.log(err);
        setIsUploading(false);
        toast.error(err);
        handleClose();
      });

    } catch (ex) {
      setIsSucess(false);
      console.log(ex);
      toast.error(ex.message);
      handleClose();
      setIsUploading(false);
      setIsSucess(false);
    }
  }

  async function buyBoastItem() {
    try {
      setIsUploading(true);

      const transaction = await fcl.mutate({
        cadence: PURCHASEBOAST_DAPPER,
        limit: 999,
        args: (arg, t) => [
          arg('0x03599ddc5b277a6b', t.Address),
          arg('0x52cbea4e6f616b8e', t.Address),
          arg(boast[0].listingId, t.UInt64),
          arg('7.0', t.UFix64)
        ],
        authorizations: [fcl.authz],
      });

      console.log('TX ID -', transaction)

      await fcl.tx(transaction).onceSealed().then((res) => {
        console.log(res);
        setIsUploading(false);
        setIsSucess(true);
        toast.success("Successfully purchased!");
        handleClose();
      }).catch((err) => {
        setIsSucess(false);
        console.log(err);
        setIsUploading(false);
        toast.error(err);
        handleClose();
      });

    } catch (ex) {
      setIsSucess(false);
      console.log(ex);
      toast.error(ex.message);
      handleClose();
      setIsUploading(false);
      setIsSucess(false);
    }

  }

  async function buySyllableItem() {
    try {
      setIsUploading(true);

      const transaction = await fcl.mutate({
        cadence: PURCHASESYLLABLES_DAPPER,
        limit: 999,
        args: (arg, t) => [
          arg('0x03599ddc5b277a6b', t.Address),
          arg('0x52cbea4e6f616b8e', t.Address),
          arg(syllable[0].listingId, t.UInt64),
          arg('9.0', t.UFix64)
        ],
        authorizations: [fcl.authz],
      });

      console.log('TX ID -', transaction)

      await fcl.tx(transaction).onceSealed().then((res) => {
        console.log(res);
        setIsUploading(false);
        setIsSucess(true);
        toast.success("Successfully purchased!");
        handleClose();
      }).catch((err) => {
        setIsSucess(false);
        console.log(err);
        setIsUploading(false);
        toast.error(err);
        handleClose();
      });

    } catch (ex) {
      setIsSucess(false);
      console.log(ex);
      toast.error("Cannot Purchase this nft!");
      handleClose();
      setIsUploading(false);
      setIsSucess(false);
    }

  }

  // Payment modal for Boast
  const handleBoastClose = () => setBoastShow(false);
  const handleBoastShow = () => setBoastShow(true);

  // Payment modal for Ground
  const handleGroundClose = () => setGroundShow(false);
  const handleGroundShow = () => setGroundShow(true);

  // Payment modal for LibraryPass
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Payment modal for Wisp
  const handleWispClose = () => setWispShow(false);
  const handleWispShow = () => setWispShow(true);

  // Payment modal for Syllable
  const handleSyllableClose = () => setSyllableShow(false);
  const handleSyllableShow = () => setSyllableShow(true);

  const clickRead = (type) => {
    navigate("/book", { state: { type: type } });
  };

  if (slide) {
    if (slide.type === -1) {
      creatorName = "MANCAVE PLAYBABES";
      instagramURL = "https://www.instagram.com/mancaveplaybabes/";
      twitterURL = "https://twitter.com/MancavePlaybabe";
      websiteURL = "https://www.mancaveplaybabes.com/";
      openseaURL = "";

      assetDetail = (
        // <img className="m-auto" src=".././black_back_pass.png" alt="" width="60%" height="auto" />
        <img className="m-auto" src=".././BOAST_MAGAZINE_ISSUE6.png" alt="" width="60%" height="auto" />
      );

      proflileImg = (
        <img src="/mancave_profile.png" className="profile_img" />
      );

      bookBtn = (
        <Button variant="primary" className="font-weight-bold"
          onClick={() => clickRead("boast")} size="lg">
          Read
        </Button>
      );

      countNFT = <p className="text-white">88 Pages</p>;

      boast.length === 0
        ? (buyButton = (
          <div className="sold-button text-center text-danger font-weight-bold mt-3 p-3">
            SOLD OUT
          </div>
        ))
        : (buyButton = (
          <Button
            className="green-button text-dark font-weight-bold mt-3 p-3 w-100"
            onClick={handleBoastShow}
          >
            BUY NOW
          </Button>
        ));

    } else if (slide.type === 0) {
      creatorName = "Judith Brittany Wallace";
      instagramURL = "https://www.instagram.com/wellnesssbritt/";
      twitterURL = "https://twitter.com/wellnesssbritt";
      websiteURL = "https://linktr.ee/wellnesssbritt";
      openseaURL = "https://rarible.com/wellnesssbritt";

      assetDetail = (
        <img className="m-auto" src=".././feature-01.png" alt="" width="60%" height="auto" />
      );

      proflileImg = (
        <img src="/author_template_0-00-10-18.png" className="profile_img" />
      );

      bookBtn = (
        <Button variant="primary" className="font-weight-bold"
          onClick={() => clickRead("groundwork29")} size="lg">
          Read
        </Button>
      );

      countNFT = <p className="text-white">196 Pages</p>;

      groundData.length === 0
        ? (buyButton = (
          <div className="sold-button text-center text-danger font-weight-bold mt-3 p-3">
            SOLD OUT
          </div>
        ))
        : (buyButton = (
          <Button
            className="green-button text-dark font-weight-bold mt-3 p-3 w-100"
            onClick={handleGroundShow}
          >
            BUY NOW
          </Button>
        ));

    } else if (slide.type === 1) {
      creatorName = "Published NFT";
      instagramURL = "https://www.instagram.com/publishednft/";
      twitterURL = "https://twitter.com/publishednft";
      websiteURL = "https://publishednft.io/";
      openseaURL = "";

      // countNFT = <p className="green-font">{purchasedCount1} of 10K</p>;
      if (nftSerialId) {
        countNFT = <p className="green-font">{nftSerialId} of 10K</p>;
      }

      libraryPass.length === 0
        ? (buyButton = (
          <div className="sold-button text-center text-danger font-weight-bold mt-3 p-3">
            SOLD OUT
          </div>
        ))
        : (buyButton = (
          <Button
            className="green-button text-dark font-weight-bold mt-3 p-3 w-100"
            onClick={handleShow}
          >
            BUY NOW
          </Button>
        ));

      assetDetail = (
        <video width="100%" height="auto" controls autoPlay>
          <source src=".././NFT Library Pass Final.mp4" type="video/mp4" />
        </video>
      );

      downloadLink = (
        <a href="https://crustwebsites.net/ipfs/bafybeih4udfjcdrwhbga66ucddajuj7iekoicl4ks7h72jdcrkll2ftvpy">
          <img
            className="me-2"
            alt=""
            src="/download-logo.png"
            width="40"
            height="40"
          />
        </a>
      );

      ipfsLink = (
        <a href="https://ipfs-scan.io/?cid=bafybeih4udfjcdrwhbga66ucddajuj7iekoicl4ks7h72jdcrkll2ftvpy">
          <img className="me-2" alt="" src="/ipfs.png" width="30" height="30" />
        </a>
      );
      proflileImg = (
        <img src="/published_proflile_0-00-00-00.png" className="profile_img" />
      );
    } else if (slide.type === 2) {
      creatorName = "Abhay Sehgal";
      instagramURL = " https://www.instagram.com/artbysehgal/";
      twitterURL = "https://twitter.com/artbysehgal";
      websiteURL = "https://www.abhaysehgal.com/illustrations";
      openseaURL = "https://opensea.io/AbhaySehgal?tab=created";

      // countNFT = <p className="green-font">{purchasedCount2} of 102</p>;
      if (nftSerialId) {
        countNFT = <p className="green-font">{nftSerialId} of 100</p>;
      }

      wisp.length === 0
        ? (buyButton = (
          <div className="sold-button text-center text-danger font-weight-bold mt-3 p-3">
            SOLD OUT
          </div>
        ))
        : (buyButton = (
          <Button
            className="green-button text-dark font-weight-bold mt-3 p-3 w-100"
            onClick={handleWispShow}
          >
            BUY NOW
          </Button>
        ));

      assetDetail = (
        <video width="100%" height="auto" controls autoPlay>
          <source src=".././second-type.mp4" type="video/mp4" />
        </video>
      );

      downloadLink = (
        <a href="https://crustwebsites.net/ipfs/bafybeidzzl4gim3n36s5gc3vezacofso6pivaczorthzgodiz2pgjum6by">
          <img
            className="me-2"
            alt=""
            src="/download-logo.png"
            width="40"
            height="40"
          />
        </a>
      );

      ipfsLink = (
        <a href="https://ipfs-scan.io/?cid=bafybeidzzl4gim3n36s5gc3vezacofso6pivaczorthzgodiz2pgjum6by">
          <img className="me-2" alt="" src="/ipfs.png" width="30" height="30" />
        </a>
      );
      proflileImg = (
        <img
          src="/abhay_sehgal_proflile_0-00-00-00.png"
          alt=""
          className="profile_img"
        />
      );
    } else if (slide.type === 3) {
      creatorName = "Sanjay Rout";
      instagramURL = "https://www.neetibisht.in/";
      twitterURL = "https://twitter.com/drsskro";
      websiteURL = "https://linktr.ee/Drsanjayrout";
      openseaURL = "https://opensea.io/MintSongs-Collection";

      assetDetail = (
        <img className="m-auto" src=".././feature-02.png" alt="" width="100%" height="auto" />
      );

      proflileImg = (
        <img src="/dresskro_profile-pic.png" className="profile_img" />
      );

      bookBtn = (
        <Button variant="primary" className="font-weight-bold mt-3"
          onClick={() => clickRead("delightful")} size="lg">
          Listen
        </Button>
      );
    } else if (slide.type === 4) {
      creatorName = "Sanjay Rout";
      instagramURL = "https://www.neetibisht.in/";
      twitterURL = "https://twitter.com/drsskro";
      websiteURL = "https://linktr.ee/Drsanjayrout";
      openseaURL = "https://opensea.io/MintSongs-Collection";

      assetDetail = (
        <img className="m-auto" src=".././feature-03.png" alt="" width="100%" height="auto" />
      );

      proflileImg = (
        <img src="/dresskro_profile-pic.png" className="profile_img" />
      );

      bookBtn = (
        <Button variant="primary" className="font-weight-bold"
          onClick={() => clickRead("humanminds")} size="lg">
          Read
        </Button>
      );
    } else if (slide.type === 5) {
      creatorName = "Greg R. Fishbone";
      instagramURL = "";
      twitterURL = "https://twitter.com/tem2";
      websiteURL = "https://gfishbone.com/";
      openseaURL = "";

      assetDetail = (
        <img className="m-auto" src=".././feature-04.png" alt="" width="60%" height="auto" />
      );

      proflileImg = (
        <img src="/Greg.jpg" className="profile_img" />
      );

      bookBtn = (
        <Button variant="primary" className="font-weight-bold"
          onClick={() => clickRead("nightvision")} size="lg">
          Read
        </Button>
      );
    } else if (slide.type === 6) {
      creatorName = "Rainy Dayz";
      instagramURL = "";
      twitterURL = "https://twitter.com/Rydzproductions";
      websiteURL = "https://rainydayz.io/";
      openseaURL = "https://magiceden.io/creators/rainydayz";

      assetDetail = (
        <img className="m-auto" src=".././feature-05.png" alt="" width="60%" height="auto" />
      );

      proflileImg = (
        <img src="/rainydays.png" className="profile_img" />
      );

      bookBtn = (
        <Button variant="primary" className="font-weight-bold"
          onClick={() => clickRead("element")} size="lg">
          Read
        </Button>
      );
    } else if (slide.type === 7) {
      creatorName = "Tom Ang";
      instagramURL = "https://www.instagram.com/tomangphoto/";
      twitterURL = "https://twitter.com/Tom_Ang";
      websiteURL = "https://tomang.com/";
      openseaURL = "";

      assetDetail = (
        <img className="m-auto" src=".././feature-06.png" alt="" width="60%" height="auto" />
      );

      proflileImg = (
        <img src="/Tom_Ang_proflile_0-00-00-00.png" className="profile_img" />
      );

      bookBtn = (
        <Button variant="primary" className="font-weight-bold"
          onClick={() => clickRead("syllables")} size="lg">
          Read
        </Button>
      );

      countNFT = <p className="text-white">57 Pages</p>;

      syllable.length === 0
        ? (buyButton = (
          <div className="sold-button text-center text-danger font-weight-bold mt-3 p-3">
            SOLD OUT
          </div>
        ))
        : (buyButton = (
          <Button
            className="green-button text-dark font-weight-bold mt-3 p-3 w-100"
            onClick={handleSyllableShow}
          >
            BUY NOW
          </Button>
        ));
    }
  }

  let utcDate = new Date(Date.UTC(2023, 1, 15, 19, 0, 0));

  return (
    <>
    <div className="container">
      {slide && (
        <>
          <div className="row">
            <div className="col-md-7 mt-5 d-flex justify-content-center align-items-center">
              <div className="m-auto">
                <div className="d-flex m-auto">
                  {assetDetail}
                </div>

                <div className="d-flex justify-content-center mt-3 mb-5">
                  <div>
                    <a target="_blank" href="https://www.flowty.io/collection/0x52cbea4e6f616b8e/PublishedNFT/activity/rentals">
                      <img
                        className="me-2"
                        alt=""
                        src="/white-flowty.png"
                        width="20"
                        height="25"
                      />
                    </a>
                    <a href="https://evaluate.xyz/swap" target="_blank">
                      <img
                        className="me-2"
                        alt=""
                        src="/nft_transfer_logo.png"
                        width="50"
                        height="45"
                      />
                    </a>
                    {ipfsLink}
                    <a href="https://www.flowdiver.io/contract/A.52cbea4e6f616b8e.LibraryPass" target="_blank">
                      <img
                        className="me-2"
                        alt=""
                        src="/built-on-flow.png"
                        width="150"
                        height="30"
                      />
                    </a>
                  </div>
                </div>

                {isPass && (slide.type === 0 || slide.type === -1 || slide.type === 7) &&
                  <div className="d-flex justify-content-center mt-3 mb-5">
                    {bookBtn}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-5">
              <div className="me-2 description-pad mt-5 mb-5 p-3">
                <h2 className="mb-2">{slide.title}</h2>

                {slide.type === -1 &&
                  <>
                    <div className='d-flex'>
                      <div className='col'>
                        <p className='font-weight-bold mb-0'>RELEASE DATE</p>
                        <p className='green-font mb-0'>2/15/2023</p>
                        <p className="text-white">eMag</p>
                      </div>
                      <div className='col'>
                        <p className='font-weight-bold mb-0'>EDITION # 1</p>
                        <p className='font-weight-bold mb-0'>ISSUE #6</p>
                        {countNFT}
                      </div>
                    </div>

                    {/* <TimerDescription fontStyle="font-heading" deadline={utcDate} /> */}
                  </>
                }

                {slide.type === 0 &&
                  <>
                    <div className='d-flex'>
                      <div className='col'>
                        <p className='font-weight-bold mb-0'>RELEASE DATE</p>
                        <p className='green-font mb-0'>2/06/2023</p>
                        <p className="text-white">eBook</p>
                      </div>
                      <div className='col'>
                        <p className='font-weight-bold mb-0'>EDITION # 1</p>
                        <p className='font-weight-bold mb-0'>SERIES # 1</p>
                        {countNFT}
                      </div>
                    </div>
                  </>
                }
                {slide.type === 7 &&
                  <>
                    <div className='d-flex'>
                      <div className='col'>
                        <p className='font-weight-bold mb-0'>RELEASE DATE</p>
                        <p className='green-font mb-0'>7/19/2023</p>
                        <p className="text-white">eBook</p>
                      </div>
                      <div className='col'>
                        <p className='font-weight-bold mb-0'>EDITION # 1</p>
                        {countNFT}
                      </div>
                    </div>
                  </>
                }

                <p className="font-weight-bold mt-5">DESCRIPTION</p>
                <p className="mb-5" style={{ whiteSpace: "pre-line" }}>
                  {slide.description}
                </p>

                {slide.type === 2 && (
                  <>
                    <p className="font-weight-bold">ARTIST BIO</p>
                    <p style={{ whiteSpace: "pre-line" }}>
                      Abhay Sehgal is an Indian-born contemporary artist who
                      focuses on the style of Surrealism by interpreting dreams
                      and psychology.
                      <br />
                      He is an artist residing in Miami and has graduated from
                      the School of the art Institute of Chicago.
                      <br />
                      Abhay executes his thought process through illustrations
                      and animations representing the ideologies of fear and
                      fantasies.
                      <br />
                      Creating works that indulge the minds of all and bring
                      reality to the forefront of nature’s fantasy.
                      <br />
                      Originally Abhay’s passion lied in Visual Effects but with
                      time he has been exploring more mediums in order to create
                      the perfect blend of art and technology.
                      <br />
                    </p>
                  </>
                )}

                <div className="row">
                  <div className="col-md-6">
                    <p className="font-weight-bold">PRICE</p>
                    <div className="d-flex">
                      <h5 className="green-border font-weight-bold p-3 me-3 mb-0 pt-4">
                        {slide.cost}
                      </h5>
                      <div className="white-border font-weight-bold p-3">
                        <p className="font-sm mb-1">TOTAL CREATED</p>
                        <h5 className="mb-0">
                          {slide.totalCount}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="d-flex">
                      {slide.type === 2 ?
                        <p className="font-weight-bold me-2">ARTIST</p>
                        :
                        <p className="font-weight-bold me-2">AUTHOR</p>
                      }
                      <a className="me-2 text-white" href={twitterURL} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                      <a className="me-2 text-white" href={instagramURL} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                      <a className="me-2 text-white" href={websiteURL} target="_blank" rel="noreferrer">
                        <FaGlobe className="text-white mb-1" />
                      </a>
                      <a className="me-4 text-white mb-1" href={openseaURL} target="_blank" rel="noopener noreferrer"
                      >
                        <img src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Transparent%20White.svg"
                          alt="" width="16px" height="16px" style={{ marginBottom: "3px" }} />
                      </a>
                    </div>

                    <div className="d-flex align-items-center">
                      {proflileImg}
                      <p className="mx-2">{creatorName}</p>
                    </div>
                  </div>
                </div>

                {buyButton}

                <Button
                  className="white-border-button text-white font-weight-bold mt-3 p-3"
                  onClick={availableClick}
                >
                  VIEW ALL AVAILABLE
                </Button>
              </div>
            </div>
          </div>

          <Modal show={boastShow} onHide={handleBoastClose} centered backdrop={false}>
            <Modal.Header closeVariant='white'>
              <Modal.Title>
                {isUploading &&
                  <h4 className='font-weight-bold'>
                    Payment Processing
                  </h4>
                }

                {!isUploading && !isSuccess &&
                  <h4 className='font-weight-bold'>
                    Payment Request
                  </h4>
                }

                {!isUploading && isSuccess &&
                  <h4 className='font-weight-bold'>
                    Payment Complete
                  </h4>
                }
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className='text-center'>
              <p className='font-weight-bold'>
                {slide.title}
              </p>
              {isUploading &&
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <p>
                    Your payment has been submitted and your transaction is being confirmed on the Flow blockchain.
                  </p>
                </>
              }

              {!isUploading && !isSuccess &&
                <p>
                  Please submit your payment to buy nft.
                </p>
              }

              {!isUploading && isSuccess &&
                <p>
                  Your payment has completed and your transaction has been confirmed on the Flow blockchain.
                </p>
              }

            </Modal.Body>

            <Modal.Footer>
              {!isUploading && !isSuccess &&
                <>
                  <Button variant="primary" onClick={buyBoastItem}>
                    Submit
                  </Button>
                  <Button variant="primary" onClick={handleBoastClose}>
                    Close
                  </Button>
                </>
              }

              {!isUploading && isSuccess &&
                <Button variant="primary" onClick={handleBoastClose}>
                  Close
                </Button>
              }
            </Modal.Footer>
          </Modal>

          <Modal show={groundShow} onHide={handleGroundClose} centered backdrop={false}>
            <Modal.Header closeVariant='white'>
              <Modal.Title>
                {isUploading &&
                  <h4 className='font-weight-bold'>
                    Payment Processing
                  </h4>
                }

                {!isUploading && !isSuccess &&
                  <h4 className='font-weight-bold'>
                    Payment Request
                  </h4>
                }

                {!isUploading && isSuccess &&
                  <h4 className='font-weight-bold'>
                    Payment Complete
                  </h4>
                }
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className='text-center'>
              <p className='font-weight-bold'>
                {slide.title}
              </p>
              {isUploading &&
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <p>
                    Your payment has been submitted and your transaction is being confirmed on the Flow blockchain.
                  </p>
                </>
              }

              {!isUploading && !isSuccess &&
                <p>
                  Please submit your payment to buy nft.
                </p>
              }

              {!isUploading && isSuccess &&
                <p>
                  Your payment has completed and your transaction has been confirmed on the Flow blockchain.
                </p>
              }

            </Modal.Body>

            <Modal.Footer>
              {!isUploading && !isSuccess &&
                <>
                  <Button variant="primary" onClick={buyGroundItem}>
                    Submit
                  </Button>
                  <Button variant="primary" onClick={handleGroundClose}>
                    Close
                  </Button>
                </>
              }

              {!isUploading && isSuccess &&
                <Button variant="primary" onClick={handleGroundClose}>
                  Close
                </Button>
              }
            </Modal.Footer>
          </Modal>

          <Modal show={syllableShow} onHide={handleSyllableClose} centered backdrop={false}>
            <Modal.Header closeVariant='white'>
              <Modal.Title>
                {isUploading &&
                  <h4 className='font-weight-bold'>
                    Payment Processing
                  </h4>
                }

                {!isUploading && !isSuccess &&
                  <h4 className='font-weight-bold'>
                    Payment Request
                  </h4>
                }

                {!isUploading && isSuccess &&
                  <h4 className='font-weight-bold'>
                    Payment Complete
                  </h4>
                }
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className='text-center'>
              <p className='font-weight-bold'>
                {slide.title}
              </p>
              {isUploading &&
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <p>
                    Your payment has been submitted and your transaction is being confirmed on the Flow blockchain.
                  </p>
                </>
              }

              {!isUploading && !isSuccess &&
                <p>
                  Please submit your payment to buy nft.
                </p>
              }

              {!isUploading && isSuccess &&
                <p>
                  Your payment has completed and your transaction has been confirmed on the Flow blockchain.
                </p>
              }

            </Modal.Body>

            <Modal.Footer>
              {!isUploading && !isSuccess &&
                <>
                  <Button variant="primary" onClick={buySyllableItem}>
                    Submit
                  </Button>
                  <Button variant="primary" onClick={handleSyllableClose}>
                    Close
                  </Button>
                </>
              }

              {!isUploading && isSuccess &&
                <Button variant="primary" onClick={handleSyllableClose}>
                  Close
                </Button>
              }
            </Modal.Footer>
          </Modal>

          <Modal show={show} onHide={handleClose} centered backdrop={false}>
            <Modal.Header closeVariant="white">
              <Modal.Title>
                {isUploading && (
                  <h4 className="font-weight-bold">Payment Processing</h4>
                )}

                {!isUploading && !isSuccess && (
                  <h4 className="font-weight-bold">Payment Request</h4>
                )}

                {!isUploading && isSuccess && (
                  <h4 className="font-weight-bold">Payment Complete</h4>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <p className="font-weight-bold">{slide.title}</p>
              {isUploading && (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <p>
                    Your payment has been submitted and your transaction is
                    being confirmed on the Flow blockchain.
                  </p>
                </>
              )}

              {!isUploading && !isSuccess && (
                <p>Please submit your payment to buy nft.</p>
              )}

              {!isUploading && isSuccess && (
                <p>
                  Your payment has completed and your transaction has been
                  confirmed on the Flow blockchain.
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              {isUploading && (
                <Button variant="primary" disabled>
                  Close
                </Button>
              )}

              {!isUploading && !isSuccess && (
                <>
                  <Button variant="primary" onClick={buyItem}>
                    Submit
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Close
                  </Button>
                </>
              )}

              {!isUploading && isSuccess && (
                <Button variant="primary" onClick={handleClose}>
                  Close
                </Button>
              )}
            </Modal.Footer>
          </Modal>

          <Modal show={wispShow} onHide={handleWispClose} centered backdrop={false}>
            <Modal.Header closeButton closeVariant="white">
              <Modal.Title>
                {isUploading && (
                  <h4 className="font-weight-bold">Payment Processing</h4>
                )}

                {!isUploading && !isSuccess && (
                  <h4 className="font-weight-bold">Payment Request</h4>
                )}

                {!isUploading && isSuccess && (
                  <h4 className="font-weight-bold">Payment Complete</h4>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <p className="font-weight-bold">{slide.title}</p>
              {isUploading && (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <p>
                    Your payment has been submitted and your transaction is
                    being confirmed on the Flow blockchain.
                  </p>
                </>
              )}

              {!isUploading && !isSuccess && (
                <p>Please submit your payment to buy nft.</p>
              )}

              {!isUploading && isSuccess && (
                <p>
                  Your payment has completed and your transaction has been
                  confirmed on the Flow blockchain.
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              {isUploading && (
                <Button variant="primary" disabled>
                  Close
                </Button>
              )}

              {!isUploading && !isSuccess && (
                <>
                  <Button variant="primary" onClick={buyWispItem}>
                    Submit
                  </Button>
                  <Button variant="primary" onClick={handleWispClose}>
                    Close
                  </Button>
                </>
              )}

              {!isUploading && isSuccess && (
                <Button variant="primary" onClick={handleWispClose}>
                  Close
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
    </>
  );
}
