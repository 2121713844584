import { React, useRef, useEffect, useState } from 'react';
import '../styles/home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Carousel from 'react-multi-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-multi-carousel/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, Link } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();

  const clickDetail = (type) => {
    navigate("/description/" + type, { state: { type: type } });
  };

  return (
    <div className='banner-container'>
      <div className='container'>
        <div id="banner">
          <div className="title-sm">
            THE FUTURE OF E-BOOKS
          </div>
          <div className="title">
            The Best Web3 Marketplace to Buy, Sell, and Trade your eBooks
          </div>
          <p className="content">
            Published NFT is a web3 publishing house built on the Flow blockchain, where authors can publish
            <strong> eBooks, Audiobooks, Comics, Magazines, Articles,</strong> etc.
          </p>
          <div className="btn-container d-flex flex-column flex-sm-row">
            <button className="btn me-sm-4" id="btn-gotoBookstore" onClick={() => clickDetail('wisp')}>
              Storyteller
              <img src="./img/up-right-arrow.png" alt="" />
            </button>
            <button className="btn" id="btn-watchVideo"
            onClick={ () => navigate("/description/librarypass", { state: { type: "librarypass" } }) }>
              <img src="librarypass_icon.png" alt="" />
              Library Pass
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function Card({ color, imgSrc, name, author, price }) {
  return (
    <div className="card-custom mx-2">
      <div className="color-rectangle" style={{ backgroundColor: color }}></div>
      <div className="card-content">
        <div style={{ width: "200px", height: "298px", display: "flex", alignItems: "center" }}>
          <img src={imgSrc} alt="" style={{ width: "200px", height: "298px" }} />
        </div>
        <p className="tag-name">{name}</p>
        <p className="tag-author">by {author}</p>
        <p className="tag-price">${price}</p>
      </div>
    </div>
  );
}

const CustomLeftArrow = ({ onClick, className, type }) => (
  <button
    onClick={onClick}
    className={className}
    style={{
      background: "rgb(8,11,19)",
      fontSize: '18px',
      cursor: 'pointer',
      zIndex: 10,
      border: type === 1 ? '2px solid rgb(44,139,40)' : '2px solid gray',
      borderRadius: '50%',
      width: '35px',
      height: '35px',
      marginTop: '1rem',
      marginBottom: '1rem',
      color: type === 1 ? 'rgb(44,139,40)' : 'gray',
      marginRight: type === 1 ? "275px" : ""
    }}
  >
    ‹
  </button>
);

const CustomRightArrow = ({ onClick, type }) => (
  <button
    onClick={onClick}
    style={{
      background: "rgb(8,11,19)",
      fontSize: '18px',
      cursor: 'pointer',
      zIndex: 10,
      border: type === 1 ? '2px solid rgb(44,139,40)' : '2px solid gray',
      borderRadius: '50%',
      width: '35px',
      height: '35px',
      marginTop: '1rem',
      marginBottom: '1rem',
      color: type === 1 ? 'rgb(44,139,40)' : 'gray'
    }}
  >
    ›
  </button>
);

const DropItem = ({ imgSrc, title, badge, author, description, price, buttonText, link }) => {
  const navigate = useNavigate();

  const clickDetail = (type) => {
    navigate("/description/" + type, { state: { type: type } });
  };

  return (
    <div className="drop-item mb-3 d-flex flex-column flex-lg-row">
      <div className="drop-img me-lg-4">
        <img src={imgSrc} alt={title} style={{ cursor: "pointer" }} 
        onClick={() => clickDetail(link)}
        />
      </div>
      <div className="drop-content">
        <div className="title text-align-lg-center d-flex flex-column flex-lg-row align-items-center">
          <div style={{ cursor: "pointer" }} onClick={() => clickDetail(link)}>
            {title}
          </div>
          <div className="badge">{badge}</div>
        </div>
        <p>by {author}</p>
        <p>{description}</p>
      </div>
      <div className="drop-price">{price}</div>
      <div className="btn-container">
        <button className="btn btn-available" onClick={() => clickDetail(link)}>
          {buttonText} <img src="./img/up-right-arrow.png" alt="Arrow" />
        </button>
      </div>
    </div>
  );
}

const DropItemContainer = () => {
  const items = [
    {
      imgSrc: "feature-06.png",
      title: "49 Syllables On Life",
      badge: "eBooks",
      author: "Tom Ang",
      description: '"49 Syllables on Life" is a unique marriage of words and digital art. The images were...',
      price: "$9.00",
      buttonText: "Available",
      link: "syllables"
    },
    {
      imgSrc: "BOAST_MAGAZINE_ISSUE6.png",
      title: "Boast Magazine Issue #6",
      badge: "Magazines",
      author: "MANCAVE PLAY",
      description: '"With a mission to recall the days of glamour, Mancave Playbabes brings the perfect ...',
      price: "$7.00",
      buttonText: "Available",
      link: "boast6"
    },
    {
      imgSrc: "feature-01.png",
      title: "Groundwork 29",
      badge: "eBooks",
      author: "Brittany Wallace",
      description: '"The life of a student athlete competing in the NCAA is a lifestyle that is glorified ...',
      price: "$10.00",
      buttonText: "Available",
      link: "groundwork29"
    },
    // {
    //   imgSrc: "black_back_pass.png",
    //   title: "Genesis Library Pass",
    //   badge: "Passes",
    //   author: "Published NFT",
    //   description: '"Library Passes are no longer those ordinary pieces of plastic you have to bring to ...',
    //   price: "$25.00",
    //   buttonText: "Available",
    //   link: "/description/1"
    // },
    // {
    //   imgSrc: "wilo_with_play.png",
    //   title: "Will O' The Wisp",
    //   badge: "Stories",
    //   author: "Abhay Sehgal",
    //   description: '"I am creating a series of works based on my furry character The Emotional Yeti ...',
    //   price: "$50.00",
    //   buttonText: "Available",
    //   link: "/description/wisp"
    // }
  ];

  return (
    <div className="drop-item-container mb-4 px-sm-5">
      {items.map((item, index) => (
        <DropItem key={index} {...item} />
      ))}
    </div>
  );
}

function CardUpcoming({ imgSrc, name, author, price, index, currentSlide }) {
  // console.log(((Math.abs(currentSlide) % 8) + 3) % 8, currentSlide, index);
  let scaled = index === (Math.abs(currentSlide) + 3) % 8 || ((currentSlide == 13 || currentSlide == 5) && index == 8);
  return (
    <>
      <div className="card-upcoming card-custom mx-2" style={{ transform: scaled ? "scale(1.5)" : "scale(1)", paddingTop: '60px', transition: 'transform ease-in 300ms', paddingLeft: scaled ? "100px" : "", paddingRight: scaled ? "100px" : "" }}>
        <img src={imgSrc} alt="" style={{ borderRadius: '5px', width: '170px', height: '250px' }} />

        <div style={{ textAlign: 'center', width: '200px', fontSize: '12px', display: scaled ? "block" : "none" }}>
          {name}
          <h6 style={{ color: 'gray', fontSize: '10px' }}>by {author}</h6>
        </div>
      </div>
    </>

  );
}

const Upcoming = () => {
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };

  const handleAfterChange = (previousSlide, { currentSlide }) => {
    console.log(currentSlide);
    setCurrentSlide(currentSlide);
  };

  return (
    <div className="container section" id="section-upcoming-releases">
      <div className="background"></div>
      <div className="content">
        <div className="section-title">Upcoming Releases</div>
        <p className="section-description text-white">
          Unlock new worlds, rewards and gifts with our upcoming releases — where every eBook adds to the piece of the story.
        </p>
      </div>
      <div className="d-flex justify-content-center align-items-center" id="carousel-control-group" style={{ position: 'relative', top: '214px' }}>
        <CustomLeftArrow onClick={() => carouselRef.current && carouselRef.current.previous()} className="margin-carousel-button" type={1}>Previous</CustomLeftArrow>
        <CustomRightArrow onClick={() => carouselRef.current && carouselRef.current.next()} type={1}>Next</CustomRightArrow>
      </div>
      <div className='w-100'>
        <Carousel
          ref={carouselRef}
          responsive={responsive}
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          showDots={true}
          customLeftArrow={<></>}
          customRightArrow={<></>}
          centerSlidePercentage={70}
          afterChange={handleAfterChange}
        >

          <CardUpcoming
            imgSrc="feature-05.png"
            name="The Element"
            author="Rainy Dayz"
            price="9.00"
            currentSlide={currentSlide}
            index={1}
          />
          <CardUpcoming
            imgSrc="feature-09.png"
            name="Decoding Indian Economy"
            author="Goenkans"
            price="9.00"
            currentSlide={currentSlide}
            index={2}
          />
          <CardUpcoming
            imgSrc="feature-04.png"
            name="NIGHT vision"
            author="Greg R. Fishbone"
            price="9.00"
            currentSlide={currentSlide}
            index={3}
          />
          <CardUpcoming
            imgSrc="feature-02.png"
            name="Delightful Novella"
            author="Sanjay Rout"
            price="9.00"
            currentSlide={currentSlide}
            index={4}
          />
          <CardUpcoming
            imgSrc="feature-07.png"
            name="Dansuki Village"
            author="Elisha Arowojobe"
            price="9.00"
            currentSlide={currentSlide}
            index={5}
          />
          <CardUpcoming
            imgSrc="feature-08.png"
            name="Notes from nowhere"
            author="CD Damitio"
            price="9.00"
            currentSlide={currentSlide}
            index={6}
          />
          <CardUpcoming
            imgSrc="feature-10.png"
            name="May Nothingness Spare Us"
            author="Jose Covo"
            price="9.00"
            currentSlide={currentSlide}
            index={7}
          />
          <CardUpcoming
            imgSrc="feature-03.png"
            name="Human Minds Super Power"
            author="Sanjay Rout"
            price="9.00"
            currentSlide={currentSlide}
            index={8}
          />
        </Carousel>
      </div>
    </div>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Home = () => {
  const carouselRef = useRef(null);

  return (
    <>
      <Banner />
      <Upcoming />

      <div className="container section" id="section-featured-drops">
        <div className="section-title">Featured Drops</div>
        <p className="section-description mb-5">
          Discover the extraordinary with our featured drops—where rare eBooks meet stunning art, creating collectibles that are as unique as the stories they tell.
        </p>
        <DropItemContainer />
        <button className="btn btn-view-all">
          <Link to="/bookstore">
            View all eBooks
          </Link>
        </button>
      </div>

      <div className="section" id="section-about">
        <img src="./img/video_back.png" alt="" />
        <div className="container h-100 d-flex flex-column flex-lg-row align-items-center mb-4">
          <div className="content">
            <div className="logo">
              <a href="https://gitbook.publishednft.io/" target="_blank">              
                <img src="./img/star-img.png" />
              </a>
            </div>
            <div className="title">The Future of eBooks</div>
            <p className="description">
              Published NFT is a web3 publishing house built on the Flow blockchain, where authors can publish
              <strong> eBooks, Audiobooks, Comics, Magazines, Articles,</strong> etc.
            </p>
          </div>

          <iframe className='promo-video' src="https://www.youtube.com/embed/zvPOze74BoQ" frameBorder="0" allowFullScreen>          
          </iframe>
        </div>
      </div>
    </>
  );
}

export default Home;