import { Navbar, Nav, Modal } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from 'react';
import { Button, OverlayTrigger, Tooltip, Overlay, Dropdown } from "react-bootstrap";
import * as fcl from "@onflow/fcl";
import { FaBars, FaCheckCircle, FaUser } from 'react-icons/fa';

export default function NewHeader() {
  const [user, setUser] = useState({ loggedIn: null });

  useEffect(() => {
    fcl.currentUser.subscribe(setUser);
  }, []);

  return (
    <header>
      <div className="container d-flex flex-column flex-sm-row justify-content-between">
        <Link to="/">
          <div className="logo my-2">
            <img src="./img/logo.png" alt="" style={{ width: '150px', height: '80px' }} />
            <span className="badge bg-warning text-white beta-badge font-sm">Beta</span>
          </div>
        </Link>

        <div className="dropdown d-flex d-lg-none my-2">
          <button id="btn-dropdown" type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
            Menu
          </button>
          <ul className="dropdown-menu w-100" style={{background: "#17181b"}}>
            <li>
              <Link className="dropdown-item" to="/bookstore">
                <strong className='text-white'>Bookstore</strong>
              </Link>
            </li>

            <li>
              <a className="dropdown-item" href="#">
                <strong className='text-white'>Trade</strong>
              </a>
            </li>

            <li onClick={(e) => e.stopPropagation()}>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <strong>Challenges</strong>
                </Dropdown.Toggle>

                <Dropdown.Menu id="dropdown-mobile" style={{background: "#17181b"}}>
                  <Dropdown.Item href="#" className='text-white'>Contest</Dropdown.Item>
                  <Dropdown.Item href="#" className='text-white'>Quest</Dropdown.Item>
                  <Dropdown.Item href="#" className='text-white'>Quizzes</Dropdown.Item>
                  <Dropdown.Item href="#" className='text-white'>Trivia</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li>
              <Link className="dropdown-item" to="/marketplace">
                <strong className='text-white'>Marketplace</strong>
              </Link>
            </li>

            <li>
              <a className="dropdown-item" href="https://docs.google.com/forms/d/e/1FAIpQLSdkkt5AU3WwyCdeit0U3vc0HaejojffatR31EbjGDYNSsBb2A/viewform">
                <strong className='text-white'>Get Published</strong>
              </a>
            </li>

            <li onClick={fcl.logIn} role="button">
              <a className="dropdown-item text-white">
                Sign In/Up
              </a>
            </li>
          </ul>
        </div>

        <nav className="d-lg-block d-none my-2">
          <div className='d-flex'>
            <Link className="btn pt-3" to="/bookstore">
              <strong>Bookstore</strong>
            </Link>

            <a className='btn pt-3' href="#">
              <strong>Trade</strong>
            </a>

            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className='pt-3'>
                <strong>Challenges</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{background: "#17181b"}}>
                <Dropdown.Item href="#" className='text-white'>Contest</Dropdown.Item>
                <Dropdown.Item href="#" className='text-white'>Quest</Dropdown.Item>
                <Dropdown.Item href="#" className='text-white'>Quizzes</Dropdown.Item>
                <Dropdown.Item href="#" className='text-white'>Trivia</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Link className="btn pt-3" to="/marketplace">
              <strong>Marketplace</strong>
            </Link>

            <a className="btn pt-3" href="https://docs.google.com/forms/d/e/1FAIpQLSdkkt5AU3WwyCdeit0U3vc0HaejojffatR31EbjGDYNSsBb2A/viewform">
              <strong>Get Published</strong>
            </a>       

            {!user.loggedIn ?
              <button className="btn my-2" id="btn-signin" onClick={fcl.logIn}>
                Sign In/Up <img src="./img/up-right-arrow.png" alt="" />
              </button>
              :
              <button className="btn my-2" id="btn-signin" onClick={fcl.unauthenticate}>
                Sign Out <img src="./img/up-right-arrow.png" alt="" />
              </button>
            }
          </div>          
        </nav>
      </div>
    </header>
  )
}