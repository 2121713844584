export const INIT_ACCOUNT = `
import FungibleToken from 0xFungibleToken
import NonFungibleToken from 0xNFT
import FUSD from 0xFUSD
import LibraryPass from 0xLibraryPass

transaction {
let address: Address

prepare(currentUser: AuthAccount) {
    if(currentUser.borrow<&FUSD.Vault>(from: /storage/fusdVault) == nil) {
      currentUser.save(<-FUSD.createEmptyVault(), to: /storage/fusdVault)
      currentUser.link<&FUSD.Vault{FungibleToken.Receiver}>(
        /public/fusdReceiver,
        target: /storage/fusdVault
      )

      currentUser.link<&FUSD.Vault{FungibleToken.Provider}>(
        /public/fusdProvider,
        target: /storage/fusdProvider
      )

      currentUser.link<&FUSD.Vault{FungibleToken.Balance}>(
        /public/fusdBalance,
        target: /storage/fusdVault
      )
    }

    self.address = currentUser.address
    let account= getAccount(self.address)
    let artCollection = currentUser.getCapability(LibraryPass.CollectionPublicPath).borrow<&{LibraryPass.LibraryPassCollectionPublic}>()

    if artCollection == nil {
      let collection <- LibraryPass.createEmptyCollection()
      currentUser.save(<-collection, to: LibraryPass.CollectionStoragePath)
      currentUser.link<&LibraryPass.Collection{NonFungibleToken.CollectionPublic, LibraryPass.LibraryPassCollectionPublic}>(LibraryPass.CollectionPublicPath, target: LibraryPass.CollectionStoragePath)
      log("account is initialized")
    } else {
      log("account was already initialized")
    }
}
post {    
    getAccount(self.address).getCapability(LibraryPass.CollectionPublicPath).borrow<&{LibraryPass.LibraryPassCollectionPublic}>() != nil : "Account is not initialized"
}
}`