export const getOwnGround = `
import MetadataViews from 0x631e88ae7f1d7c20
import GroundWork29 from 0x00af2ab363fa49e4

access(all) struct PublishedData {
    access(all) let nftGlobalId: UInt64
    access(all) let nftSerialId: UInt64
    access(all) let type: String

    init(nftGlobalId: UInt64, nftSerialId: UInt64, type: String) {
        self.nftGlobalId = nftGlobalId
        self.nftSerialId = nftSerialId
        self.type = type
    }
}

access(all) fun main(address: Address): [PublishedData] {

    let publishedDataArray: [PublishedData] = []

    let account = getAccount(address)
    let collection = account.capabilities.borrow<&GroundWork29.Collection>(GroundWork29.CollectionPublicPath)
        ?? panic("Could not borrow a reference to the collection")

    let nftIDs = collection.getIDs()

    for nftID in nftIDs {
        let nft = collection.borrowGroundWork29(id: nftID) 
                    ?? panic("Could not borrow a reference to the collection")
        let viewSerial = nft.resolveView(Type<MetadataViews.Serial>())!
        let displayView = viewSerial as! MetadataViews.Serial

        let view = nft.resolveView(Type<MetadataViews.Display>())!
        let display = view as! MetadataViews.Display

        let nftType = display.name
        let nftSerialID = displayView.number

        let publishedData = PublishedData(nftGlobalId: nftID, nftSerialId: nftSerialID, type: nftType)
        publishedDataArray.append(publishedData)
    }
    return publishedDataArray
}
`