export const CHECK_GROUND_COLLECTION = `
import NonFungibleToken from 0xNFT
import GroundWork29 from 0xPublishedNFT

access(all) view fun main(address: Address): Bool {
    let collectionCapability = getAccount(address)
        .capabilities
        .borrow<&GroundWork29.Collection>(GroundWork29.CollectionPublicPath)

    if let collection = collectionCapability {
        return true
    } else {
        return false
    }
}
`