export const PURCHASEBOAST_DAPPER = `
import FungibleToken from 0xf233dcee88fe0abe
import NonFungibleToken from 0x1d7e57aa55817448
import MetadataViews from 0x1d7e57aa55817448
import DapperUtilityCoin from 0xead892083b3e2c6c
import BoastNFTStorefront from 0x52cbea4e6f616b8e
import Boast from 0x52cbea4e6f616b8e

// This transaction purchases an NFT from a dapp directly (i.e. **not** on a peer-to-peer marketplace).
// FIRST ARGUMENT OF THIS TRANSACTION MUST BE YOUR MERCHANT ACCOUNT ADDRESS PROVIDED BY DAPPER LABS
transaction(merchantAccountAddress: Address, storefrontAddress: Address, listingResourceID: UInt64, expectedPrice: UFix64) {
    let paymentVault: @FungibleToken.Vault
    let buyerNFTCollection: &AnyResource{NonFungibleToken.CollectionPublic}
    let storefront: &BoastNFTStorefront.Storefront{BoastNFTStorefront.StorefrontPublic}
    let listing: &BoastNFTStorefront.Listing{BoastNFTStorefront.ListingPublic}
    let balanceBeforeTransfer: UFix64
    let mainDUCVault: &DapperUtilityCoin.Vault
    let salePrice: UFix64
    
    // "dapp" as authorizer is OPTIONAL
    // If "dapp" is also an authorizer and it MUST be the first authorizer
    prepare(dapper: AuthAccount, buyer: AuthAccount) {

        // Initialize the collection if the buyer does not already have one
        if buyer.borrow<&Boast.Collection>(from: Boast.CollectionStoragePath) == nil {
            buyer.save(<-Boast.createEmptyCollection(), to: Boast.CollectionStoragePath)
            buyer.link<&Boast.Collection{NonFungibleToken.CollectionPublic, Boast.BoastCollectionPublic, MetadataViews.ResolverCollection}>(
                Boast.CollectionPublicPath, target: Boast.CollectionStoragePath
            )
             ?? panic("Could not link collection Pub Path");
        }

        self.storefront = getAccount(0x52cbea4e6f616b8e).getCapability<&BoastNFTStorefront.Storefront{BoastNFTStorefront.StorefrontPublic}>(BoastNFTStorefront.StorefrontPublicPath)
                                                        .borrow()?? panic("Could not borrow a reference to the storefront")
        
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID) ?? panic("No Listing with that ID in Storefront")

        self.salePrice = self.listing.getDetails().salePrice

        self.mainDUCVault = dapper.borrow<&DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
                    ?? panic("Could not borrow reference to Dapper Utility Coin vault")

        self.balanceBeforeTransfer = self.mainDUCVault.balance
        self.paymentVault <- self.mainDUCVault.withdraw(amount: self.salePrice)

        self.buyerNFTCollection = buyer.getCapability<&{NonFungibleToken.CollectionPublic}>(Boast.CollectionPublicPath)
                                        .borrow()?? panic("Cannot borrow NFT collection receiver from account")
    }

    pre {
        self.salePrice == expectedPrice: "unexpected price"
    }

    execute {
        let item <- self.listing.purchase(
            payment: <-self.paymentVault
        )

        self.buyerNFTCollection.deposit(token: <-item)
    }

    post {
        self.mainDUCVault.balance == self.balanceBeforeTransfer: "DUC leakage"
    }
}
`