import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactReader } from 'react-reader'
import { Nav, Navbar, Dropdown, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaBars, FaUser, FaCheckCircle } from 'react-icons/fa'
import * as fcl from "@onflow/fcl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FaGlobe } from "react-icons/fa";
import { INIT_PUBLISHED_DAPPER } from '../cadence/script/LibraryPass/init_published_dapper';
import { CHECK_PUBLISHED_COLLECTION } from '../cadence/script/LibraryPass/checkPublishedCollection';

export default function Book() {
  // And your own state logic to persist state
  const [epubLocation, setEpubLocation] = useState(null);
  const location = useLocation();
  const [user, setUser] = useState({ loggedIn: null });
  const [isInit, setIsInit] = useState(false);
  const [copiedStr, setCopiedStr] = useState("Copy to clipboard");
  const isMobile = window.innerWidth <= 768; 
  const [size, setSize] = useState(100);
  const renditionRef = useRef(null);
  const [book, setBook] = useState("");
  const [avatar, setAvatar] = useState("");
  const [instagramURL, setInstagramURL] = useState("");
  const [twitterURL, setTwitterURL] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [openseaURL, seOpenseaURL] = useState("");
  const [audio, setAudio] = useState(false);

  const changeSize = newSize => {
    setSize(newSize)
  }

  const locationChanged = epubcifi => {
    // epubcifi is a internal string used by epubjs to point to a location in an epub. It looks like this: epubcfi(/6/6[titlepage]!/4/2/12[pgepubid00003]/3:0)
    setEpubLocation(epubcifi)
  }

  useEffect(() => { 
    fcl.currentUser.subscribe(setUser);
    checkAccountInitialized();
    
    if(location.state.type === "boast"){
      // setBook("http://localhost:3001/books/BOAST_MAGAZINE_ISSUE_6.epub");
      setBook("https://publishednft.io/books/BOAST_MAGAZINE_ISSUE_6.epub");
      setAvatar("/mancave_profile.png");
      setInstagramURL("https://www.instagram.com/mancaveplaybabes/");
      setTwitterURL("https://twitter.com/MancavePlaybabe");
      setWebsiteURL("https://www.mancaveplaybabes.com/");
      seOpenseaURL("");
    }else if(location.state.type === "groundwork29"){
      // setBook("http://localhost:3001/books/groundwork29.epub");
      setBook("https://publishednft.io/books/groundwork29.epub");
      setAvatar("/author_template_0-00-10-18.png");
      setInstagramURL("https://www.instagram.com/wellnesssbritt/");
      setTwitterURL("https://twitter.com/wellnesssbritt");
      setWebsiteURL("https://linktr.ee/wellnesssbritt");
      seOpenseaURL("https://rarible.com/wellnesssbritt");

    }else if(location.state.type === "delightful"){
      setAvatar("/dresskro_profile-pic.png");
      setInstagramURL("https://www.neetibisht.in/");
      setTwitterURL("https://twitter.com/drsskro");
      setWebsiteURL("https://linktr.ee/Drsanjayrout");
      seOpenseaURL("https://opensea.io/MintSongs-Collection");
      setAudio(true);

    }else if(location.state.type === "humanminds"){
      // setBook("http://localhost:3001/books/Human_Minds_Super_Power.epub");
      setBook("https://publishednft.io/books/Human_Minds_Super_Power.epub");
      setAvatar("/dresskro_profile-pic.png");
      setInstagramURL("https://www.neetibisht.in/");
      setTwitterURL("https://twitter.com/drsskro");
      setWebsiteURL("https://linktr.ee/Drsanjayrout");
      seOpenseaURL("https://opensea.io/MintSongs-Collection");

    }else if(location.state.type === "nightvision"){
      // setBook("http://localhost:3001/books/NIGHTvision.epub");
      setBook("https://publishednft.io/books/NIGHTvision.epub");
      setAvatar("/Greg.jpg");
      setInstagramURL("");
      setTwitterURL("https://twitter.com/tem2");
      setWebsiteURL("https://gfishbone.com/");
      seOpenseaURL("");

    }else if(location.state.type === "element"){
      // setBook("http://localhost:3001/books/Element.epub");
      setBook("https://publishednft.io/books/Element.epub");
      setAvatar("/rainydays.png");
      setInstagramURL("");
      setTwitterURL("https://twitter.com/Rydzproductions");
      setWebsiteURL("https://rainydayz.io/");
      seOpenseaURL("https://magiceden.io/creators/rainydayz");

    }else if(location.state.type === "syllables"){
      // setBook("http://localhost:3001/books/True_49_Syllables_on_Life.epub");
      setBook("https://publishednft.io/books/True_49_Syllables_on_Life.epub");
      setAvatar("/Tom_Ang_proflile_0-00-00-00.png");
      setInstagramURL("https://www.instagram.com/tomangphoto/");
      setTwitterURL("https://twitter.com/Tom_Ang");
      setWebsiteURL("https://tomang.com/");
      seOpenseaURL("");
    }
  }, []);

  useEffect(() => {
    checkAccountInitialized();
  }, [user]);

  useEffect(() => {
    if (renditionRef.current) {
      renditionRef.current.themes.fontSize(`${size}%`)
    }
  }, [size])

  const initAccount = async () => {
    var transactionId = await fcl.mutate({
      cadence: INIT_PUBLISHED_DAPPER,
      limit: 999,
    }); 

    await fcl.tx(transactionId).onceSealed().then((res) => {
      console.log(res);
      setIsInit(true);
    }).catch((err) => {
      console.log(err);
    });
  };

  const checkAccountInitialized = async () => {
    const currentUser = await fcl.currentUser().snapshot();
    if(currentUser.addr != null){
      const res = await fcl.query({
        cadence: CHECK_PUBLISHED_COLLECTION,
        args: (arg, t) => [arg(currentUser?.addr, t.Address)],
      });
          
      console.log('init - ', res);
      setIsInit(res);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {copiedStr}
    </Tooltip>
  );

  const renderTooltipInit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Initialize wallet before buying NFTs
    </Tooltip>
  );

  const addrClick = (e) => {
    navigator.clipboard.writeText(e.target.innerHTML);
    setCopiedStr("Copied");
  }
    
  return (
    <div>
      <Navbar className="bg-gray px-1" expand="lg">
        <Navbar.Brand>
          <Link to="/">
            <img id="logo-desktop" alt='' src="./logo-desktop.png" />
            <span className="badge bg-primary text-white">Reader</span>
          </Link>        
        </Navbar.Brand>

        {(user.loggedIn && isMobile) &&
          <Dropdown>
            <Dropdown.Toggle className="profile-button" align="end" id="dropdown-menu-align-end">
              <FaUser className='text-green' size={20} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-grey" align="end">
              <Dropdown.Item href="#/action-1">
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250 }}
                  overlay={renderTooltip}
                >
                  <button className='addr-btn font-weight-bold me-2 p-0' onClick={(e) => addrClick(e)} 
                    onMouseLeave={() => setCopiedStr("Copy to clipboard")}>
                    {user.addr}
                  </button>
                </OverlayTrigger>
                {isInit &&
                  <FaCheckCircle className='text-white'/>
                }
              </Dropdown.Item>
              <hr className='text-white mt-1 mb-1'/>

              <Dropdown.Item href="#/action-2">
                <a className='text-white font-weight-bold mb-0' href='https://accounts.meetdapper.com/' 
                  target="_blank">
                  My Dapper Wallet
                </a>
              </Dropdown.Item>
              <hr className='text-white mt-1 mb-1'/>

              <Dropdown.Item href="#/action-3">
                <p className='text-white font-weight-bold mb-0' role="button" onClick={fcl.unauthenticate}>
                  Sign out
                </p>  
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }       

        <Navbar.Toggle> <FaBars className='text-white '/> </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">           
          <Nav>      
            <div className='d-flex'>              
              <img className='me-2' src={avatar} width="50px" height="50px" style={{borderRadius: '50%'}} />
              <a className="pt-3 me-2 text-white" href={twitterURL} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a className="pt-3 me-2 text-white" href={instagramURL} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a className="pt-3 me-2 text-white" href={websiteURL} target="_blank" rel="noreferrer">
                <FaGlobe className="text-white mb-1" />
              </a>
              <a className="pt-3 me-2 text-white mb-1" href={openseaURL} target="_blank" rel="noopener noreferrer"
              >
                <img src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Transparent%20White.svg"
                  alt="" width="16px" height="16px" style={{ marginBottom: "3px" }} />
              </a>            
            </div>

            {user.loggedIn &&
              <Link className="text-primary font-weight-bold m-2 pt-1" to="/collection">
                My Collection
              </Link>
            }

            {user.loggedIn ?
              <>
                {!isInit && 
                  <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipInit}
                  >
                    <Button variant="primary" className='me-2 mb-2' onClick={initAccount}>
                      Create Account
                    </Button>
                  </OverlayTrigger>
                }        
              </>
            :
              <Button variant="primary ml-3" onClick={fcl.logIn}>
                Sign In/Up
              </Button>
            }    

          </Nav>
        </Navbar.Collapse>  
        
        {(user.loggedIn && !isMobile) &&
          <Dropdown>
            <Dropdown.Toggle className="profile-button" align="end" id="dropdown-menu-align-end">
              <FaUser className='text-green' size={20} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-grey" align="end">
              <Dropdown.Item href="#/action-1">
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250 }}
                  overlay={renderTooltip}
                >
                  <button className='addr-btn font-weight-bold me-2 p-0' onClick={(e) => addrClick(e)} 
                    onMouseLeave={() => setCopiedStr("Copy to clipboard")}>
                    {user.addr}
                  </button>
                </OverlayTrigger>
                {isInit &&
                  <FaCheckCircle className='text-white'/>
                }
              </Dropdown.Item>
              <hr className='text-white mt-1 mb-1'/>

              <Dropdown.Item href="#/action-2">
                <a className='text-white font-weight-bold mb-0' href='https://accounts.meetdapper.com/' 
                  target="_blank">
                  My Dapper Wallet
                </a>
              </Dropdown.Item>
              <hr className='text-white mt-1 mb-1'/>

              <Dropdown.Item href="#/action-3">
                <p className='text-white font-weight-bold mb-0' role="button" onClick={fcl.unauthenticate}>
                  Sign out
                </p>  
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }                    
      </Navbar>
      {!audio ?
      <>
        <div style={{ height: '100vh', position: 'relative' }}>
          <ReactReader
            location={epubLocation}
            locationChanged={locationChanged}
            url={book}
            getRendition={rendition => {
              renditionRef.current = rendition
              renditionRef.current.themes.fontSize(`${size}%`)
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: '1rem',
            right: '1rem',
            left: '1rem',
            textAlign: 'center',
            zIndex: 1
          }}
        >
          <button onClick={() => changeSize(Math.max(80, size - 10))}>-</button>
          <span className='text-dark'>{size}%</span>
          <button onClick={() => changeSize(Math.min(130, size + 10))}>+</button>
        </div>
      </>      
      :
      <div className='text-center mt-5'>
        <h2 className='text-white'>
          Delightful Novella
        </h2>
        <div className='col mt-5'>
          <audio src="/books/Delightful_Novella.mp3" controls/>
        </div>        
      </div>      
      }      
    </div>    
  )
}