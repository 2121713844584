export const INIT_GROUND_DAPPER = `
import NonFungibleToken from 0xNFT
import MetadataViews from 0xNFT
import GroundWork29 from 0xPublishedNFT

transaction {

  prepare(signer: auth(BorrowValue, IssueStorageCapabilityController, PublishCapability, SaveValue, UnpublishCapability) &Account) {
      
      let collectionData = GroundWork29.resolveContractView(resourceType: nil, viewType: Type<MetadataViews.NFTCollectionData>()) as! MetadataViews.NFTCollectionData?
          ?? panic("ViewResolver does not resolve NFTCollectionData view")

      // Return early if the account already has a collection
      if signer.storage.borrow<&GroundWork29.Collection>(from: collectionData.storagePath) != nil {
          return
      }

      // Create a new empty collection
      let collection <- GroundWork29.createEmptyCollection(nftType: Type<@GroundWork29.NFT>())

      // save it to the account
      signer.storage.save(<-collection, to: collectionData.storagePath)

      // create a public capability for the collection
      signer.capabilities.unpublish(collectionData.publicPath)
      let collectionCap = signer.capabilities.storage.issue<&GroundWork29.Collection>(collectionData.storagePath)
      signer.capabilities.publish(collectionCap, at: collectionData.publicPath)
  }
}
`