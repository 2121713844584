import { useEffect } from 'react';

export default function PrivacyPolicy(){
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return(
    <div className='container'>
      <h1 className='mt-5 mb-5 text-center'>Privacy Policy</h1>
      <p>
      This Privacy Policy (this “Policy”) applies to the Personal Data (as defined below) and other
      information collected while you are using or inquiring about Published NFT, Inc.’s (“Published
      NFT”, “we“, “us“, or “our“) NFT platform and related services. This Policy also governs your
      interaction with our websites, www.published.io, www.publishednft.org and
      www.publishednft.app (the “Websites”).<br/><br/>

      By accessing or using our Websites and/or the Published NFT NFT platform (the “Platform”),
      you agree that you have read, understand, and are bound by this Policy and our terms of use.
      IF YOU DO NOT AGREE TO BE BOUND BY THIS POLICY OR OUR TERMS OF USE,
      PLEASE DO NOT USE OR ACCESS OUR WEBSITE OR THE PLATFORM.<br/><br/>

      This Policy explains, among other items: (1) What Personal Data and other information we
      collect; (2) How we use the Personal Data and other information; (3) How we share the
      Personal Data and other information; and (4) Your choices regarding our use of your Personal
      Data and other information.<br/><br/>

      Collection of Personal Data<br/><br/>

      Personal Data is information that identifies you or can be used to identify or contact you, e.g.,
      your name, email address, mailing address, phone number or Internet Protocol (“IP”) address.
      We may need to collect and process Personal Data in order to provide requested information,
      allow you to access the Websites or the Platform and/or because we are legally required to do
      so. Personal Data may be collected directly from you, from your employer and/or from third
      parties.<br/><br/>

      Personal Data may be requested from you as part of the Platform registration process and/or
      the NFT transaction process. This information may be collected via the Platform or offline. The
      registration process typically requests only you provide your name and an email address. We
      also may collect Personal Data when you inquire about our Platform and/or contact us for
      customer service or technical support. Your Personal Data helps us to better respond to your
      interests and needs.<br/><br/>

      In some circumstances, Published NFT may receive and utilize Personal Data from your posts
      on social media platforms having and applying their own privacy policies and terms of service.<br/><br/>

      Published NFT may collect Personal Data voluntarily provided by you, including in emails,
      online forums, order and registration forms, surveys, promotion participation, phone calls and
      online chats with customer service or technical support and through other means.<br/><br/>

      Published NFT collects information through various other means, including participation at
      conferences, tradeshows and events. We also collect certain Personal Data from our brand
      partners. From time to time, we may also acquire from third-parties certain lists containing
      Personal Data of individuals who may be interested in our Platform or Websites.<br/><br/>

      Use of Personal Data<br/><br/>

      In general, you can use and visit our Websites without submitting any Personal Data. However,
      in order to utilize and access certain features on our Websites and to access the Platform you
      will need to submit your Personal Data.<br/><br/>

      We use Personal Data for legitimate business purposes, including the following:<br/><br/>

      Use of the Platform and Fulfillment of Requests. We may use Personal Data about you to: (a)
      register you for the Platform, allow you to access the Platform and/or provide you with customer
      service or technical support; (b) manage our contractual relationship with you or your employer;
      (c) comply with a legal obligation. Specifically, we will use your Personal Data for delivering an
      NFT(s) purchased by you via our websites, currently www.publishednft.com.<br/><br/>

      Other Communications. From time to time, we may use Personal Data about you to inform you
      through targeted advertising of products, programs, services and promotions that we believe
      may be of interest to you as well as for general marketing purposes. We sometimes use a
      marketing automation tool to identify individuals who may be interested in receiving information
      about the Websites or the Platform.<br/><br/>

      Other Business Purposes. We may also use Personal Data about you for other business
      purposes, such as data analysis, providing customer service or technical support, customizing
      and improving the content and layout of the Websites or the Platform, completing business
      transactions, development of marketing and promotion plans and materials, statistical analysis
      of user behavior (for example, to understand what parts of the Websites or the Platform are of
      most interest to users), product development, market research, administering individual
      accounts, and meeting regulatory requirements. We engage in these activities to manage our
      relationship with you, to comply with a legal obligation and/or because we have a legitimate
      interest.<br/><br/>

      Administrative Communications. We reserve the right to use Personal Data to send you
      important information regarding the Websites or the Platform, your account status, changes to
      this Policy, or any other policies, agreements or transactions relevant to your use of the
      Websites or the Platform. Because this information may be important to your use of the
      Websites or the Platform, you may not opt-out of receiving such communications. We will
      engage in this activity to manage our contractual relationship with you and/or to comply with a
      legal obligation.<br/><br/>

      Provision of Service; Data Transfers. We will share your data with platform hosts and similar
      vendors who provide the technical foundation of the services. The provision of the service often
      involves transmission of data across national borders. Such transfers are often governed by
      data processing agreements.<br/><br/>

      Shopify App. If you access the Platform via our Shopify App: (1) you will purchase an NFT from
      our merchant partner and payment will be made via the merchant’s Shopify Payments module;
      (2) the App will collect your name and email address; and (3) we will store your Personal Data in
      order to deliver the NFT to you.<br/><br/>

      Employment. We may use your Personal Data to consider you for employment when you apply
      for a position with us or submit a resume or an employment application to us.<br/><br/>

      We believe that we have established reasonable organizational and technical measures
      designed to protect Personal Data from loss, misuse, or unauthorized access, disclosure,
      alteration or destruction. However, no electronic transmission, storage or processing of
      information can be entirely secure and you assume all risk of loss, unauthorized access to or
      misuse of your information. Please note that one of the most effective security measures is
      protecting your password. Do not use any common passwords (such as “password”, “1234” or
      your child’s name) and do not share your password with others. Most Personal Data is stored
      electronically on third-party servers located in the United States .<br/><br/>

      By submitting your Personal Data, you expressly authorize Published NFT to contact you on
      your mobile telephone to the extent you provide the same to us. This contact may be a text
      message or a telephone call.<br/><br/>

      What Other Information We Collect<br/><br/>

      When you visit or interact with our Websites or the Platform, we or our third-party service
      providers may also use cookies and other types of web technologies to collect technical and
      usage information such as browsing history, domain name, IP address, browser type, date and
      time of each visit, and pages that your web browser has visited. Cookies are small files that a
      site or its service provider transfers to your computer’s hard drive through your web browser (if
      you allow) that enables the sites or service provider’s systems to recognize your browser and
      capture and remember certain information in order to collect technical and usage information
      such as browsing history, domain name, IP address, browser type, date and time of each visit,
      the pages you visit, and other technical and usage information<br/><br/>

      We use cookies to understand and save your preferences for future visits and to compile
      aggregate data about Websites traffic and Websites or Platform interaction so that we can offer
      better experiences and tools in the future.<br/><br/>

      Most web browsers permit you to set your web browser to block or delete all cookies, including
      cookies associated with our services, or to indicate when a cookie is being set by us. However,
      it’s important to remember that our Websites or the Platform may not function properly if you set
      your browser to reject our cookies.<br/><br/>

      We may use a tool called “Google Analytics” to collect information about use of the Websites.
      Google Analytics collects information such as how often users visit our Websites, what pages
      they visit when they do so, and what other sites they used prior to coming to our Websites. We
      use the information we get from Google Analytics to improve our Websites. To the best of our
      knowledge, Google Analytics collects only the IP address assigned to you on the date you visit
      our Websites, rather than your name or other identifying information. We do not combine the
      information collected through the use of Google Analytics with personally identifiable
      information. We do sometimes transmit IP addresses and email address to Google Analytics for
      the purposes of performing IP geolocation and/or customer segmentation. Although Google
      Analytics plants a permanent cookie on your web browser to identify you as a unique user the
      next time you visit our Websites, the cookie cannot be used by anyone but Google. Google’s
      ability to use and share information collected by Google Analytics about your visits to our
      Websites is restricted by the Google Analytics Terms of Use and the Google Privacy Policy.
      More information on Google Analytics can be found here:
      https://policies.google.com/technologies/partner-sites. If you would like to opt-out of having your
      data used by these cookies, please use the Google Analytics opt-out available here:
      https://tools.google.com/dlpage/gaoptout/.<br/><br/>

      We may also use other third-party service providers to track our visitors. Most browsers also
      allow you to turn on a feature commonly referred to as “Do Not Track” so that websites and
      service providers will not track your behavior over time and across websites.<br/><br/>

      4. How We Share Information<br/><br/>

      Although we do not presently sell your Personal Data for monetary consideration, we will
      provide some or all of your Personal Data to our business partners such as third parties who
      assist us in operating our Websites or the Platform, conduct surveys or market research and/or
      provide general business services (e.g., marketing, analytics). We may also share your
      Personal Data when we believe the disclosure is appropriate to comply with the law or legal
      process, enforce our policies, or protect ours or other’s rights, property, or safety.<br/><br/>

      We may provide your Personal Data to the merchants we do business with in order to facilitate
      your transaction(s).<br/><br/>

      From time to time, we may enter into a special relationship with another company that is not
      owned by or affiliated with GigLabs to provide or promote joint products, services, applications,
      features, or conducting seminars or webinars (together, “Jointly Offered Items”). Any
      information, including Personal Data, that you provide in connection with one of these Jointly
      Offered Items, and/or on one of these co-branded pages, will be shared with our third-party
      partners. You should check these third parties’ web sites for information regarding their privacy
      practices.<br/><br/>

      PLEASE NOTE THAT THIS PRIVACY POLICY DOES NOT ADDRESS THE PRIVACY OR
      INFORMATION PRACTICES OF ANY THIRD PARTIES. If you do not want Personal Data
      about you shared with such third parties, please do not provide Personal Data in connection
      with the Jointly Offered Items and/or co-branded pages.<br/><br/>

      We may share your Personal Data with our parents, subsidiaries or affiliates. We may also
      transfer your Personal Data to any successor company through a corporate transaction such as
      a merger, sale of assets, statutory consolidation and/or joint venture.<br/><br/>

      Choices Re: Personal Data<br/><br/>

      We give you choices regarding our use and disclosure of your Personal Data for marketing
      purposes. If you no longer want to receive marketing-related emails from us on a going-forward
      basis, you may stop receiving these emails by sending an e-mail to support@publishednft.io.
      Similarly, if you would prefer that we do not share your Personal Data on a going-forward basis
      with unaffiliated third parties for their marketing purposes, you may cease this sharing by
      sending an e-mail to support@publishednft.io. All of our email communications include the
      ability to opt-out from receiving future emails, except Administrative Communications.<br/><br/>

      We will try to comply with your request(s) as soon as reasonably practicable. Please note: (a)
      we will not be able to remove your Personal Data from the databases of third parties with which
      we have already shared your Personal Data (i.e., to which we have already provided your
      Personal Data as of the date that we implement your request); (b) that requesting us not to
      share your Personal Data may result in you no longer receiving any marketing emails from
      GigLabs; and (c) that if you choose not to receive marketing-related messages from us, we may
      still send you important Administrative Communications, and you cannot elect to stop receiving
      such communications, unless you choose to stop using the Platform.<br/><br/>

      You may also indicate your choices regarding marketing-related emails by contacting us via
      regular mail using the “Contact Information” below, or if you have an account on the Platform, by
      changing your preferences on such account at any time.<br/><br/>

      If you would like to request to review, correct, update, suppress, delete or otherwise limit our
      use of your Personal Data that has been previously provided to us, or if you would like to
      request to receive an electronic copy of your Personal Data for purposes of transmitting it to
      another company (to the extent this right to data portability is provided to you by applicable law),
      you may make a request by contacting us using the information provided in the “Contact
      Information” section below. We will respond to your request consistent with applicable law. For
      your protection, we may only implement requests with respect to the Personal Data associated
      with the particular email address that you use to send us your request, and we may need to
      verify your identity before implementing your request. We will try to comply with your request as
      soon as reasonably practicable and consistent with applicable law. Please note that we may
      need to retain certain information for recordkeeping purposes and/or to complete any
      transactions that you began prior to requesting the change or deletion. There may also be
      residual information that will remain within our databases and other records, which will not be
      removed.<br/><br/>

      Miscellaneous<br/><br/>

      Changes to our Privacy Policy<br/><br/>

      We reserve the right to change this Policy from time to time. You are encouraged to regularly
      review this Policy. If we decide to materially change this Policy, we will post a notice on the
      Websites and/or within the Platform.<br/><br/>

      Third Party Websites<br/><br/>

      Our Websites or the Platform may contain links to other sites such as social networks or links to
      our business partners. Please be aware that we do not endorse and are not responsible for the
      privacy practices of such other sites and this Policy does not apply to such third-party sites. If
      you have any questions about these other companies’ data practices, you should review their
      privacy policies.<br/><br/>

      Minors and Children<br/><br/>

      Our Websites and the Platform are not directed to minors or children under the age of 16 and
      we do not knowingly collect Personal Data from any individual under the age of 16. If you are
      under the age of 16, please stop using our Websites or the Platform.<br/><br/>

      Additional Information for California Residents<br/><br/>

      If you are a resident of California, the California Consumer Privacy Act (“CCPA”) may provide
      you with specific rights regarding your Personal Data. These include:<br/><br/>

      - You can request information about our collection and use of your Personal Data over the
      past twelve (12) months concerning: (i) the categories of Personal Data we collected about you,
      (ii) the business or commercial purpose for collecting your Personal Data, (iii) the categories of
      sources from which your Personal Data is collected, and (iv) the categories of third parties with
      whom we share your Personal Data. You can request a list disclosing the categories of your
      Personal Data we have shared in connection with a business purpose.<br/><br/>

      - You can request to receive a copy of your Personal Data in a commonly used and machine-
      readable format.<br/><br/>

      - You can request that we delete your Personal Data, subject to certain exceptions.<br/><br/>

      To exercise the rights described above, it may be necessary for us to verify your identity or
      authority to make the request and confirm the Personal Data relates to you. Only you, or a
      person registered with the California Secretary of State that you authorize to act on your behalf,
      may make a verifiable consumer request related to your Personal Data. We will not discriminate
      against you for exercising any of your privacy rights under CCPA or other applicable law.<br/><br/>

      During the past twelve (12) months, we collected the categories of Personal Data described
      above from California residents. We also disclosed such Personal Data to third parties for the
      purposes specified above during the past twelve (12) months. Any request related to the CCPA
      may be made via the Contact Information below.<br/><br/>

      Policy Last Updated: February 1, 2022<br/><br/>
      </p>
    </div>
  )
}