import { useState, useEffect } from "react";
import { Card, Row, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Category from "../components/Category";

export default function BookStore() {
  const [passesActive, setPassesActive] = useState(false);
  const [videosActive, setVideosActive] = useState(false);
  const [ebooksActive, setEbooksActive] = useState(false);
  const [magazinesActive, setMagazinesActive] = useState(false);
  const [allActive, setAllActive] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clickDetail = (type) => {
    navigate("/description/" + type, { state: { type: type } });
  };

  return(
    <div className="container text-center">
      <div className='d-flex justify-content-center mt-5 mb-2'>
        <h1>Bookstore</h1>
      </div>

      <div className='row mb-2 justify-content-center'>
        <div className='col-md-6 d-flex mb-3 p-0 justify-content-center'>
          <Category text='All' allActive = {allActive} setAllActive = {setAllActive} 
          setPassesActive = {setPassesActive} setVideosActive = {setVideosActive}
          setEbooksActive = {setEbooksActive} setMagazinesActive = {setMagazinesActive}  />
          <Category text='eBooks' ebooksActive = {ebooksActive} setEbooksActive = {setEbooksActive} 
          setAllActive = {setAllActive} />
          <Category text='Magazines' magazinesActive = {magazinesActive}  setMagazinesActive = {setMagazinesActive} 
          setAllActive = {setAllActive} />
          <Category text='Comics' setAllActive = {setAllActive}/>
        </div>     
      </div>

      <Row xs={1} md={6} className="g-4 mb-5">
        {(allActive || ebooksActive) && 
          <div className="col pt-2">
            <Card className="transparent">
              <Badge className="book-badge" bg="primary">New</Badge>
              <Card.Img variant="top" src="feature-06.png" onClick={() => clickDetail("syllables")} role="button" />
              <Card.Body className="text-center pb-0">
                <p className="text-white reduce-text mb-0" onClick={() => clickDetail("syllables")} role="button">
                  <u>49 Syllables On Life</u>
                </p>
                <p className="text-white reduce-text font-sm">by Tom Ang</p>
              </Card.Body>
            </Card>
          </div>               
        }

        {(allActive || magazinesActive) && 
          <div className="col pt-2">
            <Card className="transparent">
              <Badge className="book-badge" bg="primary">New</Badge>
              <Card.Img variant="top" src="BOAST_MAGAZINE_ISSUE6.png" onClick={() => clickDetail("boast6")} role="button" />
              <Card.Body className="text-center pb-0">
                <p className="text-white reduce-text mb-0" onClick={() => clickDetail("boast6")} role="button">
                  <u>Boast Magazine Issue #6</u>
                </p>
                <p className="text-white reduce-text font-sm">by Mancave Play</p>
              </Card.Body>
            </Card>
          </div>          
        }

        {(allActive || ebooksActive) && 
        <div className="col pt-2">
          <Card className="transparent">
            <Badge className="book-badge" bg="primary">New</Badge>
            <Card.Img variant="top" src="feature-01.png" onClick={() => clickDetail("groundwork29")} role="button" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0" onClick={() => clickDetail("groundwork29")} role="button">
                <u>Groundwork 29</u>
              </p>
              <p className="text-white reduce-text font-sm">by Brittany Wallace</p>
            </Card.Body>
          </Card>
        </div>
        }

        <div className="col pt-2">
          <Card className="transparent">
            <Badge className="book-badge" bg="warning">Coming soon</Badge>
            <Card.Img variant="top" src="feature-02.png" onClick={() => clickDetail("delightfulnovella")} role="button" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0" onClick={() => clickDetail("delightfulnovella")} role="button">
                <u>Delightful Novella</u>
              </p>
              <p className="text-white reduce-text font-sm">by Sanjay Rout</p>
            </Card.Body>
          </Card>
        </div>        

        <div className="col pt-2">
          <Card className="transparent">
            <Badge className="book-badge" bg="warning">Coming soon</Badge>
            <Card.Img variant="top" src="feature-09.png" onClick={() => clickDetail("humanminds")} role="button" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0" onClick={() => clickDetail("humanminds")} role="button">
                <u>Decoding Indian Economy</u>
              </p>
              <p className="text-white reduce-text font-sm">by Goenkans</p>
            </Card.Body>
          </Card>
        </div>
        
        <div className="col pt-2">
          <Card className="transparent">
            <Badge className="book-badge" bg="warning">Coming soon</Badge>
            <Card.Img variant="top" src="feature-04.png" onClick={() => clickDetail("nightvision")} role="button" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0" onClick={() => clickDetail("nightvision")} role="button">
                <u>NIGHT vision</u>
              </p>
              <p className="text-white reduce-text font-sm">by Greg R. Fishbone</p>
            </Card.Body>
          </Card>
        </div>
        
        <div className="col pt-2">
          <Card className="transparent">
            <Badge className="book-badge" bg="warning">Coming soon</Badge>
            <Card.Img variant="top" src="feature-05.png" onClick={() => clickDetail("element")} role="button" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0" onClick={() => clickDetail("element")} role="button">
                <u>The Element</u>
              </p>
              <p className="text-white reduce-text font-sm">by Rainy Dayz</p>
            </Card.Body>
          </Card>
        </div>
        
        <div className="col pt-2">
          <Card className="transparent">
            <Badge className="book-badge" bg="warning">Coming soon</Badge>
            <Card.Img variant="top" src="feature-07.png" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0">
                <u>Dansuki Village</u>
              </p>
              <p className="text-white reduce-text font-sm">by Elisha Arowojobe</p>
            </Card.Body>
          </Card>
        </div>

        <div className="col pt-2">
          <Card className="transparent">
            <Badge className="book-badge" bg="warning">Coming soon</Badge>
            <Card.Img variant="top" src="feature-08.png" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0">
                <u>Notes from nowhere</u>
              </p>
              <p className="text-white reduce-text font-sm">by CD Damitio</p>
            </Card.Body>
          </Card>
        </div>

        <div className="col pt-2">
          <Card className="transparent">
            <Badge className="book-badge" bg="warning">Coming soon</Badge>
            <Card.Img variant="top" src="feature-10.png" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0">
                <u>May Nothingness Spare Us</u>
              </p>
              <p className="text-white reduce-text font-sm">by Jose Covo</p>
            </Card.Body>
          </Card>
        </div>

        <div className="col pt-2">
          <Card className="transparent">
            <Badge className="book-badge" bg="warning">Coming soon</Badge>
            <Card.Img variant="top" src="feature-03.png" onClick={() => clickDetail("humanminds")} role="button" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0" onClick={() => clickDetail("humanminds")} role="button">
                <u>Human Minds Super Power</u>
              </p>
              <p className="text-white reduce-text font-sm">by Sanjay Rout</p>
            </Card.Body>
          </Card>
        </div>        
      </Row>
    </div>
  )
}