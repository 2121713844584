import { useEffect, useState } from 'react'

export default function Category(props) {
  const {passesActive, setPassesActive, videosActive, setVideosActive, ebooksActive, setEbooksActive, 
    magazinesActive, setMagazinesActive, allActive, setAllActive, text}  = props;
  const [bgColor, SetBgColor] = useState('#313131');
  const [allColor, SetAllColor] = useState('transparent linear-gradient(89deg, #ec62b9 0%, #6a80f8 100%)');
  const [isActive, setIsActive] = useState(false);
  var content = '';

  useEffect(() => console.log('allactive - ', allActive ), [allActive]);

  useEffect(()=> {
    if(passesActive){
      SetBgColor('transparent linear-gradient(89deg, #ec62b9 0%, #6a80f8 100%)');
      setAllActive?.(false);
    }else SetBgColor('#313131');

    if(!passesActive && !videosActive && !ebooksActive && !magazinesActive){
      setAllActive(true)
    }
  }, [passesActive]);

  useEffect(()=> {
    if(videosActive){ 
      SetBgColor('transparent linear-gradient(89deg, #ec62b9 0%, #6a80f8 100%)');
      setAllActive?.(false);
    }else SetBgColor('#313131');

    if(!passesActive && !videosActive && !ebooksActive && !magazinesActive){
      setAllActive(true)
    }
  }, [videosActive]);

  useEffect(()=> {
    if(ebooksActive) {
      SetBgColor('transparent linear-gradient(89deg, #ec62b9 0%, #6a80f8 100%)');
      setAllActive?.(false);
    }else SetBgColor('#313131')

    if(!passesActive && !videosActive && !ebooksActive && !magazinesActive){
      setAllActive(true)
    }
  }, [ebooksActive]);

  useEffect(()=> {
    if(magazinesActive){ 
      SetBgColor('transparent linear-gradient(89deg, #ec62b9 0%, #6a80f8 100%)');
      setAllActive?.(false);
    }else SetBgColor('#313131');

    if(!passesActive && !videosActive && !ebooksActive && !magazinesActive){
      setAllActive(true)
    }
  }, [magazinesActive]);

  useEffect(()=> {
    if(allActive) {
      SetAllColor('transparent linear-gradient(89deg, #ec62b9 0%, #6a80f8 100%)');
      setPassesActive(false);
      setVideosActive(false);
      setEbooksActive(false);
      setMagazinesActive(false);
    }else SetAllColor('#313131');
  }, [allActive]);

  useEffect(()=> {
    isActive ? 
      SetBgColor('transparent linear-gradient(89deg, #ec62b9 0%, #6a80f8 100%)')
    :
      SetBgColor('#313131');
  }, [isActive]);

  if(text === "Passes"){
    content = <div className='category font-weight-bold me-3' onClick={() => setPassesActive(!passesActive)} 
    style={{'background': bgColor, 'cursor':'pointer'}}>
      {text}
    </div>;

  }else if(text === "Stories"){
    content = <div className='category font-weight-bold me-3' onClick={() => setVideosActive(!videosActive)} 
    style={{'background': bgColor, 'cursor':'pointer'}}>
      {text}
    </div>;

  }else if(text === "eBooks"){
    content = <div className='category font-weight-bold me-3' onClick={() => setEbooksActive(!ebooksActive)} 
    style={{'background': bgColor, 'cursor':'pointer'}}>
      {text}
    </div>;

  }else if(text === "Magazines"){
    content = <div className='category font-weight-bold me-3' onClick={() => setMagazinesActive(!magazinesActive)} 
    style={{'background': bgColor, 'cursor':'pointer'}}>
      {text}
    </div>;

  }else if(text === "All"){
    content = <div className='category font-weight-bold me-3' onClick={() => setAllActive(!allActive)} 
    style={{'background': allColor, 'cursor':'pointer'}}>
      {text}
    </div>;

  }else {
    content = <div className='category font-weight-bold me-3' onClick={() => setIsActive(!isActive)} 
    style={{'background': bgColor, 'cursor':'pointer'}}>
      {text}
    </div>;
  }

  return(
    <>
      {content}  
    </>   
  )
}