import { Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import { NFTStorage, File, toGatewayURL } from "nft.storage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {INIT_ACCOUNT} from '../cadence/init_account'

import { Api } from "../apis";

const apiKey = 
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDMwODIzMjJkODUxODlBMGQ4OTAwZTAxNmIyYjhGNWY5NDEwNmI1QjMiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY0MDA0NzUxOTI4OCwibmFtZSI6InB1Ymxpc2hlZC1uZnQifQ.jyoH4mNvOpmFz0vj14Kmm2Y69cl9uBGoGadA0miHnlk";

const clientNFTStorage = new NFTStorage({ token: apiKey });

const _getCid = (ipfsLink) => {
  const _parts = ipfsLink.split("/");
  if (_parts.length > 3) {
    return _parts[_parts.length - 2];
  } else {
    return null;
  }
};
const ipfsHttpLink = (ipfsLink) => {
  const _cid = _getCid(ipfsLink);
  return `https://ipfs.io/ipfs/${_cid}`;
};

export default function Mint() {
  const [user, setUser] = useState({ loggedIn: null });
  const [videoFile, setVideoFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [nftType, setNftType] = useState(1);
  const [nftURL, setNftURL] = useState('');

  useEffect(() => { 
    fcl.currentUser.subscribe(setUser);
  }, []);

  const initAccount = async () => {
    var transactionId = await fcl.mutate({
      cadence: INIT_ACCOUNT,
      limit: 999,
    });   

    await fcl.tx(transactionId).onceSealed();
  };

  const uploadFileToNFTStorage = async () => {
    const metadata = await clientNFTStorage.store({
      name: 'video nft',
      description: 'video nft',
      image: new File([videoFile], videoFile.name, { type: videoFile.type })
    });  

    console.log(
      "nft storage submitted metadata : ",
      JSON.stringify(metadata, null, 2)
    );

    if (metadata && metadata.url) {
      const httpLink = toGatewayURL(metadata.url);
      console.log("httpLink: ", httpLink);
      console.log("httpsLink : ", httpLink.href);

      const _fileUrl = ipfsHttpLink(metadata.url);

      //* bafyreigb7qezbgh7z5dv6yogltx4kovufnmpuqjj4v377mh3u4bknv25ei/metadata.json
      const cidMetadataLink = httpLink.pathname.replace("/ipfs/", "");
      return cidMetadataLink;
    }
    return null;
  };

  const mintClick = async () => {
    try {
      setIsUploading(true);
      // const cidMetadata = await uploadFileToNFTStorage();
      // if (!cidMetadata) {
      //   setIsUploading(false);
      //   alert("Failed to upload meta data, please try after a moment.");
      //   return;
      // }
      // console.log("cidMetadataLink : ", cidMetadata);

      if(nftType === 1){
        for(let i = 0; i < 5000; i++ ){
          const resMint = await Api.mintReq(user.addr, nftURL, nftType);
          console.log('First Mint:', resMint);
        }
      }else if(nftType === 2){
        for(let i = 0; i < 100; i++ ){
          const resMint = await Api.mintReq(user.addr, nftURL, nftType);
          console.log('Second Mint:', resMint);
        }
      }      
      
      // if (resMint.status === 200) {
      //   // toast.success("Successfully minted!");
      //   setIsUploading(false);
      // } else {
      //   // toast.error(resMint.error);
      //   setIsUploading(false);
      // }
    } catch (ex) {
      toast.error(ex.message);
    }
    setIsUploading(false);
  }

  const handleNFTType = (e) => {
    var number = parseInt(e.target.value);
    setNftType(number);
  }

  const handleNFTURL = (e) => {
    setNftURL(e.target.value);
  }

  return(
    <div className="container mt-5 mb-5" style={{width: '50%'}}>
      <div className='text-center mb-5'>
        <h2 className='text-center mb-4'>Please initialize account to mint</h2>         
        {user.loggedIn &&
          <Button variant="primary" className='me-5' onClick={initAccount}>
            Initialize Account
          </Button>
        }  
      </div>      

      <Form>
        <Form.Group className="mb-3">
          <Form.Label>NFT Type (Should be integer between 1-3)</Form.Label>
          <Form.Control type="text" placeholder="Type" name="type" value={nftType} onChange={handleNFTType} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>URL</Form.Label>
          <Form.Control type="text" placeholder="URL" name="type" value={nftURL} onChange={handleNFTURL} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Upload Asset</Form.Label>
          <Form.Control type="file" onChange={(e) => setVideoFile(e.target.files[0])} />
        </Form.Group>
        
        {isUploading ?
          <p>Minting...</p>
        :
          <Button variant="primary" onClick={mintClick}>
            Mint
          </Button>
        }        
      </Form> 
      <ToastContainer />
    </div>
  )
}