import { useState, useEffect } from 'react';
import * as fcl from "@onflow/fcl";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Spinner, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { PURCHASENFT_DAPPER } from '../cadence/script/LibraryPass/purchaseNFT_dapper';
import { PURCHASEGROUND_DAPPER } from '../cadence/script/GroundWork29/purchaseGround_dapper';
import { PURCHASEBOAST_DAPPER } from "../cadence/script/Boast/purchaseBoast_dapper";
import { PURCHASESYLLABLES_DAPPER } from "../cadence/script/Syllable/purchaseSyllables_dapper";

export default function UpcomingDrop(props) {
  const { data, libraryPass, wisp, groundData, boast, syllable } = props;
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [isSuccess, setIsSucess] = useState(false);
  const [groundShow, setGroundShow] = useState(false);
  const [show, setShow] = useState(false);
  const [wispShow, setWispShow] = useState(false);
  const [boastShow, setBoastShow] = useState(false);
  const [syllableShow, setSyllableShow] = useState(false);
  const [user, setUser] = useState({ loggedIn: null });

  useEffect(() => { 
    fcl.currentUser.subscribe(setUser);   
  }, []);

  async function buyBoastItem() {
    try{
      setIsUploading(true);

      const transaction = await fcl.mutate({
        cadence: PURCHASEBOAST_DAPPER,
        limit: 999,
        args: (arg, t) => [
          arg('0x03599ddc5b277a6b', t.Address),
          arg('0x52cbea4e6f616b8e', t.Address),
          arg(boast[0].listingId, t.UInt64),
          arg('7.0', t.UFix64)
        ],
        authorizations: [fcl.authz],
      });

      console.log('TX ID -',transaction)

      await fcl.tx(transaction).onceSealed().then((res) => {
        console.log(res);
        setIsUploading(false);
        setIsSucess(true);
        toast.success("Successfully purchased!");
        handleClose();
      }).catch((err) => {
        setIsSucess(false);
        console.log(err);
        setIsUploading(false);
        toast.error(err);
        handleClose();       
      });

    }catch(ex){
      setIsSucess(false);
      console.log(ex);
      toast.error(ex.message);
      handleClose();
      setIsUploading(false);
      setIsSucess(false);
    }
    
  }

  async function buyGroundItem() {
    try{
      setIsUploading(true);

      const transaction = await fcl.mutate({
        cadence: PURCHASEGROUND_DAPPER,
        limit: 999,
        args: (arg, t) => [
          arg('0x03599ddc5b277a6b', t.Address),
          arg('0x52cbea4e6f616b8e', t.Address),
          arg(groundData[0].listingId, t.UInt64),
          arg('10.0', t.UFix64)
        ],
        authorizations: [fcl.authz],
      });

      console.log('TX ID -',transaction)

      await fcl.tx(transaction).onceSealed().then((res) => {
        console.log(res);
        setIsUploading(false);
        setIsSucess(true);
        toast.success("Successfully purchased!");
        handleClose();
      }).catch((err) => {
        setIsSucess(false);
        console.log(err);
        setIsUploading(false);
        toast.error(err);
        handleClose();       
      });

    }catch(ex){
      setIsSucess(false);
      console.log(ex);
      toast.error(ex.message);
      handleClose();
      setIsUploading(false);
      setIsSucess(false);
    }
    
  }

  async function buySyllableItem() {
    try{
      setIsUploading(true);

      const transaction = await fcl.mutate({
        cadence: PURCHASESYLLABLES_DAPPER,
        limit: 999,
        args: (arg, t) => [
          arg('0x03599ddc5b277a6b', t.Address),
          arg('0x52cbea4e6f616b8e', t.Address),
          arg(syllable[0].listingId, t.UInt64),
          arg('9.0', t.UFix64)
        ],
        authorizations: [fcl.authz],
      });

      console.log('TX ID -',transaction)

      await fcl.tx(transaction).onceSealed().then((res) => {
        console.log(res);
        setIsUploading(false);
        setIsSucess(true);
        toast.success("Successfully purchased!");
        handleClose();
      }).catch((err) => {
        setIsSucess(false);
        console.log(err);
        setIsUploading(false);
        toast.error(err);
        handleClose();       
      });

    }catch(ex){
      setIsSucess(false);
      console.log(ex);
      toast.error("Cannot Purchase this nft!");
      handleClose();
      setIsUploading(false);
      setIsSucess(false);
    }
    
  }
  
  async function buyItem() {

    try{
      setIsUploading(true);

      const transaction = await fcl.mutate({
        cadence: PURCHASENFT_DAPPER,
        limit: 999,
        args: (arg, t) => [
          arg('0x03599ddc5b277a6b', t.Address),
          arg('0x52cbea4e6f616b8e', t.Address),
          arg(libraryPass[0].listingId, t.UInt64),
          arg('25.0', t.UFix64)
        ],
        authorizations: [fcl.authz],
      });

      console.log('TX ID -',transaction)

      await fcl.tx(transaction).onceSealed().then((res) => {
        console.log(res);
        setIsUploading(false);
        setIsSucess(true);
        toast.success("Successfully purchased!");
        handleClose();
      }).catch((err) => {
        setIsSucess(false);
        console.log(err);
        setIsUploading(false);
        toast.error(err);
        handleClose();       
      });

    }catch(ex){
      setIsSucess(false);
      console.log(ex);
      toast.error(ex.message);
      handleClose();
      setIsUploading(false);
      setIsSucess(false);
    }
    
  }

  async function buyWispItem() {

    try{
      setIsUploading(true);

      const transaction = await fcl.mutate({
        cadence: PURCHASENFT_DAPPER,
        limit: 999,
        args: (arg, t) => [
          arg('0x03599ddc5b277a6b', t.Address),
          arg('0x52cbea4e6f616b8e', t.Address),
          arg(wisp[0].listingId, t.UInt64),
          arg('50.0', t.UFix64)
        ],
        authorizations: [fcl.authz],
      });

      console.log('TX ID -',transaction)

      await fcl.tx(transaction).onceSealed().then((res) => {
        console.log(res);
        setIsUploading(false);
        setIsSucess(true);
        toast.success("Successfully purchased!");
        handleClose();
      }).catch((err) => {
        setIsSucess(false);
        console.log(err);
        setIsUploading(false);
        toast.error(err);
        handleClose();       
      });

    }catch(ex){
      setIsSucess(false);
      console.log(ex);
      toast.error(ex.message);
      handleClose();
      setIsUploading(false);
      setIsSucess(false);
    }
    
  }  

  const clickDetail = (param) => {
    navigate("/description/" + param);
  }

  // Payment modal for Boast
  const handleBoastClose = () => setBoastShow(false);
  const handleBoastShow = () => setBoastShow(true);

  // Payment modal for Ground
  const handleGroundClose = () => setGroundShow(false);
  const handleGroundShow = () => setGroundShow(true);

  // Payment modal for LibraryPass
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Payment modal for Wisp
  const handleWispClose = () => setWispShow(false);
  const handleWispShow = () => setWispShow(true);

  // Payment modal for Syllable
  const handleSyllableClose = () => setSyllableShow(false);
  const handleSyllableShow = () => setSyllableShow(true);

  return (
    <>
      <div key={`root-${data._id}`} className="h-full w-full border-radius-10">
        {/* <div className='bg-info font-weight-bold text-center p-2' id="time-badge">
          <Timer fontStyle="font-heading" deadline={new Date(data.released)} />
        </div> */}

        <>
          <div className="bg-success font-weight-bold text-center p-2" id="cost-badge">          
            {data.cost}
          </div>

          <div className="font-weight-bold text-center p-2" id="mint-count-badge">
            {data.mintCount}
          </div>

          <div className="font-weight-bold mb-4" id="mint-description">
            <p className="mb-1">{data.title}</p>
            <p className="text-grey">{data.author}</p>
            {data.type === -2 &&
              <Button className="bg-primary font-weight-bold p-2 text-center" 
                onClick={handleSyllableShow} id="mint-badge">
                BUY NOW
              </Button>
            }

            {data.type === -1 &&
              <Button className="bg-primary font-weight-bold p-2 text-center" 
                onClick={handleBoastShow} id="mint-badge">
                BUY NOW
              </Button>
            }

            {data.type === 0 &&
              <Button className="bg-primary font-weight-bold p-2 text-center" 
                onClick={handleGroundShow} id="mint-badge">
                BUY NOW
              </Button>
            } 

            {data.type === 1 &&
              <Button className="bg-primary font-weight-bold p-2 text-center" 
                onClick={handleShow} id="mint-badge">
                BUY NOW
              </Button>
            }

            {data.type === 2 &&
              <Button className="bg-primary font-weight-bold p-2 text-center" 
                onClick={handleWispShow} id="mint-badge">
                BUY NOW
              </Button>
            }                    
          </div>
        </>            

        <div
          style={{
            width: "100%",
            height: "100%",
            padding: "2rem",
            background: `url(${data.thumbnail})`,
            backgroundSize: "cover",
            borderRadius: "10px",
            cursor: "pointer"
          }}
          onClick = {() => clickDetail(data.param)}
        />      
      </div>

      <Modal show={boastShow} onHide={handleBoastClose} centered backdrop={false}>
        <Modal.Header closeVariant='white'>
          <Modal.Title>
            {isUploading && 
              <h4 className='font-weight-bold'>
                Payment Processing
              </h4>
            }
            
            {!isUploading && !isSuccess &&
              <h4 className='font-weight-bold'>
                Payment Request
              </h4>
            }

            {!isUploading && isSuccess &&
              <h4 className='font-weight-bold'>
                Payment Complete
              </h4>
            }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='text-center'>                   
          <p className='font-weight-bold'>
            {data.title}
          </p>
          {isUploading && 
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <p>
                Your payment has been submitted and your transaction is being confirmed on the Flow blockchain.
              </p>
            </>
          }
          
          {!isUploading && !isSuccess &&
            <p>
              Please submit your payment to buy nft.
            </p>
          }

          {!isUploading && isSuccess &&
            <p>
              Your payment has completed and your transaction has been confirmed on the Flow blockchain.
            </p>
          }
          
        </Modal.Body>

        <Modal.Footer>          
          {!isUploading && !isSuccess &&
            <>
              <Button variant="primary" onClick={buyBoastItem}>
                Submit
              </Button>
              <Button variant="primary" onClick={handleBoastClose}>
                Close
              </Button>
            </>            
          }

          {!isUploading && isSuccess &&
            <Button variant="primary" onClick={handleBoastClose}>
              Close
            </Button>
          }                    
        </Modal.Footer>
      </Modal>

      <Modal show={groundShow} onHide={handleGroundClose} centered backdrop={false}>
        <Modal.Header closeVariant='white'>
          <Modal.Title>
            {isUploading && 
              <h4 className='font-weight-bold'>
                Payment Processing
              </h4>
            }
            
            {!isUploading && !isSuccess &&
              <h4 className='font-weight-bold'>
                Payment Request
              </h4>
            }

            {!isUploading && isSuccess &&
              <h4 className='font-weight-bold'>
                Payment Complete
              </h4>
            }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='text-center'>                   
          <p className='font-weight-bold'>
            {data.title}
          </p>
          {isUploading && 
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <p>
                Your payment has been submitted and your transaction is being confirmed on the Flow blockchain.
              </p>
            </>
          }
          
          {!isUploading && !isSuccess &&
            <p>
              Please submit your payment to buy nft.
            </p>
          }

          {!isUploading && isSuccess &&
            <p>
              Your payment has completed and your transaction has been confirmed on the Flow blockchain.
            </p>
          }
          
        </Modal.Body>

        <Modal.Footer>          
          {!isUploading && !isSuccess &&
            <>
              <Button variant="primary" onClick={buyGroundItem}>
                Submit
              </Button>
              <Button variant="primary" onClick={handleGroundClose}>
                Close
              </Button>
            </>            
          }

          {!isUploading && isSuccess &&
            <Button variant="primary" onClick={handleGroundClose}>
              Close
            </Button>
          }                    
        </Modal.Footer>
      </Modal> 

      <Modal show={syllableShow} onHide={handleSyllableClose} centered backdrop={false}>
        <Modal.Header closeVariant='white'>
          <Modal.Title>
            {isUploading && 
              <h4 className='font-weight-bold'>
                Payment Processing
              </h4>
            }
            
            {!isUploading && !isSuccess &&
              <h4 className='font-weight-bold'>
                Payment Request
              </h4>
            }

            {!isUploading && isSuccess &&
              <h4 className='font-weight-bold'>
                Payment Complete
              </h4>
            }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='text-center'>                   
          <p className='font-weight-bold'>
            {data.title}
          </p>
          {isUploading && 
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <p>
                Your payment has been submitted and your transaction is being confirmed on the Flow blockchain.
              </p>
            </>
          }
          
          {!isUploading && !isSuccess &&
            <p>
              Please submit your payment to buy nft.
            </p>
          }

          {!isUploading && isSuccess &&
            <p>
              Your payment has completed and your transaction has been confirmed on the Flow blockchain.
            </p>
          }
          
        </Modal.Body>

        <Modal.Footer>          
          {!isUploading && !isSuccess &&
            <>
              <Button variant="primary" onClick={buySyllableItem}>
                Submit
              </Button>
              <Button variant="primary" onClick={handleSyllableClose}>
                Close
              </Button>
            </>            
          }

          {!isUploading && isSuccess &&
            <Button variant="primary" onClick={handleSyllableClose}>
              Close
            </Button>
          }                    
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose} centered backdrop={false}>
        <Modal.Header closeVariant='white'>
          <Modal.Title>
            {isUploading && 
              <h4 className='font-weight-bold'>
                Payment Processing
              </h4>
            }
            
            {!isUploading && !isSuccess &&
              <h4 className='font-weight-bold'>
                Payment Request
              </h4>
            }

            {!isUploading && isSuccess &&
              <h4 className='font-weight-bold'>
                Payment Complete
              </h4>
            }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='text-center'>                   
          <p className='font-weight-bold'>
            {data.title}
          </p>
          {isUploading && 
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <p>
                Your payment has been submitted and your transaction is being confirmed on the Flow blockchain.
              </p>
            </>
          }
          
          {!isUploading && !isSuccess &&
            <p>
              Please submit your payment to buy nft.
            </p>
          }

          {!isUploading && isSuccess &&
            <p>
              Your payment has completed and your transaction has been confirmed on the Flow blockchain.
            </p>
          }
          
        </Modal.Body>

        <Modal.Footer>          
          {!isUploading && !isSuccess &&
            <>
              <Button variant="primary" onClick={buyItem}>
                Submit
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </>            
          }

          {!isUploading && isSuccess &&
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          }                    
        </Modal.Footer>
      </Modal>     

      <Modal show={wispShow} onHide={handleWispClose} centered backdrop={false}>
        <Modal.Header closeVariant='white'>
          <Modal.Title>
            {isUploading && 
              <h4 className='font-weight-bold'>
                Payment Processing
              </h4>
            }
            
            {!isUploading && !isSuccess &&
              <h4 className='font-weight-bold'>
                Payment Request
              </h4>
            }

            {!isUploading && isSuccess &&
              <h4 className='font-weight-bold'>
                Payment Complete
              </h4>
            }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='text-center'>                   
          <p className='font-weight-bold'>
            {data.title}
          </p>
          {isUploading && 
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <p>
                Your payment has been submitted and your transaction is being confirmed on the Flow blockchain.
              </p>
            </>
          }
          
          {!isUploading && !isSuccess &&
            <p>
              Please submit your payment to buy nft.
            </p>
          }

          {!isUploading && isSuccess &&
            <p>
              Your payment has completed and your transaction has been confirmed on the Flow blockchain.
            </p>
          }
          
        </Modal.Body>

        <Modal.Footer>          
          {!isUploading && !isSuccess &&
            <>
              <Button variant="primary" onClick={buyWispItem}>
                Submit
              </Button>
              <Button variant="primary" onClick={handleWispClose}>
                Close
              </Button>
            </>            
          }

          {!isUploading && isSuccess &&
            <Button variant="primary" onClick={handleWispClose}>
              Close
            </Button>
          }                    
        </Modal.Footer>
      </Modal> 
    </>    
  );
}