import { useEffect } from 'react';

export default function About(){
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return(    
    <div className='container'>
      <div className='description-pad mt-5 mb-5 p-3'>
        <h1 className='mt-5 mb-5 text-center'>
          Published NFT
        </h1>
        <div className='row mb-5'>
          <h4>
            Introduction
          </h4>
          <p>
            Published NFT is a pioneering Web3 publishing platform, in collaboration with industry leader Dapper Labs, that seeks to revolutionize the eBook and 
            audiobook markets by bringing them into the blockchain era. By leveraging blockchain technology, specifically the Flow Blockchain, 
            our platform enables the creation, distribution, and monetization of digital content in an entirely new way, with a focus on transparency, accessibility, 
            and environmental sustainability.
          </p>

          <h4>
            Mission
          </h4>
          <p>
            Our mission is to democratize access to digital content, particularly for underserved communities and emerging creators, 
            while creating new revenue streams for artists, authors, and collectors. We aim to provide a platform where creativity meets technology, 
            fostering a community-driven ecosystem where eBooks, audiobooks, and related digital assets can be owned, traded, and experienced like never before.
          </p>

          <h4>
            Content and Distribution
          </h4>
          <p>
            Published NFT will release a wide range of eBooks, including public domain books with covers reimagined by renowned and emerging artists. 
            These covers will be minted as unique NFT editions, with various rarity levels ranging from common to grail, offering both collectors and 
            readers the opportunity to own a piece of literary and artistic history.
          </p>
          <p>
            Our platform also supports various distribution models for eBooks, including:
          </p>
          <p>
            - **1-of-1 NFTs:** Exclusive eBooks or private content like journals and diaries.<br/>
            - **Open Mints:** Unlimited editions available to all users.<br/>
            - **Limited Editions:** A set number of copies for a particular release, creating scarcity.<br/>
            - **Auctions:** Allowing market-driven pricing for rare or highly sought-after eBooks.<br/>
            - **Subscription NFTs:** Available in three tiers (3 months, 6 months, 1 year), these NFTs provide users with periodic airdrops, 
            special access, and the ability to trade them on the marketplace before they expire and become collectible assets.
          </p>

          <h4>
            Innovative Features
          </h4>
          <p>
            - **3D eBooks and VR/AR/MR Compatibility:** Our platform will support 3D eBooks, which can be opened and read in virtual, augmented, 
            and mixed reality environments. This feature is compatible with devices like Apple Vision Pro and Meta Quest.
          </p>
          <p>
            - **Read-to-Earn and Quest Systems:** Users can participate in interactive reading challenges, quizzes, and quests to earn NFTs, rewards, and airdrops. 
            These features incentivize reading and engagement, making the literary experience more dynamic and rewarding.
          </p>
          <p>
            - **Bookmark NFTs:** Designed by global artists, these collectible bookmarks come with various rarity levels and can be used as placeholders within eBooks. 
            They also unlock special access, gifts, and rewards, depending on the sets you complete and the quantity you own.
          </p>
          <p>
            - **Audiobook NFTs:** We will also offer audiobook NFTs, providing another layer of digital ownership and distribution. 
            These audiobooks will be available in various formats and may include exclusive content or readings by celebrity voices.
          </p>

          <h4>
            Community and Governance
          </h4>
          <p>
            Published NFT is deeply committed to community engagement and governance through our DAO (Decentralized Autonomous Organization). 
            Users can donate cryptocurrencies, NFTs, or purchase PAGE tokens to participate in community decision-making. With our Cast platform, 
            users can create proposals, vote on initiatives, and shape the future of Published NFT, depending on the number of eBooks and PAGE tokens they hold.
          </p>

          <h4>
            Roles and Recognition
          </h4>
          <p>
            Our platform recognizes and rewards contributions from all members of our ecosystem—authors, readers, artists, and collectors—through a system of roles and badges.
            Notably, users who become "Librarians" by owning a Genesis Pass or collecting over 100 eBooks can create and manage their own virtual bookstores. 
            These bookstores will be hubs for trading, renting, or showcasing digital collections.
          </p>

          <h4>
            Sustainability and Longevit
          </h4>
          <p>
            Published NFT is designed to be a long-term player in the digital content space. We are building a platform that will continue to release an endless stream of 
            content over the next 30 years and beyond, ensuring that our ecosystem remains vibrant and relevant. As more eBooks are released, the value of roles, badges, 
            and subscription NFTs will continue to grow, creating a sustainable and evolving marketplace for digital content.
          </p>

          <h4>
            Conclusion
          </h4>
          <p>
            Published NFT is more than just a platform; it is a movement aimed at transforming the digital content landscape. By combining blockchain technology, 
            innovative features, and a strong community focus, we are creating a new paradigm for how eBooks, audiobooks, and related assets are created, distributed, 
            and experienced. We invite artists, authors, readers, and collectors to join us on this journey and help shape the future of digital content.
          </p>
        </div>        
      </div>
    </div>  
  )
}