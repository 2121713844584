import { Navbar, Nav, Modal } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from 'react';
import { Button, OverlayTrigger, Tooltip, Overlay, Dropdown } from "react-bootstrap";
import * as fcl from "@onflow/fcl";
import { FaBars, FaCheckCircle, FaUser } from 'react-icons/fa';

export default function Header() {
  const [user, setUser] = useState({ loggedIn: null });
  const [copiedStr, setCopiedStr] = useState("Copy to clipboard");
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768; 

  useEffect(() => { 
    fcl.currentUser.subscribe(setUser);
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {copiedStr}
    </Tooltip>
  );

  const renderTooltipInit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Initialize wallet before buying NFTs
    </Tooltip>
  );

  const addrClick = (e) => {
    navigator.clipboard.writeText(e.target.innerHTML);
    setCopiedStr("Copied");
  }

  const dropsClick = () => {
    navigate("/", {state: {isDrops:true}});
  }

  return (
    <>
      <Navbar className="bg-black px-1 pt-1 pb-1" expand="lg">
        <Navbar.Brand className='p-0'>
          <Link to="/">
            <img src="/img/logo.png" alt="" style={{ width: '150px', height: '80px' }} />
            <span className="badge bg-warning text-white beta-badge">Beta</span>
          </Link>        
        </Navbar.Brand>

        {(user.loggedIn && isMobile) &&
          <Dropdown>
            <Dropdown.Toggle className="profile-button" align="end" id="dropdown-menu-align-end">
              <FaUser className='text-green' size={20} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-grey" align="end">
              <Dropdown.Item href="#/action-1">
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250 }}
                  overlay={renderTooltip}
                >
                  <button className='addr-btn font-weight-bold me-2 p-0' onClick={(e) => addrClick(e)} 
                    onMouseLeave={() => setCopiedStr("Copy to clipboard")}>
                    {user.addr}
                  </button>
                </OverlayTrigger>
              </Dropdown.Item>
              <hr className='text-white mt-1 mb-1'/>

              <Dropdown.Item href="#/action-2">
                <a className='text-white font-weight-bold mb-0' href='https://accounts.meetdapper.com/' 
                  target="_blank">
                  My Dapper Wallet
                </a>
              </Dropdown.Item>
              <hr className='text-white mt-1 mb-1'/>

              <Dropdown.Item href="#/action-3">
                <p className='text-white font-weight-bold mb-0' role="button" onClick={fcl.unauthenticate}>
                  Sign out
                </p>  
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }       

        <Navbar.Toggle> <FaBars className='text-white '/> </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className='align-items-center'>
            <a target="_blank" href="https://evaluate.xyz/swap">
              <img src="/trade.png" alt="" width="40px" height="40px" />
            </a>
            {/* <a className="text-white font-weight-bold m-2" target="_blank"
              href="https://toucans.ecdao.org/p/PublishedNFTDAO">
              DAO
            </a>    */}
            <Link className="text-white font-weight-bold m-2" to="/bookstore">
              Bookstore
            </Link>
            <Link className="text-white font-weight-bold m-2" to='/metaverse'>
              Metaverse
            </Link>
            {/* <a className="text-white font-weight-bold m-2" target="_blank"
              href="https://cast.fyi/#/community/37?tab=about">
              Cast
            </a>    */}
            <Link className="text-white font-weight-bold m-2" to="/marketplace">
              Marketplace
            </Link>
            <a className="text-white font-weight-bold m-2"  target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdkkt5AU3WwyCdeit0U3vc0HaejojffatR31EbjGDYNSsBb2A/viewform">
              Get Published
            </a>

            {user.loggedIn &&
              <Link className="text-primary font-weight-bold m-2" to="/collection">
                My Library
              </Link>            
            }

            {!user.loggedIn &&
              <Button variant="primary ml-3" size="sm" onClick={fcl.logIn} style={{height:"90%"}}>
                Sign In/Up
              </Button>
            }           
          </Nav>
        </Navbar.Collapse>  
        
        {(user.loggedIn && !isMobile) &&
          <Dropdown>
            <Dropdown.Toggle className="profile-button" align="end" id="dropdown-menu-align-end">
              <FaUser className='text-green' size={20} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-grey" align="end">
              <Dropdown.Item href="#/action-1">
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250 }}
                  overlay={renderTooltip}
                >
                  <button className='addr-btn font-weight-bold me-2 p-0' onClick={(e) => addrClick(e)} 
                    onMouseLeave={() => setCopiedStr("Copy to clipboard")}>
                    {user.addr}
                  </button>
                </OverlayTrigger>
              </Dropdown.Item>
              <hr className='text-white mt-1 mb-1'/>

              <Dropdown.Item href="#/action-2">
                <a className='text-white font-weight-bold mb-0' href='https://accounts.meetdapper.com/' 
                  target="_blank">
                  My Dapper Wallet
                </a>
              </Dropdown.Item>
              <hr className='text-white mt-1 mb-1'/>

              <Dropdown.Item href="#/action-3">
                <p className='text-white font-weight-bold mb-0' role="button" onClick={fcl.unauthenticate}>
                  Sign out
                </p>  
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }                    
      </Navbar>
    </>
  )
}