import { useEffect, useState } from "react";
import { Modal, Button, InputGroup, FormControl, Form, Card, Badge } from "react-bootstrap";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { UnorderedList, ListItem } from "@chakra-ui/react";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useNavigate, useLocation, Link } from "react-router-dom";
import * as fcl from "@onflow/fcl";
import { ToastContainer } from "react-toastify";
import featuredDrops from "../mock/featured.json";
import upcomingDrops from "../mock/upcoming.json";
import UpcomingDrop from "../components/UpcomingDrop";
import { GET_LISTINGS_WITH_DATA } from "../cadence/script/LibraryPass/getListingsWithData";
import { GET_GROUND_WITH_DATA } from "../cadence/script/GroundWork29/getGroundWithData";
import { GET_BOAST_WITH_DATA } from "../cadence/script/Boast/getBoastWithData";
import { GET_Syllables_WITH_DATA } from "../cadence/script/Syllable/getSyllablesWithData";

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  const submitOnEnter = (event) => {
    if (event.keyCode === 13) {
      submit();
    }
  };

  return (
    <div className="newsletter-form">
      <input
        ref={(node) => (email = node)}
        type="email"
        onKeyDown={(e) => submitOnEnter(e)}
        placeholder="Email"
        className= "form-control m-auto"
        style={{ width: "80%" }}
        required
      />

      <button
        className="bg-primary text-white border-radius-10 px-3 pt-1 pb-1 mt-2"
        onClick={submit}
      >
        Subscribe
      </button>    

      {status === "sending" && (
        <div className="subs-sending-msg">sending...</div>
      )}
      {status === "error" && (
        <div
          className="subs-error-msg"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="subs-success-msg"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};

const ModalForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  const submitOnEnter = (event) => {
    if (event.keyCode === 13) {
      submit();
    }
  };

  return (
    <div className="newsletter-form">
      <InputGroup className="mb-3 mt-3">
        <FormControl
          placeholder="Email"
          aria-label="Email"
          aria-describedby="basic-addon2"
          ref={(node) => (email = node)}
          onKeyDown={(e) => submitOnEnter(e)}
          required
        />
        <Button variant="primary" id="button-addon2" onClick={submit}>
          Submit
        </Button>
      </InputGroup>

      {status === "sending" && (
        <div className="subs-sending-msg">sending...</div>
      )}
      {status === "error" && (
        <div
          className="subs-error-msg"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="subs-success-msg"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};

export default function HomeOld() {
  const url =
    "https://publishednft.us6.list-manage.com/subscribe/post?u=07e4e935a467fc4f628f335ba&amp;id=7b096762a9";
  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [nftData, setNftData] = useState([]);
  const [groundData, setGroundData] = useState([]);
  const [boast, setBoast] = useState([]);
  const [syllable, setSyllable] = useState([]);
  
  const libraryPass = nftData.filter(({ type }) => type === "Genesis Library Pass");
  const wisp = nftData.filter(({ type }) => type === "The Wisp");

  useEffect(() => {
    // fcl.query({
    //   cadence: GET_LISTINGS_WITH_DATA,
    //   args: (arg, t) => [arg("0x52cbea4e6f616b8e", t.Address)], //mainnet
    //   // args: (arg, t) => [arg("0x00af2ab363fa49e4", t.Address)], //testnet
    // }).then(setNftData).catch(console.log);

    // fcl.query({
    //   cadence: GET_GROUND_WITH_DATA,
    //   args: (arg, t) => [arg("0x52cbea4e6f616b8e", t.Address)], //mainnet
    // }).then(setGroundData).catch(console.log);

    // fcl.query({
    //   cadence: GET_BOAST_WITH_DATA,
    //   args: (arg, t) => [arg("0x52cbea4e6f616b8e", t.Address)], //mainnet
    // }).then(setBoast).catch(console.log);

    // fcl.query({
    //   cadence: GET_Syllables_WITH_DATA,
    //   args: (arg, t) => [arg("0x52cbea4e6f616b8e", t.Address)], //mainnet
    // }).then(setSyllable).catch(console.log);
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clickDetail = (type) => {
    navigate("/description/" + type, { state: { type: type } });
  };

  
  return (
    <div className="container">
      <div className="row p-3 mt-3">
        <div className="col-md-7">
          <h5 className="font-weight-bold">Recent Drops</h5>
          <Splide
            options={{
              autoplay: "true",
              gap: "1em",
              wheel: true,
              waitForTransition: false,
              arrows: true,
              widthRatio: 1,
              height: "45vh",
              breakpoints: {
                640: {
                  arrows: false,
                  widthRatio: 1,
                  height: "30vh",
                },
                400: {
                  arrows: false,
                  widthRatio: 1,
                  height: "18vh",
                },
              },
            }}
          >
            {featuredDrops.map((slide, index) => {
              if (slide.type <= 1)
                return (
                  <SplideSlide key={Math.random()}>
                    <UpcomingDrop data={slide} libraryPass={libraryPass} wisp={wisp} groundData={groundData} boast={boast} syllable={syllable}/>
                  </SplideSlide>
                )
              }
            )}
          </Splide>
        </div>

        <div className="col-md-5">
          <h5 className="font-weight-bold text-center">Featured Drops</h5>
          <UnorderedList
            id="recent-order"
            className="mt-1 bg-gray border-radius-10"
          >
            {upcomingDrops.map((item, index) => (
              <div key={"root-" + item._id} className="flex flex-col w-full">
                <ListItem
                  key={item._id}
                  className="flex w-full justify-content-between p-3"
                  style={{ height: "100px" }}
                >
                  <div className="flex flex-col w-full">
                    <p
                      className="font-heading text-white mb-0"
                      style={{ cursor: "pointer", width: "fit-content" }}
                      onClick={() => clickDetail(item.param)}
                    >
                      {item.title}
                    </p>

                    <div className="flex justify-content-between px-2">
                      <p className="opacity-50 font-sm mb-0">{item.author}</p>
                      <p className="opacity-50 font-sm mb-0">{item.category}</p>
                      <p className="opacity-50 font-sm mb-0">{item.cost}</p> 

                      {item.type === 1 ? (
                        libraryPass.length === 0 ? (
                          <Button
                            className="bg-danger text-center font-weight-bold border-radius-10 p-2"
                            size="sm"
                            onClick={() => clickDetail(item.param)}
                          >
                            SOLD OUT
                          </Button>
                        ) : (
                          <Button
                            className="green-button text-center font-weight-bold border-radius-10 p-2"
                            size="sm"
                            onClick={() => clickDetail(item.param)}
                          >
                            AVAILABLE
                          </Button>
                        )
                      ) : (
                        <></>
                      )}

                      {item.type === 2 ? (
                        wisp.length === 0 ? (
                          <Button
                            className="bg-danger text-center font-weight-bold border-radius-10 p-2"
                            size="sm"
                            onClick={() => clickDetail(item.param)}
                          >
                            SOLD OUT
                          </Button>
                        ) : (
                          <Button
                            className="green-button text-center font-weight-bold border-radius-10 p-2"
                            size="sm"
                            onClick={() => clickDetail(item.param)}
                          >
                            AVAILABLE
                          </Button>
                        )
                      ) : (
                        <></>
                      )}

                      {item.type === 3 ? (
                        groundData.length === 0 ? (
                          <Button
                            className="bg-danger text-center font-weight-bold border-radius-10 p-2"
                            size="sm"
                            onClick={() => clickDetail(item.param)}
                          >
                            SOLD OUT
                          </Button>
                        ) : (
                          <Button
                            className="green-button text-center font-weight-bold border-radius-10 p-2"
                            size="sm"
                            onClick={() => clickDetail(item.param)}
                          >
                            AVAILABLE
                          </Button>
                        )
                      ) : (
                        <></>
                      )}      

                      {item.type === 4 ? (
                        boast.length === 0 ? (
                          <Button
                            className="bg-danger text-center font-weight-bold border-radius-10 p-2"
                            size="sm"
                            onClick={() => clickDetail(item.param)}
                          >
                            SOLD OUT
                          </Button>
                        ) : (
                          <Button
                            className="green-button text-center font-weight-bold border-radius-10 p-2"
                            size="sm"
                            onClick={() => clickDetail(item.param)}
                          >
                            AVAILABLE
                          </Button>
                        )
                      ) : (
                        <></>
                      )}       

                      {item.type === 5 ? (
                        syllable.length === 0 ? (
                          <Button
                            className="bg-danger text-center font-weight-bold border-radius-10 p-2"
                            size="sm"
                            onClick={() => clickDetail(item.param)}
                          >
                            SOLD OUT
                          </Button>
                        ) : (
                          <Button
                            className="green-button text-center font-weight-bold border-radius-10 p-2"
                            size="sm"
                            onClick={() => clickDetail(item.param)}
                          >
                            AVAILABLE
                          </Button>
                        )
                      ) : (
                        <></>
                      )}                            
                    </div>
                  </div>
                  
                  <div
                    className="recent-img"
                    style={{
                      backgroundImage: `url(${item.thumbnail})`,
                      cursor: "pointer",
                    }}
                    onClick={() => clickDetail(item.param)}
                  ></div>
                </ListItem>
                {index !== upcomingDrops.length && (
                  <hr
                    key={"divider-" + item._id}
                    className="my-2 text-white opacity-35"
                  ></hr>
                )}
              </div>
            ))}
          </UnorderedList>
        </div>
      </div>

      <div className="row container justify-content-center m-auto mt-5">
        <h3 className="font-heading font-weight-bold text-center">
          Upcoming releases
        </h3>

        <div className="col-md-2">
          <Card className="transparent">
            <Card.Img variant="top" src="feature-05.png" onClick={() => clickDetail("element")} role="button" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0" onClick={() => clickDetail("element")} role="button">
                <u>The Element</u>
              </p>
              <p className="text-white font-sm mb-0 reduce-text">by Rainy Dayz</p>
            </Card.Body>
          </Card>
        </div>        

        <div className="col-md-2">
          <Card className="transparent">
            <Card.Img variant="top" src="feature-09.png" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0">
                <u>Decoding Indian Economy</u>
              </p>
              <p className="text-white font-sm mb-0 reduce-text">by Goenkans</p>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-2">
          <Card className="transparent">
            <Card.Img variant="top" src="feature-04.png" onClick={() => clickDetail("nightvision")} role="button" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0" onClick={() => clickDetail("nightvision")} role="button">
                <u>NIGHT vision</u>
              </p>
              <p className="text-white font-sm mb-0 reduce-text">by Greg R. Fishbone</p>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-2">
          <Card className="transparent">
            <Card.Img variant="top" src="feature-02.png" onClick={() => clickDetail("delightfulnovella")} role="button" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0" onClick={() => clickDetail("delightfulnovella")} role="button">
                <u>Delightful Novella</u>
              </p>
              <p className="text-white font-sm mb-0 reduce-text">by Sanjay Rout</p>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-2">
          <Card className="transparent">
            <Card.Img variant="top" src="feature-07.png" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0">
                <u>Dansuki Village</u>
              </p>
              <p className="text-white font-sm mb-0 reduce-text">by Elisha Arowojobe</p>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-2">
          <Card className="transparent">
            <Card.Img variant="top" src="feature-08.png" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0">
                <u>Notes from nowhere</u>
              </p>
              <p className="text-white font-sm mb-0 reduce-text">by CD Damitio</p>
            </Card.Body>
          </Card>
        </div>       
      </div>

      <div className="row container justify-content-center m-auto">
        <div className="col-md-2">
          <Card className="transparent">
            <Card.Img variant="top" src="feature-10.png" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0">
                <u>May Nothingness Spare Us</u>
              </p>
              <p className="text-white font-sm mb-0 reduce-text">by Jose Covo</p>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-2">
          <Card role="button" className="transparent">
            <Card.Img variant="top" src="feature-03.png" onClick={() => clickDetail("humanminds")} role="button" />
            <Card.Body className="text-center pb-0">
              <p className="text-white reduce-text mb-0" onClick={() => clickDetail("humanminds")} role="button">
                <u>Human Minds Super Power</u>
              </p>
              <p className="text-white font-sm mb-0 reduce-text">by Sanjay Rout</p>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="bg-gray p-3 mt-3 text-center row justify-content-center">
        <div className="col-md-6">
          <h3 className="font-weight-bold text-center">
            Buy, sell, and trade your eBooks
          </h3>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
          <p className="pt-2 text-center m-auto" style={{ width: "80%" }}>
            Join Published NFT newsletter for latest news and updates!!!
          </p>
        </div>
      </div>

      <div className="mt-3 mb-3 p-3 text-center">
        <h3 className="font-weight-bold text-center">About Published NFT</h3>
        <p className="pt-4 text-center m-auto" style={{ width: "60%" }}>
          Published NFT is a blockchain eBook publishing platform built on the
          Flow blockchain, where authors can publish eBooks, Lyrics, Comics,
          Magazines, Articles, Poems, Recipes, Movie Scripts, Computer Language,
          etc.
        </p>
      </div>
      
      <ToastContainer />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Meet Cindy!!! Join The Community</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sign up for our email newsletter for first access to updates,
          promotions, and tutorials
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <ModalForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
          <Form.Group className="mb-3" id="formGridCheckbox">
            <Form.Check
              type="checkbox"
              label="I agree to allow PublishedNFT to send me emails."
            />
          </Form.Group>
        </Modal.Body>
        <img
          className="modal-img"
          src="\Cindy.png"
          alt=""
          width="546px"
          height="846px"
        />
      </Modal>
    </div>
  );
}
