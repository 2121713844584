import * as fcl from "@onflow/fcl";
import { useEffect, useState } from "react";
import { Card, Button, Alert } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CHECK_PUBLISHED_COLLECTION } from '../cadence/script/LibraryPass/checkPublishedCollection';
import { INIT_PUBLISHED_DAPPER } from '../cadence/script/LibraryPass/init_published_dapper';
import { CHECK_GROUND_COLLECTION } from '../cadence/script/GroundWork29/checkGroundCollection';
import { INIT_GROUND_DAPPER } from '../cadence/script/GroundWork29/init_ground_dapper';

export default function Init(){
  const [user, setUser] = useState({ loggedIn: null });
  const [isLibraryPass, setIsLibraryPass] = useState(false);
  const [isGroundwork, setIsGroundwork] = useState(false);

  useEffect(() => { 
    fcl.currentUser.subscribe(setUser);
    // checkLibraryPassInitialized();
    checkGroundworkInitialized();
  }, []);

  useEffect(() => {
    // checkLibraryPassInitialized();
    checkGroundworkInitialized();
  }, [user]); 

  const checkLibraryPassInitialized = async () => {
    const currentUser = await fcl.currentUser().snapshot();
    if(currentUser.addr != null){
      const res = await fcl.query({
        cadence: CHECK_PUBLISHED_COLLECTION,
        args: (arg, t) => [arg(currentUser?.addr, t.Address)],
      });
          
      console.log('init library - ', res);
      setIsLibraryPass(res);
    }
  };
  const initLibraryPass = async () => {
    var transactionId = await fcl.mutate({
      cadence: INIT_PUBLISHED_DAPPER,
      limit: 999,
    }); 

    await fcl.tx(transactionId).onceSealed().then((res) => {
      console.log(res);
      toast.success("Successfully initialized for LibraryPass and Wisp!");
      setIsLibraryPass(true);
    }).catch((err) => {
      console.log(err);
      toast.error(err);
    });
  };

  const checkGroundworkInitialized = async () => {
    const currentUser = await fcl.currentUser().snapshot();
    if(currentUser.addr != null){
      const res = await fcl.query({
        cadence: CHECK_GROUND_COLLECTION,
        args: (arg, t) => [arg(currentUser?.addr, t.Address)],
      });
          
      console.log('init groundwork - ', res);
      setIsGroundwork(res);
    }
  };
  const initGroundwork = async () => {
    var transactionId = await fcl.mutate({
      cadence: INIT_GROUND_DAPPER,
      limit: 999,
    }); 

    await fcl.tx(transactionId).onceSealed().then((res) => {
      console.log(res);
      toast.success("Successfully initialized for Groundwork 29!");
      setIsGroundwork(true);
    }).catch((err) => {
      console.log(err);
      toast.error(err);
    });
  };

  return(
    <div className="container mt-5">
      <h1 className="text-white text-center mb-5">
        Please initialize your wallet to mint NFTs
      </h1>

      <div className="row mb-5">
        <div className="col-md-3 p-3">
          <Card bg="light" key="light" text="dark" className="mb-2">
            <Card.Img variant="top" src="./librarypass.png" />
            <Card.Body className="text-center">
              <Card.Title className="text-white text-center mb-4">
                LibraryPass & Wisp
              </Card.Title>

              {isLibraryPass ?
                <Alert key='success' variant='success' className="mb-0 font-sm">
                  Your account is already initialized.
                </Alert>
              :
                <Button variant="primary" onClick={initLibraryPass}>
                  Initialize
                </Button>
              }                          
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-3 p-3">
          <Card bg="light" key="light" text="dark" className="mb-2">
            <Card.Img variant="top" src="./feature-01.png" />
            <Card.Body className="text-center">
              <Card.Title className="text-white mb-4">
                Groundwork 29
              </Card.Title>

              {isGroundwork ?
                <Alert key='success' variant='success' className="mb-0 font-sm">
                  Your account is already initialized.
                </Alert>
              :
                <Button variant="primary" onClick={initGroundwork} className="m-auto">
                  Initialize
                </Button>
              }
            </Card.Body>
          </Card>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}