export default function Metaverse(){
  return(
    <div className='metaverse-bg'>
      <div className='container text-center'>
        <h1 className='mb-5'>
          Metaverse Museum
        </h1>

        <iframe width="70%" height="500px" src="https://www.youtube.com/embed/e8dN4dwGnH4?si=P798oIfDjOfE8ppf" frameBorder="0" allowFullScreen>          
        </iframe>

        <div className='d-flex justify-content-center mt-3 pb-3'>
          <a href='https://youtu.be/e8dN4dwGnH4?si=P798oIfDjOfE8ppf'>
            <img src="./youtube.png" alt="" width="150px" height="50px" />
          </a>
        </div>                
      </div>
    </div>
    
  )
}