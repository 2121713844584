import { useEffect } from 'react';

export default function Terms(){
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return(
    <div className='container'>
      <h1 className='mt-5 mb-5 text-center'>Published NFT Terms Of Service</h1>
      <p>
        Below are the Terms and Conditions (these “Terms”) governing Your use of the Published NFT Platform currently located 
        at <a href="https://publishednft.io/" alt="">https://publishednft.io/</a> (together with any tools, features, functionality, materials and services available therein,
        the “Services”). The Services provide users the opportunity to purchase, sell, trade, rent, pawn, collect and display 
        digital assets (“Digital Collectibles”) featuring Content (as defined below) provided by Published NFT, LLC 
        (“Published NFT”) and its licensors (together Published NFT and its licensors, the “Content Providers”).
      </p>
      <p>
        The Terms are between You, on the one hand, and The Non-Fungible Token Company, LLC. (d/b/a Published NFT) 
        (“Published NFT”, “we”, “us” or “our”) and Content Providers (“Content Providers”), on the other hand 
        (and for the avoidance of doubt, the Terms do not apply between Published NFT and content providers). 
        The Terms hereby incorporate by this reference any additional terms and conditions displayed on or 
        accessed through the Services, or otherwise made available to You in connection with the Services or 
        Digital Collectibles, including the Content License Agreement (“Content License”) set forth below these Terms.
      </p>
      <p><b>
        These Terms contain very important information regarding Your rights and obligations, as well as conditions, 
        limitations and exclusions that might apply to You. Your access to and/or use of the Services, 
        including by clicking “Accept”, constitutes Your consent to these Terms. Please read these Terms carefully.
      </b></p>
      <p>
        For purposes of these Terms, “You” and “Your” means you as the user of the Services.
      </p>
      <p><b>
        THESE TERMS CONTAIN IMPORTANT PROVISIONS INCLUDING AN ARBITRATION PROVISION THAT REQUIRES YOU AND PUBLISHED NFT, 
        AND YOU AND Published NFT, TO RESOLVE ALL DISPUTES BY BINDING ARBITRATION INSTEAD OF IN COURT. PLEASE SEE PART VI 
        TITLED “DISPUTE RESOLUTION BY BINDING ARBITRATION AND CLASS ACTION WAIVER” BELOW. YOU WAIVE YOUR RIGHT TO TRIAL 
        BY JURY, AND EXCEPT AS PERMITTED BY LAW, YOU WILL NOT BE ABLE TO BRING ANY CLAIMS IN STATE OR FEDERAL COURT. 
        YOU HAVE THE RIGHT TO OPT-OUT OF ARBITRATION AS EXPLAINED IN PART VI BELOW.
      </b></p>

      <h5 className='mt-5'>
        TABLE OF CONTENTS
      </h5>
      <p>
        Part I — Legal Requirements of Use of the Services<br/>
        Part II — The Services<br/>
        Part III — Ownership and Content<br/>
        Part IV — Use of the Services<br/>
        Part V — Indemnification, Disclaimers and Limitation of Liability and Assumption of Risks<br/>
        Part VI — Dispute Resolution by Binding Arbitration and Class Action Waiver
        Part VII — Miscellaneous
      </p>
      <p>
        You must be at least 18 years of age, or such legal age as applicable for Your jurisdiction, to use the Services. 
        <b>By using the Services, You represent and warrant that (i) You are at least 18 years of age (or such other age of legality in Your jurisdiction),</b>
        (ii) You are an individual, natural person and will purchase Digital Collectibles for Your Account (as defined below) only and not on the 
        behalf of others, 
        (iii) <b>You reside in a jurisdiction where the Services are offered, and (iv) You are familiar and in compliance with all relevant local 
        laws of the jurisdiction in which You reside.</b>
      </p>

      <h5 className='mt-5'>
        Part I — LEGAL REQUIREMENTS OF USE OF THE SERVICES
      </h5>
      <p>
        Prior to Your accessing and/or using of the Services, You shall closely review these Terms and Published NFT’s privacy policy 
        (collectively “Privacy Policy”) (see Part IV Section 3 below). If You do not agree with these Terms and Privacy Policy, do not access 
        the Services for any purpose. Your access to, and use of, the Services is evidence of Your acceptance of these Terms and 
        Privacy Policy and to all applicable laws, statutes and ordinances. You must use the Services in accordance with all applicable international,
        federal, state and local laws and may not post or transmit anything that would give rise to any civil or criminal liability 
        for any party or otherwise violate any law.
      </p>
      <br/>
      <p>
        We may change these Terms from time to time by notifying You of such changes by any reasonable means, including by posting the revised 
        Terms through the Services. Any such changes will not apply to any dispute between You and us or You and Published NFT arising prior to 
        the date on which we posted the revised Terms incorporating such changes, or otherwise notified You of such changes. Your use of the 
        Services following any changes to these Terms will constitute Your acceptance of such changes. The “Last Updated” legend above indicates 
        when these Terms were last changed. We may, at any time and without liability (to us or Published NFT), modify or discontinue all or 
        part of the Services (including access to the Services via any third-party links); charge, modify or waive any fees required to use 
        the Services; or offer opportunities to some or all users of the Services.
      </p>

      <h5 className='mt-5'>
        Part II — THE SERVICES
      </h5>
      <h5 className='mt-3'>
        1. DEFINED TERMS
      </h5>
      <p>
        In addition to the capitalized terms used and defined throughout these Terms, the following capitalized terms have the following meanings:
        <br/><br/>
        a. “Buyer Users” means Users that buy or otherwise acquire Digital Collectibles via the Services.
        <br/><br/>
        b. “Content” means content, eBooks, materials, art, design and drawings (in any form or media, including, without limitation, 
        video, epub, pdf, audio or photographs), including Published NFT IP, associated with Digital Collectibles.
        <br/><br/>
        c. “Published NFT IP” means any and all intellectual property, copyrights, trade secrets, trademarks, know-how, patent rights or 
        any other intellectual property rights recognized in any country or jurisdiction in the world that is owned, licensed or controlled 
        by Published NFT. Published NFT IP includes any Content from or provided by Published NFT or the Content Providers.
        <br/><br/>
        d. “NFT” means a non-fungible token or similar digital item implemented on a blockchain. The NFT is separate and distinct from the 
        Content or any other Third Party IP (as defined below) with which it may be linked or associated.
        <br/><br/>
        e. “Services Wallet” means a digital wallet that is compatible with the applicable blockchain on which the Digital Collectibles 
        may be stored or transferred, such as the Dapper Wallet.
        <br/><br/>
        f. “Payments Account” means a third party payment provider account (such as a Circle payments account), which may or may not 
        sit on a blockchain, and that is used to facilitate payments on the Services.
        <br/><br/>
        g. “Seller Users” means Users that sell or otherwise transfer Digital Collectibles via the Services.
        <br/><br/>
        h. “Users” means users of the Services. For the avoidance of doubt, You are a User.
      </p>

      <h5 className='mt-3'>
        2. THE SERVICES
      </h5>
      <p>
        a. We may offer Services from time to time, which may allow users to purchase, bid on, earn, collect, sell, trade, rent, transfer and 
        showcase Digital Collectibles, whether as individual Digital Collectibles or packs of Digital Collectibles. Certain Digital Collectibles
        may have utility in collection challenges and game experiences which may be offered via the Services from time to time. To ensure that 
        the uniqueness and ownership of each Digital Collectible offered via the Services is accurately tracked, Published NFT uses blockchain 
        based NFT technology to demonstrate and record ownership and history of the Digital Collectible. Published NFT mints each Digital Collectible 
        as an NFT on a publicly available blockchain (such as the Flow blockchain). Once committed to the blockchain, the NFT cannot be removed, 
        including by us or any other third party (provided, however, as further set forth in the Content License, You acknowledge that the Content 
        is licensed to You and may be subject to further limitations and restrictions). NFTs are not used to make payments and are not mutually 
        interchangeable. These design features limit the usefulness of NFTs as a form of payment or substitute for currency. Instead, NFTs, 
        including any Digital Collectibles that are minted as NFTs, are only intended to be used for enjoyment as digital eBooks and collectibles.
        <br/><br/>
        b. You acknowledge and agree that when You purchase a Digital Collectible, You own the NFT but You do not own any intellectual property 
        rights in the Content associated with such Digital Collectible except for and subject to the license grants and conditions expressly 
        set forth in these Terms and the Content License.
      </p>

      <h5 className='mt-3'>
        3. NFT TRANSACTIONS
      </h5>
      <p>
        a. The Services may facilitate transactions for purchases and sales of Digital Collectibles between Buyer Users and Seller Users. However, 
        we or Published NFT may not be a party to any sales agreements between Buyer Users and Seller Users on the Services, and neither 
        content provider nor Published NFT are responsible for any breach or default by a Seller User or a Buyer User. That said, 
        we reserve the right to be the final decision maker on any disputes between Buyer Users and Seller Users arising from purchases of 
        Digital Collectibles via the Services, including in connection with any auctions or other purchase methods, except that our right to 
        be final decision maker does not extend to the Content or any Published NFT IP which is subject to the Content License and the rights 
        of Published NFT and the Content Providers.
        <br/><br/>
        b. If You have a dispute with one or more Users, You release Published NFT, us and the Content Providers (and each of our affiliates 
        and subsidiaries, and our and their respective officers, directors, employees and agents) from claims, demands and damages (actual and 
        consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. In entering into 
        this release You expressly waive any protections (whether statutory or otherwise) that would otherwise limit the coverage of this release 
        to include only those claims which You may know or suspect to exist in Your favor at the time of agreeing to this release.
        <br/><br/>
        c. We may collect revenue on the Services for such transactions via transaction fees and other applicable fees which we display when 
        You interact with the applicable Services. There may also be third party fees, including, for example, royalties related to the Content,
        or blockchain gas fees in connection with minting the NFTs or processing certain of Your transactions through the blockchain while 
        You use the Services.
        <br/><br/>
        d. You are responsible for Your interactions with other Users. We reserve the right, but are not obligated, to monitor interactions 
        between Users, and neither nor Published NFT Content Providers are liable for Your interactions with other Users, or for any 
        User’s actions or inactions.
      </p>

      <h5 className='mt-3'>
        4. AUCTION TERMS
      </h5>
      <p>
        a. The Digital Collectibles may be sold by Published NFT, or in a secondary sale by Seller Users, as part of an auction. 
        You will be permitted to submit bids for a Digital Collectible through the applicable Services or such other means we designate 
        in our sole discretion. You must pay for the purchase via the currency set forth by Published NFT, which may be in dollars or cryptocurrency. 
        Published NFT and Seller Users may set minimum bid amounts, reserve prices, or other rules and restrictions for any Digital Collectible auction, 
        as applicable.
        <br/><br/>
        b. As soon as You place and confirm Your bid amount, You agree that You may not, under any circumstances, amend, retract or revoke Your bid. 
        Neither nor Published NFT are responsible for any errors made in Your bidding. We reserve the right, in our sole and absolute discretion to 
        reject, revoke or refuse to accept any bid (even those that have been previously accepted); to withdraw any Digital Collectible from 
        being eligible for purchase, whether during or after the sale, including for failing to meet any reserve prices; to restart or continue 
        the bidding even if the bidding has finished; or in the case of error or dispute and whether during or after the sale, to continue the bidding, 
        determine the successful bidder, cancel the sale of the lot, or re-offer and re-sell any lot. If any dispute relating to bidding arises 
        during or after the sale, our decision in exercise of this option is absolute and final, except that our right to be final decision maker
        does not extend to the Content or any Published NFT IP which is subject to the Content License and the rights of the Content Providers.
        <br/><br/>
        c. Bids may only be submitted between the dates and times specified for that Digital Collectible, as applicable, or as otherwise 
        permitted by us in our sole discretion. However, Published NFT (or Seller User) may extend the bidding window to always ensure 
        a buffer period (such as five minutes) exists between the last bid and closing time of that bidding window. These extensions 
        may continue indefinitely until there is no more bidding activity on the Digital Collectible for such designated period.
        <br/><br/>
        d. The purchase price of any auction will include the hammer price and the buyer’s premium (in the case of a sale by auction) or a 
        “Buy Now” price if that is offered by Published NFT or Seller User, as applicable, as well as any all shipping and related costs and charges,
        transaction costs, including but not limited to gas fees, conversion fees, payment fees and other platform fees, any applicable duties, 
        goods, sales use, compensating or service tax or VAT.
        <br/><br/>
        e. Published NFT or Seller User may provide a “Buy Now” feature that allows You to pay for a Digital Collectible, as applicable at a 
        specific price without bidding during an auction. If You purchase such a lot at the “Buy Now” price, these Terms and auction terms 
        will apply to the purchase or bid in the same manner as if You had been the successful bidder in an auction for that Digital Collectible.
        <br/><br/>
        f. Orders or sales may not always be successfully sent or received for processing. For a variety of reasons, Your order or sale may 
        take longer to execute (or may have less available time to execute) than what is normally expected. Neither we nor the content provider 
        guarantee that Your order or sale via the Services will always be able to be executed.
      </p>

      <h5 className='mt-3'>
        5. USER ACCOUNTS AND WALLETS
      </h5>
      <p>
        a. Creating and Safeguarding Your Account. You may browse and check out offerings on the Published NFT Platform without creating an Account. 
        However, to actually transact on such Services (such as purchasing or selling Digital Collectibles), You will need to create an account or 
        link Dapper account, (“Account”) on the Services. You agree to provide us with accurate, complete and updated information for Your Account, 
        and may be required to provide, for example, a display name, username, wallet address, email address, password, and/or phone number. 
        By creating an Account, You represent and warrant that You have not been identified as a Specially Designated National or placed on 
        any sanctions list by the U.S. Treasury Department’s Office of Foreign Assets Control, the U.S. Commerce Department, or the 
        U.S. Department of State, or been placed on any European Union sanctions lists, United Nations sanctions lists, UK Her Majesty’s Treasury 
        sanctions lists or similar lists. You are solely responsible for any activity on Your Account and any associated wallets 
        (whether a Services Wallet, Payments Account, or otherwise) and for maintaining the confidentiality and security of Your password/username. 
        You shall not disclose, transmit, post, give away or otherwise share Your password/username with any non-authorized party or use the 
        password/username for any unauthorized purpose. Published NFT are not liable for any acts or omissions by You in connection with 
        Your Account or for any loss as a result of Your Account being compromised. You must immediately notify us pursuant to the “Contact Us” 
        section below if You know or have any reason to suspect that Your Account or password have been stolen, misappropriated or otherwise compromised.
        You agree not to create any Account if we have previously removed Your Account, or we previously banned You from any of our Services, 
        unless we provide written consent otherwise. You acknowledge that the Account is for Your personal use only. Any use of the Account 
        by a person other than You is strictly prohibited and will give us the rights to immediately terminate Your Account. You are legally 
        responsible for all activity placed through Your Account using Your username. We reserve the right to reclaim usernames without liability to You.
        <br/><br/>
        b. One Account Per User. Each individual user may only have one Account. We reserve the right, in our sole discretion, to determine 
        whether You have or control more than one Account. We further reserve the right to suspend or terminate any Account that we deem to 
        be in excess of the one Account permitted per individual user. During any period of suspension of an Account, You will not be able 
        to perform any actions on the Services through that Account.
        <br/><br/>
        c. NFT Wallets; Payments Accounts. To transact in Digital Collectibles via the Services (such as purchasing or selling Digital Collectibles), 
        You will need to create an Account and create and link (i) a Services Wallet and (ii) a Payments Account with that Account. 
        Each Services Wallet and each Payments Account can only be linked to one Account. All Digital Collectibles that You add, purchase or 
        transfer to Your Account will be stored in Your Account’s designated Services Wallet, and Your funds which can be used to engage in 
        transactions via the Services shall be stored in Your Payments Account which is linked to Your Account. At this time, 
        You will not be able to extract Your Digital Collectibles from the Services Wallet associated with Your Account to Your own external wallet. 
        Your use of the Payments Account is subject to the terms and conditions of the actual service provider of the Payments Account. 
        Users must familiarize themselves with the terms of use, technology and security protocols of any such third party wallet. 
        For instance the terms and conditions applicable to use of a Payments Account operated by Circle Internal Financial, Inc. are set 
        forth at https://www.circle.com/en/legal. Do not deposit, transfer, upload (or attempt any of the foregoing) any other cryptocurrency, 
        NFTs, or other digital assets from outside the applicable Services into any Services Wallet or Payments Account, unless Published NFT 
        or the applicable Payments Account service provider expressly permits You to do so as part of the functionality of the Services Wallet 
        or Payments Account, as applicable. Any such transferred item or items may be lost, and neither Published NFT nor any Content Providers 
        assume any responsibility for any such loss. We may terminate the offering or support of Services Wallets or Payments Account at any time, 
        in our sole discretion. If we believe You still have Digital Collectibles in Your Services Wallet at the time we determine to cease offering 
        or supporting Services Wallets, we will attempt to notify You to transfer such Digital Collectibles out of Your Services Wallet and into 
        an alternative wallet. However, neither Published NFT nor any Content Providers assume any responsibility or liability if You fail 
        to transfer such Digital Collectibles after receiving such notice. If we stop supporting Your Payments Account, Your funds will remain 
        in Your Payments Account subject to the applicable terms and conditions of the service provider of such Payments Account.
        <br/><br/>
        d. Verification and Payment.
        <br/><br/>
        i. In order to transact on the Services, Published NFT or its service providers may require You to provide additional information and 
        documentation as required by applicable law or regulation, including laws related to anti-money laundering. In such cases, Published NFT, 
        in its sole discretion, may pause or cancel Your transactions within the applicable Services until Published NFT, and/or its service providers, 
        have reviewed these documents and accepted them as satisfying the requirements of applicable law. If You do not provide complete and accurate 
        information and documentation in response to such a request, Your transactions may not be processed on the Services. You acknowledge that 
        there may also be a time delay between the time when You submit such information and when Published NFT and/or its service providers are 
        able to complete any anti-money laundering, know-your-client or similar checks. If, in Published NFT’s opinion, You do not satisfy our 
        bidder identification and registration procedures, Published NFT may refuse to register You to bid or permit Your participation in the 
        sale and if You make a successful bid, we may cancel Your bid and sell the Digital Collectible to another person.
        <br/><br/>
        ii. Certain of the Services allow You to purchase and otherwise transact in Digital Collectibles. You may pay for a Digital Collectible 
        purchased via the Services using Your Payments Account. Published NFT does not process any payments made on or through the Services. 
        Published NFT uses the services and Payments Account of third party service providers, including Circle Internet Financial, Inc., 
        to enable the processing of purchase and sale transactions of Digital Collectibles via the Services. You acknowledge and agree that 
        all information You provide with regards to a purchase of Digital Collectibles, including, without limitation, credit card or other 
        payment information that the Services may accept through a third-party platform or system from time-to-time (including via Your Payments Account),
        is accurate, current and complete, and You may not associate one credit card (or other payment information) with multiple Accounts. 
        You represent and warrant that You have the legal right to use the payment method You provide to a third-party platform or system that 
        fulfills payments for the Digital Collectible via Your Payments Account, including, without limitation, any credit card You provide 
        when completing a transaction. When You bid on or purchase a Digital Collectible, You (A) agree to pay the price for such Digital 
        Collectible as set forth in the applicable Service, any charges necessary to the fulfillment of the Digital Collectible, 
        including any required gas fees, and all applicable taxes (which may include, without limitation, sales, use, value-added and 
        other taxes, duties and assessments other than taxes on our net income) in connection with Your purchase (the “Full Purchase Amount”), 
        and (B) authorize Published NFT and/or a third-party platform or system that fulfills payments for Digital Collectibles to charge 
        Your credit card or other payment method specified in Your Payments Account for the Full Purchase Amount.
        <br/><br/>
        iii. You acknowledge that with respect to Digital Collectibles, Published NFT may incorporate functionality into any Digital Collectible 
        or the applicable Service that imposes a royalty on any secondary sales or transfers for value of such Digital Collectibles by a User
        (e.g., to another User who collects Digital Collectibles) based on the value of any such transfer and Published NFT may charge a 
        listing fee for any transactions taking place on or in connection with the Services.
        <br/><br/>
        iv. All fees and charges are payable in accordance with payment terms in effect at the time the fee or the charge becomes payable. 
        Orders and winning bids will not be processed until payment has been received in full, and Published NFT and/or its service provider(s) 
        completes all purchaser identification and registration procedures, including, but not limited to, completing any anti-money laundering 
        and/or anti-terrorism financing checks, that Published NFT may require to its satisfaction, and any holds on Your Payments Account or 
        accounts linked thereto by any payment processor are solely Your responsibility. Your order or winning bid may be suspended or canceled 
        for any reason, including if Your Payments Account is invalid or if in our opinion, You do not satisfy our purchaser identification 
        and registration procedures.
        <br/><br/>
        v. You are responsible for providing Published NFT (or Seller User, as applicable) with a complete and accurate address for Services Wallet 
        or Payments Account, as applicable for receipt of any purchased Digital Collectibles or funds earned, as applicable. You are solely 
        responsible for any errors that may occur in such transfer due to providing us incorrect information about Your Services Wallet, 
        its keys, or other relevant information required to facilitate such transfer.
        <br/><br/>
        vi. We, Published NFT and the Content Providers have no liability to You or to any third party for any claims or damages that may arise as 
        a result of any payments or transactions that You engage in via the Services, or any other payment or transactions that You conduct via 
        the Services. <b>All sales are final. We, Published NFT do not provide returns or refunds for any purchases that You might make on or 
        through the Services — whether for Digital Collectibles (whether sold as individual Digital Collectibles or in packs) or anything else.</b>
        <br/><br/>
        vii. You are solely responsible to pay any and all sales, use, value-added and other taxes, duties, and assessments (except taxes on our 
        net income) now or hereafter claimed or imposed by any governmental authority associated with Your use of the Services (including, 
        without limitation, any taxes that may become payable as the result of Your ownership, transfer, purchase, or sale of any Digital 
        Collectible). Except for income taxes levied on us, You: (i) will be solely responsible for reporting any tax obligations when, 
        if ever, such obligations arise as a result of Your use of the Services or in relation to a Digital Collectible; (ii) will pay or 
        reimburse Published NFT and/or its service provider(s) (as applicable) for all national, federal, state, local or other taxes and 
        assessments of any jurisdiction, including value added taxes and other taxes as may be required, and amounts levied in lieu thereof 
        based on charges set, services performed or payments made hereunder, and any withholding taxes as are now or hereafter may be imposed 
        under the authority of any national, state, local or any other taxing jurisdiction; and (iii) will not be entitled to deduct the 
        amount of any such taxes, duties or assessments from payments (including blockchain gas fees or similar transaction fees) made to 
        us pursuant to these Terms. You agree to indemnify and hold harmless Published NFT and Published NFT for any claims, demands, liens 
        or judgments based on non-payment or underpayment of such taxes, including in our capacity as a withholding agent, or as a collection 
        agent. Upon opening Your Account, You will provide to Published NFT any form or document accurately completed that may be required 
        by law or requested by Published NFT in order to allow Published NFT to make a payment to You without or at a reduced rate of a 
        deduction or withholding for any tax, including without limitation, a duly completed and executed U.S. Internal Revenue Service 
        Form W-9 or an applicable Form W-8 (or any successor thereto). You agree that You will provide an updated form or document referred 
        to in the preceding paragraph promptly upon demand by Published NFT and upon learning that any such form previously provided has 
        become obsolete, incorrect or ineffective.
      </p>

      <h5 className='mt-5'>
        PART III — OWNERSHIP AND CONTENT
      </h5>
      <h5 className='mt-3'>
        1. GENERAL
      </h5>
      <p>
        a. When You purchase a Digital Collectible, You own the NFT underlying the Digital Collectible but You do not own any intellectual property 
        rights in the Content except for any license grants that may be expressly set forth herein or in the Content License. The Services, 
        including their “look and feel” (e.g., text, graphics, images, logos) and proprietary content, information, systems, programs, records, 
        statistics, data, documentation of either an audio or visual nature, and other materials, including the Content, are protected under 
        copyright, trademark and other intellectual property laws. You agree that Published NFT, Published NFT and/or the Content Providers own 
        all right, title and interest in and to the Services and Content (including any and all intellectual property rights therein) and 
        You agree not to take any action(s) inconsistent with such ownership interests. Published NFT, Published NFT and the Content Providers 
        reserve all rights in connection with the Services and its content (including the Content), including, without limitation, the exclusive 
        right to create derivative works.
      </p>

      <h5 className='mt-3'>
        2. COPYRIGHT & TRADEMARKS
      </h5>
      <p>
        a. Unless otherwise indicated, the Services, documents and information, including, but not limited to, text, graphics, icons, logos, 
        buttons and images, on the Services are copyrighted materials of, trademarks of, and owned and controlled by Published NFT, 
        Published NFT or the Content Providers or their respective affiliates or licensors.
      </p>

      <h5 className='mt-3'>
        3. CONTENT LICENSE GRANT
      </h5>
      <p>
        a. A copy of the Content License is located at the end of these Terms. Your license to the Content associated with Your Digital 
        Collectible(s) is solely as set forth in the Content License. You may not use or exploit the Content other than as expressly 
        authorized in the Content License. The terms of the Content License will apply to all subsequent owners of Your Digital Collectible(s), 
        whether such owners acquire the Digital Collectible(s) through the Services or elsewhere. We may also embed a link (or other reference) 
        to the Content License and/or additional license terms or restrictions in the metadata for Your Digital Collectible(s).
      </p>

      <h5 className='mt-3'>
        4. FEEDBACK
      </h5>
      <p>
        a. We welcome feedback, comments and suggestions for improvements to the Services (“Feedback”). You acknowledge and expressly agree that 
        any contribution of Feedback does not and will not give or grant You any right, title or interest in the Services or in any such Feedback. 
        All Feedback becomes the sole and exclusive property of Published NFT, and Published NFT may use and disclose Feedback in any manner and 
        for any purpose whatsoever without further notice or compensation to You and without retention by You of any proprietary or other right 
        or claim. You hereby assign to Published NFT any and all right, title and interest (including, but not limited to, any patent, copyright, 
        trade secret, trademark, show-how, know-how, moral rights and any and all other intellectual property right) that You may have in and to 
        any and all Feedback. Notwithstanding the foregoing, in no event shall the Feedback use or contain any portion of or reference to the 
        Content or Published NFT IP, and in any event, any rights to and use of such Feedback shall be subject to the license grants and 
        conditions expressly set forth in these Terms and the Content License.
      </p>

      <h5 className='mt-5'>
        Part IV — USE OF THE SERVICES
      </h5>
      <h5 className='mt-3'>
        1. USE OF THE SERVICES
      </h5>
      <p>
        a. You are responsible for Your interactions with other Users on the Services. While we reserve the right to monitor interactions between 
        Users, we are not obligated to do so, and neither we or Published NFT can be held liable for Your interactions with other Users, or 
        for any User’s actions or inactions. If You have a dispute with one or more Users, You release Published NFT, Published NFT, the 
        Content Providers, and each of our and their affiliates and subsidiaries, and our and their respective officers, directors, employees 
        and agents) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or 
        in any way connected with such disputes. In entering into this release You expressly waive any protections (whether statutory or otherwise) 
        that would otherwise limit the coverage of this release to include only those claims which You may know or suspect to exist in Your favor 
        at the time of agreeing to this release.
        <br/><br/>
        b. You are responsible for obtaining, maintaining and paying for all hardware and all telecommunications and other services needed to use the Services.
      </p>

      <h5 className='mt-3'>
        2. PROHIBITED ACTIVITIES
      </h5>
      <p>
        a. Illegal Activities. You are responsible for complying with applicable law, including state and federal laws and regulations regarding 
        money laundering, terrorist financing, and money transfer and remittance. You are responsible for understanding and abiding by the laws 
        and regulations of each jurisdiction in which You use the Services. Users of the account shall not engage in any illegal activities 
        according to applicable law or in relation to any other Prohibited Activity listed below.
        <br/><br/>
        b. List of Prohibited Activities. You must not do any of the following in connection with the Services, unless applicable laws or regulations 
        prohibit these restrictions or You have our prior written permission to do so:
        <br/><br/>
        - download, modify, copy, distribute, transmit, display, perform, reproduce, duplicate, publish, license, create derivative works from, 
        or offer for sale any Content or information contained on, or obtained from or through, the Services, except for temporary files that 
        are automatically cached by Your web browser for display purposes, or as otherwise expressly permitted in these Terms;
        <br/>
        - systematically download and store the Content;
        <br/>
        - accept, receive or purchase any Digital Collectible from another User that You have any grounds to believe was not rightfully in 
        possession thereof at the time of such transaction;
        <br/>
        - duplicate, decompile, decipher, reverse engineer, disassemble or decode the Services (including any underlying idea or algorithm), 
        or attempt to do any of the same;
        <br/>
        - use, reproduce or remove any copyright, trademark, service mark, trade name, slogan, logo, image, or other proprietary notation displayed 
        on or through the Services;
        <br/>
        - use cheats, automation software (bots), hacks, modifications (mods) or any other unauthorized third-party software designed to establish 
        Accounts, perform any transaction on the Services (except as expressly provided herein), or modify or make use of the Services in any way;
        <br/>
        - use or exploit the Services for any commercial purpose, including without limitation communicating or facilitating any commercial 
        advertisement or solicitation;
        <br/>
        - access or use the Services in any manner that could disable, overburden, damage, disrupt or impair the Services or interfere with any 
        other party’s access to or use of the Services or use any device, software or routine that causes the same;
        <br/>
        - attempt to gain unauthorized access to, interfere with, damage or disrupt the Services, accounts registered to other users, or the 
        computer systems or networks connected to the Services;
        <br/>
        - harvest or collect information about Users of the Services;
        <br/>
        - circumvent, remove, alter, deactivate, degrade or thwart any technological measure or content protections of the Services;
        <br/>
        - use any robot, spider, crawlers, scraper, or other automatic device, process, software or queries that intercepts, “mines,” scrapes, 
        extracts, or otherwise accesses the Services to monitor, extract, copy or collect information or data from or through the Services, or 
        engage in any manual process to do the same;
        <br/>
        - introduce any viruses, trojan horses, worms, logic bombs or other materials that are malicious or technologically harmful into our systems;
        <br/>
        - use the Services for illegal, harassing, bullying, unethical or disruptive purposes, or otherwise use the Services in a manner that is 
        fraudulent, defamatory, obscene, hateful or otherwise objectionable or tortious;
        <br/>
        - access or use the Services in any way not expressly permitted by these Terms; or
        <br/>
        - use the Service to transact in any of the following:
        <br/>
        - Weapons of any kind, including but not limited to firearms, ammunition, knives, explosives, or related accessories;
        <br/>
        - Controlled substances, including but not limited to narcotics, prescription drugs, steroids, or related paraphernalia or accessories;
        <br/>
        - Prostitution;
        <br/>
        - Gambling activities including but not limited to sports betting, casino games, horse racing, dog racing, lotteries, games of chance, 
        sweepstakes, games of skill that may be classified as gambling (e.g., poker), or other activities that facilitate any of the foregoing;
        <br/>
        - Money-laundering or terrorist financing;
        <br/>
        - Any sort of Ponzi scheme, pyramid scheme, or multi-level marketing program;
        <br/>
        - Goods or services that infringe or violate any copyright, trademark, or proprietary rights under the laws of any jurisdiction;
        <br/>
        - Debt settlement, refinance, or credit repair services;
        <br/>
        - Court-ordered payments, structured settlements, tax payments, or tax settlements;
        <br/>
        - Lottery contracts, layaway systems, or annuities;
        <br/>
        - Counterfeit goods, including but not limited to fake or “novelty” IDs;
        <br/>
        - Wash trading, front-running, insider trading, market manipulation or other forms of market-based fraud or deceit;
        <br/>
        - Purchasing goods of any type from “Tor” hidden service markets or “Darknet” markets, or any other service or website that acts 
        as a marketplace for illegal goods (even though such marketplace might also sell legal goods);
        <br/>
        - Transactions involving sanctioned parties, countries or coins;
        <br/>
        - ATM Operators;
        <br/>
        - Bankruptcy Services;
        <br/>
        - Cyberlocking Services;
        <br/>
        - Precious Metals and Jewelry;
        <br/>
        - Ransomware, Shell Banks/Fis (Financial Institutions);
        <br/>
        - Unlicensed MSB (Money Service Business);
        <br/>
        - Miracle Cures;
        <br/>
        - Hacking Guides; or
        <br/>
        - Psychic Services.
        <br/><br/>
        c. You are utilizing the Services for personal, private use only, and You will not make any information, materials, or services available 
        from the Services available to any person who is not otherwise allowed to access them. This prohibition includes the use of information,
        materials, or services related to or made available in connection with the Services for commercial purposes, as well as the making of 
        such information, materials, or services available to a minor in any way, shape or form.
      </p>

      <h5 className='mt-3'>
        3. DATA AND PRIVACY
      </h5>
      <p>
        All collection, sharing, and use of data regarding access to the Services and usage of the Services is governed by Published NFT’s 
        Privacy Policy and Published NFT’s Privacy Policy.
      </p>

      <h5 className='mt-5'>
        PART V — INDEMNIFICATION, DISCLAIMERS AND LIMITATION OF LIABILITY AND ASSUMPTION OF RISKS
      </h5>
      <h5 className='mt-3'>
        1. INDEMNIFICATION
      </h5>
      <p>
        a. By entering into these Terms and accessing or using the Services, You agree to indemnify and hold harmless Published NFT, 
        Published NFT and the Content Providers, and our and their respective suppliers, distributors, licensors, partners, subcontractors, 
        our and their respective affiliates and related entities and our and their respective directors, officers, employees and agents 
        (in the case of Published NFT, the “Published NFT Entities” and in the case of the Content Providers, the “Content Provider Entities”), 
        from and against any and all claims, losses, damages, liabilities and costs (included but not limited to reasonable attorneys’ fees 
        and court costs) incurred by the Published NFT Entities or Content Provider Entities, arising out of or relating to: (a) Your misuse 
        of the Services, (b) the violation or breach of these Terms by You, © the violation by You of any rights of any third party, including 
        but not limited to infringement by You (or anyone accessing the Services using Your Account) of any intellectual property or other 
        right of any person or entity, (d) Your negligence or willful misconduct, or (e) Your violation of any applicable law or regulation. 
        The foregoing indemnification obligation shall survive termination of these Terms, the Services and Your purchase or sale of any product 
        via the Services. If You are obligated to indemnify any Published NFT Entity or Content Provider Entity hereunder, then You agree that 
        Published NFT (or, at its discretion, the applicable Published NFT Entity) will have the right, in its sole discretion, to control any 
        action or proceeding against Published NFT or any of the Published NFT Entities, and to determine whether Published NFT wishes to settle, 
        and if so, on what terms, and You agree to fully cooperate with Published NFT in the defense or settlement of such claim. You agree 
        that Published NFT (or, at its discretion, the applicable Content Provider Entity) will have the right, in its sole discretion, to control 
        any action or proceeding against Published NFT or any of the Content Provider Entities, and to determine whether Published NFT wishes to 
        settle, and if so, on what terms, and You agree to fully cooperate with Published NFT in the defense or settlement of such claim.
      </p>

      <h5 className='mt-3'>
        2. DISCLAIMERS & LIMITATIONS OF LIABILITY
      </h5>
      <p>
        a. Disclaimers
        <br/><br/>
        i. YOUR ACCESS TO AND USE OF THE SERVICES AND/OR DIGITAL COLLECTIBLES ARE AT YOUR OWN RISK. YOU EXPRESSLY ACKNOWLEDGE, UNDERSTAND AND 
        AGREE THAT TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE Published NFT ENTITIES AND CONTENT PROVIDER ENTITIES DISCLAIM ALL 
        WARRANTIES, CONDITIONS, OR AFFIRMATIONS OF FACT, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, 
        FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. THE Published NFT ENTITIES AND CONTENT PROVIDER ENTITIES DO NOT MAKE ANY WARRANTY 
        OR REPRESENTATION THAT ANY PRODUCT OR SERVICE (INCLUDING THE SERVICES AND THE DIGITAL COLLECTIBLES) WILL MEET YOUR NEEDS OR REQUIREMENTS. 
        YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED TO YOU ON AN ‘AS IS’ AND ‘AS AVAILABLE’ BASIS. THE Published NFT ENTITIES AND 
        CONTENT PROVIDER ENTITIES MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL RESPONSIBILITY FOR: (A) WHETHER THE SERVICES OR DIGITAL 
        COLLECTIBLES WILL BE AVAILABLE ON AN UNINTERRUPTED, SECURE OR ERROR-FREE BASIS, (B) THE ACCURACY, COMPLETENESS, AVAILABILITY, TIMELINESS, 
        SECURITY OR RELIABILITY OF THE SERVICES OR DIGITAL COLLECTIBLES, © ANY HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM THAT 
        RESULTS FROM YOUR ACCESS TO OR USE OF THE SERVICES OR DIGITAL COLLECTIBLES, OR (D) THE OPERATION OR COMPATIBILITY WITH ANY OTHER APPLICATION 
        OR ANY PARTICULAR SYSTEM OR DEVICE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM THE Published NFT ENTITIES OR CONTENT 
        PROVIDER ENTITIES OR THROUGH THE SERVICES, WILL CREATE ANY WARRANTY OR REPRESENTATION NOT EXPRESSLY MADE HEREIN.
        <br/><br/>
        ii. THE Published NFT ENTITIES AND CONTENT PROVIDER ENTITIES TAKE NO RESPONSIBILITY AND ASSUME NO LIABILITY FOR ANY CONTENT, 
        INFORMATION OR OTHER MATERIALS THAT YOU, ANOTHER USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR 
        THROUGH OUR SERVICES.
        <br/><br/>
        iii. YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT, INFORMATION OR OTHER MATERIALS THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, 
        OR OTHERWISE INAPPROPRIATE, NONE OF WHICH THE Published NFT ENTITIES NOR THE CONTENT PROVIDER ENTITIES WILL BE RESPONSIBLE FOR.
        <br/><br/>
        b. LIMITATIONS OF LIABILITY. TO THE EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT WILL THE Published NFT ENTITIES OR THE CONTENT 
        PROVIDER ENTITIES BE LIABLE TO YOU OR TO ANY THIRD PARTY (A) FOR ANY INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, 
        WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO, LOSS OF REVENUE OR PROFITS, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, 
        LOSS OF USE, LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER DAMAGES OR LOSSES ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE 
        THE SERVICES OR ANY DIGITAL COLLECTIBLES, HOWEVER CAUSED, WHETHER UNDER THESE TERMS OR OTHERWISE ARISING IN ANY WAY IN CONNECTION WITH 
        THE SERVICES, THE DIGITAL COLLECTIBLES, OR THESE TERMS, WHETHER FORESEEABLE OR NOT AND WHETHER AN Published NFT ENTITY OR CONTENT PROVIDER 
        ENTITY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR NOT, OR (B) FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM 
        OR ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE DELIVERY, USE OR PERFORMANCE OF THE SERVICES OR DIGITAL COLLECTIBLES. 
        THE Published NFT ENTITIES’ AND THE CONTENT PROVIDER ENTITIES’ TOTAL LIABILITY TO YOU FOR ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED 
        THE LESSER OF THE AMOUNT OF ONE HUNDRED DOLLARS ($100.00), OR THE AMOUNT YOU PAID THE Published NFT ENTITIES, IF ANY, IN THE PAST SIX (6) 
        MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS 
        ESSENTIAL PURPOSE. Because certain jurisdictions (such as the state of New Jersey) to which these Terms may be subject do not allow the 
        exclusion or limitation of liability for consequential or incidental damages, the above exclusion or limitation may be held to be 
        inapplicable to You in those jurisdictions.
      </p>

      <h5 className='mt-3'>
        3. ASSUMPTION OF RISKS
      </h5>
      <p>
        a. You acknowledge and agree that there are risks associated with purchasing and holding NFTs and using blockchain technology. 
        These include, but are not limited to, risk of losing access to NFT due to loss of private key(s), custodial error or purchaser error, 
        risk of mining or blockchain attacks, risk of hacking and security weaknesses, risk of unfavorable regulatory intervention in one or 
        more jurisdictions, risks related to uncertainties in token taxation, risk of personal information disclosure, risk of uninsured losses, 
        unanticipated risks, and volatility risks.
        <br/><br/>
        b. The prices of collectible blockchain assets are extremely volatile and subjective and collectible blockchain assets have no inherent or 
        intrinsic value. Each Digital Collectible has no inherent or intrinsic value. You represent, warrant, and covenant that (i) You are 
        collecting, trading or purchasing Digital Collectibles for purposes of acquiring digital collectibles for Your personal use and enjoyment, 
        and not for any investment, commercial or speculative purposes, and any economic benefit that may be derived from appreciation in the 
        value of the Digital Collectible is incidental to obtaining it for its collectible purpose; (ii) You will not use Digital Collectibles 
        as a substitute for currency or medium of exchange, resale or redistribution and that You are not acquiring any equity or other ownership 
        or revenue sharing interest in Published NFT, Published NFT, or any Content Providers or our or their affiliates as a result of Your 
        acquisition of Digital Collectibles; and (iii) You will not portray the Digital Collectibles as an opportunity to gain an economic benefit 
        or profit, or as an investment or equity interest.
        <br/><br/>
        c. Neither Published NFT nor Published NFT nor the Content Providers represent, guarantee, or warrant the accuracy or fairness of the price 
        of any Digital Collectible sold or offered for sale on or off of the Services. You agree and acknowledge that neither Published NFT nor 
        Published NFT nor the Content Providers are fiduciaries and neither Published NFT nor Published NFT nor the Content Providers owe any 
        duties to any User of the Services or any holder of Digital Collectibles, including the duty to ensure fair pricing of Digital Collectibles.
        <br/><br/>
        d. Published NFT will use commercially reasonable efforts to deploy secure and functional smart contracts underlying Digital Collectibles 
        minted directly by Published NFT. However, Published NFT and Published NFT will not be liable or responsible to You for any failure in 
        the intended function of such smart contracts, or any bugs, viruses, exploits, logic gaps, or malicious code which may be incorporated 
        into any such smart contracts, or which could be used to commit fraud or otherwise cause harm. You acknowledge that You have obtained 
        sufficient information to make an informed decision to purchase a Digital Collectible, including carefully reviewing the code of the 
        smart contract and the NFT and fully understand and accept the functions of the same.
        <br/><br/>
        e. You acknowledge and agree that if, we believe in our sole discretion, or have reasonable grounds to believe, that under any prior 
        transaction, a Digital Collectible was stolen, obtained by fraudulent means, with stolen funds or credit card information, or 
        otherwise in violation of these Terms, all Users hereby grant us, Published NFT, the Content Providers and our service providers a 
        right to cancel any fraudulent transaction or transfer all such Digital Collectibles in question from the Services Wallet or other 
        wallet, to the User who was previously in rightful possession thereof, even if the User who loses possession of the Digital Collectible 
        was a good faith holder of the Digital Collectible.
        <br/><br/>
        f. The regulatory regime governing blockchain technologies, cryptocurrencies, tokens, and other crypto-based items is uncertain, and 
        new regulations or policies may materially adversely affect the development of certain of our Services, and therefore the potential 
        utility or value of Your Digital Collectibles. Upgrades to any blockchain network or hard forks in such networks, or a change in how 
        transactions are confirmed on such blockchain networks may have unintended, adverse effects on all blockchains, including any that 
        are related to Your Digital Collectibles.
        <br/><br/>
        g. Any purchase or sale You make, accept or facilitate outside of the Services of any Digital Collectible will be entirely at 
        Your risk. We, Published NFTdo not control or endorse purchases or sales of Digital Collectibles outside of the applicable Services 
        on which such Digital Collectibles were initially offered (including other platforms which we may offer as part of the Services). 
        We and Published NFT expressly deny and disclaim any liability to You and deny any obligation to indemnify You or hold You harmless 
        for any losses You may incur by transacting, or facilitating transactions, in Digital Collectibles outside of the Services on which 
        the Digital Collectible was initially offered.
      </p>

      <h5 className='mt-5'>
        PART VI — DISPUTE RESOLUTION BY BINDING ARBITRATION AND CLASS ACTION WAIVER
      </h5>
      <p>
        1. Informal Resolution. Each party agrees that before it seeks arbitration or any other form of legal relief it shall provide written 
        notice to the other of the specific issues in dispute (the “Dispute”). Within thirty days after such notice is received, knowledgeable 
        executives of the party or the individuals themselves shall hold at least one meeting (in person or by video- or tele-conference) for 
        the purpose of attempting in good faith to resolve the Dispute. The parties agree to maintain the confidential nature of all disputes 
        and disagreements between them, including, but not limited to, informal negotiations, mediation or arbitration, except as may be 
        necessary to prepare for or conduct these dispute resolution procedures or unless otherwise required by law or judicial decision. 
        All references to Dispute(s) and the applicability of all of the other provisions in this Part VI of these Terms shall refer to Disputes 
        or other claims, actions and proceedings between You and Published NFT or between You and Published NFT, but not to Disputes or other 
        claims, actions or proceedings between Published NFT and Published NFT.
        <br/><br/>
        2. Binding Arbitration.
        <br/><br/>
        a. Where informal resolution fails, You agree that any remaining Dispute relating in any way to Your use of Published NFT’s or 
        Published NFT’s services and/or products, including the Services and Digital Collectibles, shall be submitted to confidential 
        binding arbitration. If there is a dispute about whether this arbitration provision can be enforced or applies to the Dispute, 
        You and Published NFT and Published NFT agree that the arbitrator will decide that issue. However, any claim that all or part of 
        the Class Action Waiver is unenforceable, unconscionable, void or voidable may be determined only by a court of competent jurisdiction 
        and not by an arbitrator.
        <br/><br/>
        b. Arbitration under these Terms is under the Federal Arbitration Act which governs the interpretation and enforcement of this provision. 
        The arbitration will be administered by the JAMS in accordance with its Comprehensive Arbitration Rules and Procedures and the JAMS Consumer 
        Minimum Standards (together, the “Rules”) then in effect (those rules are deemed to be incorporated by reference to this section, and 
        as of the date of these Terms). Arbitration shall be conducted by one (1) arbitrator as selected pursuant to the Rules; the arbitrator’s 
        award shall be final and binding and may be entered as a judgment in any court of competent jurisdiction. You have a right to have the 
        arbitration conducted via telephone, or as an in-person hearing in Your hometown area (if You live in the United States) or another 
        location that is reasonably convenient to You.
        <br/><br/>
        c. Payment for any and all reasonable JAMS filing, administrative and arbitrator fees will be in accordance with the Rules. If the 
        value of Your claim does not exceed $10,000, Published NFT or Published NFT, as applicable, will pay for the reasonable filing, 
        administrative and arbitrator fees associated with the arbitration, unless the arbitrator finds that either the substance of 
        Your claim or the relief sought was frivolous or brought for an improper purpose, except that if You have initiated the arbitration 
        claim, You will still be required to pay the lesser of $250 or the maximum amount permitted under the JAMS Rules for arbitration 
        claims initiated by You. You are still responsible for all additional costs that You incur in the arbitration, including without 
        limitation, fees for attorneys or expert witnesses.
        <br/><br/>
        <b>
        3. Opt-Out. You have the right to opt out of binding arbitration and the waiver of class provisions set forth in these Terms within 
        thirty (30) days registering to use the Services or agreeing to these Terms (or, if this Part VI is amended hereafter, within thirty 
        days of such amendment being effective) by notifying us of Your decision to opt-out with a communication labeled “Arbitration Opt-Out” 
        to support@publishednft.io or by mail at 1379 West Ridge rd, Rochester, NY 14615, otherwise You shall be bound to arbitrate disputes 
        in accordance with these Terms. In order to be effective, the opt-out notice must include Your full name and Your mailing address. 
        If You opt-out of these arbitration provisions, Published NFT and Published NFT also will not be bound by them. If You have decided 
        to opt-out of Binding Arbitration, all Disputes shall be heard in a court of competent jurisdiction.
        </b>
        <br/><br/>
        4. Exceptions to Arbitration. Notwithstanding anything in these Terms to the contrary, You may instead assert Your Dispute in “small claims” 
        court, but only if Your Dispute qualifies, Your Dispute remains only in court, and Your Dispute remains on an individual and non-representative 
        and non-class basis. Further, You, and Published NFT and Published NFT, as applicable, will have the right to bring an action in a court of 
        proper jurisdiction for: (i) any Dispute related to actual or threatened infringement, misappropriation or violation of a party’s copyrights, 
        trademarks, trade secrets, patents, or other intellectual property rights; or (ii) injunctive or other equitable or conservatory relief. 
        Such claims shall be brought before a judicial proceeding in a court of competent jurisdiction.
        <br/><br/>
        <b>
        5. Waiver of Class Actions and Class Arbitrations.
        <br/><br/>
        a. YOU AND Published NFT UNDERSTAND AND AGREE THAT YOU, AND Published NFT AND Published NFT, AS APPLICABLE, MAY EACH BRING CLAIMS AGAINST 
        THE OTHER PARTY ONLY IN AN INDIVIDUAL CAPACITY AND NOT ON A CLASS, COLLECTIVE ACTION, OR REPRESENTATIVE BASIS, INCLUDING, WITHOUT LIMITATION, 
        FEDERAL OR STATE CLASS ACTIONS, OR CLASS ARBITRATIONS. YOU UNDERSTAND AND AGREE THAT YOU, AND Published NFT AND Published NFT, AS APPLICABLE, 
        EACH ARE WAIVING THE RIGHT TO PURSUE OR HAVE A DISPUTE RESOLVED AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE OR 
        REPRESENTATIVE PROCEEDING. ACCORDINGLY, UNDER THE ARBITRATION PROCEDURES OUTLINED IN THIS SECTION, AN ARBITRATOR SHALL NOT COMBINE OR 
        CONSOLIDATE MORE THAN ONE PARTY’S CLAIMS WITHOUT THE WRITTEN CONSENT OF ALL AFFECTED PARTIES TO AN ARBITRATION PROCEEDING. WITHOUT 
        LIMITING THE GENERALITY OF THE FOREGOING, YOU AND Published NFT AGREE THAT NO DISPUTE SHALL PROCEED BY WAY OF CLASS ARBITRATION WITHOUT 
        THE WRITTEN CONSENT OF ALL AFFECTED PARTIES.
        <br/><br/>
        NOTWITHSTANDING THE FOREGOING, THIS SUBSECTION SHALL NOT APPLY TO REPRESENTATIVE PRIVATE ATTORNEYS GENERAL ACT CLAIMS BROUGHT AGAINST 
        Published NFT OR Published NFT, WHICH ARE ADDRESSED SEPARATELY.
        </b>
        <br/><br/>
        6. PAGA Claims. Notwithstanding any other provision of these Terms or the Arbitration provisions, to the fullest extent permitted by law: 
        (1) You, and Published NFT and Published NFT, as applicable, agree not to bring a representative action on behalf of others under the 
        Private Attorneys General Act of 2004 (“PAGA”), California Labor Code § 2698 et seq., or any subsequent law, in any court or in arbitration, 
        and (2) for any claim brought on a private attorney general basis, including under the California PAGA, both You, and Published NFT and 
        Published NFT, as applicable, agree that any such dispute shall be resolved in arbitration on an individual basis only (i.e., to resolve 
        whether You have personally been aggrieved or subject to any violations of law), and that such an action may not be used to resolve the 
        claims or rights of other individuals in a single or collective proceeding (i.e., to resolve whether other individuals have been aggrieved 
        or subject to any violations of law) (collectively, “representative PAGA Waiver”). Notwithstanding any other provision of these Terms, 
        the arbitration section or the associated rules, disputes regarding the scope, applicability, enforceability, revocability or validity 
        of this representative PAGA Waiver may be resolved only by a civil court of competent jurisdiction and not by an arbitrator. If any 
        provision of this representative PAGA Waiver is found to be unenforceable or unlawful for any reason: (i) the unenforceable provision 
        shall be severed from these Terms; (ii) severance of the unenforceable provision shall have no impact whatsoever on any arbitration 
        provisions or the requirement that any remaining claims be arbitrated on an individual basis pursuant to the arbitration provisions; 
        and (iii) any such representative PAGA or other representative private attorneys general act claims must be litigated in a civil court 
        of competent jurisdiction and not in arbitration. To the extent that there are any Disputes to be litigated in a civil court of competent 
        jurisdiction because a civil court of competent jurisdiction determines that the representative PAGA Waiver is unenforceable with respect 
        to those Disputes, the parties agree that litigation of those Disputes shall be stayed pending the outcome of any individual Disputes in 
        arbitration.
        <br/><br/>
        7. In the event of a legal dispute between Users over which User has the right to be in possession of a Digital Collectible, we reserve 
        the right to block any additional transfers of such Digital Collectible pending written instructions of all parties to the dispute or 
        the order of a court of competent jurisdiction.
      </p>

      <h5 className='mt-5'>
        PART VII — MISCELLANEOUS
      </h5>
      <p>
        1. Force Majeure. Published NFT and Published NFT shall not be liable to You, nor be deemed to have defaulted under or breached these Terms, 
        for any delay in or impairment or failure of performance or in providing the Services or Digital Collectibles, resulting in whole or in 
        part from externally caused interference, damage or disruption to network components, transmission or pipeline facilities (which includes 
        network, electrical or other common systems); irreparable component, transmission or pipeline failure (the failure or interruption of 
        pipeline facilities); acts of God; severe weather conditions; governmental decrees or controls; strikes, labor disputes, acts of war or 
        civil unrest; acts of third parties, inability to obtain permits and licenses, or supplies or any other circumstances or causes beyond 
        the reasonable commercial control of Published NFT or Published NFT.
        <br/><br/>
        2. Complete Agreement. These Terms and any related information that are incorporated by reference collectively constitute the entire agreement 
        between You, and Published NFT and Published NFT, as applicable, regarding the subject matter hereof, subject to applicable law.
        <br/><br/>
        3. Severability. In the event any one or more of the provisions of these Terms are for any reason to be invalid or unenforceable, 
        the remaining provisions of such Terms shall be unimpaired, and the invalid or unenforceable provision shall be replaced by a provision 
        which being valid and enforceable, comes closest to the intention of the parties underlying the invalid or unenforceable provision(s).
        <br/><br/>
        4. Governing Law and Forum. These Terms are governed by the laws of the State of New York, without giving effect to its conflict of law 
        principles, and the proper venue for any disputes arising out of or relating to any of the same will be the arbitration venue set forth 
        in Part VI, or if arbitration does not apply, the state or federal courts sitting in the State of California. You waive any and all 
        objections to such jurisdiction and venue and waive personal service of process and agree that any summons and complaint commencing an 
        action in any such court shall be properly served and shall confer personal jurisdiction if served by registered or certified mail. 
        Any action or suit brought with respect to the Terms shall be tried by a court and not by a jury. YOU WAIVE ANY RIGHT TO A TRIAL BY JURY 
        IN ANY SUCH ACTION OR SUIT. You and Published NFT and Published NFT agree that the United Nations Convention on Contracts for the 
        International Sale of Goods will not apply to the interpretation or construction of these Terms.
        <br/><br/>
        5. International Use. The Services are controlled or operated (or both) from the United States, and these Terms are not intended to 
        subject Published NFT, Published NFT, or any Content Providers to any non-U.S. jurisdiction or law. The Services and Digital Collectibles 
        may not be appropriate or available for use in some non-U.S. jurisdictions. Any use of the Services or Digital Collectibles is at Your 
        own risk, and You must comply with all applicable laws, rules and regulations in doing so. We may limit the availability of the Services 
        or Digital Collectibles at any time, in whole or in part, to any person, geographic area or jurisdiction that we choose.
        <br/><br/>
        6. Injunctive Relief. You agree that a breach of these Terms will cause irreparable injury to Published NFT and Published NFT for which 
        monetary damages would not be an adequate remedy and Published NFT and Published NFT shall be entitled to equitable relief in addition 
        to any remedies it may have hereunder or at law without a bond, other security or proof of damages.
        <br/><br/>
        7. Theft and Fraudulent Activity. You acknowledge that we and Published NFT reserve the right to report unusual or suspicious activity 
        to the proper authorities. You agree that neither we nor Published NFT will be held responsible or liable to You or any other person for 
        such actions except as required by law. We and Published NFT may report any activities that we or Published NFT reasonably believe 
        constitute fraud or theft to the appropriate law enforcement authorities and may prosecute such activities to the full extent of the law. 
        To the extent permitted by law, we will retain the proceeds resulting from such fraudulent activity or theft and use those funds to pay 
        for damages and losses resulting from such fraudulent activity or theft.
        <br/><br/>
        8. Assignment. These Terms and the licenses granted hereunder may be assigned by Published NFT or Published NFT but may not be assigned by 
        You without the prior express written consent of Published NFT and Published NFT.
        <br/><br/>
        9. No Waiver. No waiver by either You or Published NFT or Published NFT, as applicable of any breach or default hereunder shall be deemed 
        to be a waiver of any preceding or subsequent breach or default.
        <br/><br/>
        10. Promotions. From time to time, we or Published NFT may offer You the chance to participate in contests, giveaways, surveys, 
        or similar promotions (collectively, “Promotions”) in connection with Your use of the Services. The Promotions may be governed 
        by rules that are separate from these Terms. If the rules for a Promotion conflict with these Terms, the Promotion rules will govern.
        <br/><br/>
        11. SMS Messaging. Certain portions of the Services may allow us to contact You via text messages. You agree that we or Published NFT 
        may contact You via text messages at any of the phone numbers provided by You or on Your behalf in connection with Your use of the Services, 
        including for Account verification purposes.
        <br/><br/>
        12. Termination of License and Your Account. If You breach any of the provisions of these Terms, all licenses granted by Published NFT 
        and/or Published NFT will terminate automatically. Additionally, we reserve the right, at our sole discretion, to remove, suspend, revoke, 
        terminate or close Your Account (including disabling, invalidating or otherwise rendering inoperable Your Services Wallet or Payments Account) 
        and/or the Services (or any part of the foregoing), with or without notice, for whatever reason and without explanation. If we delete 
        Your Account for any suspected breach of these Terms by You, You are prohibited from re-registering for the Services under a different name. 
        In the event that credit card, debit card, or other payment methods are returned from Your bank unpaid, we reserve the right, at our 
        sole discretion, to remove these pre-deposited funds from Your Account or Your Payments Account, and we further reserve the right, at our 
        sole discretion, to remove any purchased Digital Collectibles from Your Account.
        <br/><br/>
        13. Survival. All sections which by their nature should survive the termination of these Terms shall continue in full force and effect 
        subsequent to and notwithstanding any termination of these Terms by Published NFT, Published NFT or You. Termination will not limit any 
        of Published NFT’s or Published NFT’s other rights or remedies at law or in equity.
        <br/><br/>
        14. California Residents. If You are a California resident, in accordance with Cal. Civ. Code § 1789.3, You may report complaints to the 
        Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in 
        writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952–5210. 
        <br/><br/>
        15. You acknowledge that the releases contained in these Terms shall apply to unknown and unanticipated claims encompassed within the scope 
        of the releases, as well as those known and anticipated. In connection therewith, if applicable, You expressly acknowledge that You have 
        read and understood the following language from Section 1542 of the California Civil Code:
        <br/><br/>
        <b>
        16. A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her 
        favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement 
        with the debtor or released party.
        </b>
        <br/><br/>
        17. and voluntarily waive and relinquish any and all rights or benefits under Section 1542 of the California Civil Code.
        <br/><br/>
        18. New Jersey Consumers. If You are a consumer residing in New Jersey, the following provisions of these Terms do not apply to You 
        (and do not limit any rights that You may have) to the extent that they are unenforceable under New Jersey law: (a) the disclaimer of 
        liability for any indirect, incidental, consequential, special, exemplary or punitive damages of any kind (for example, to the extent 
        unenforceable under the New Jersey Punitive Damages Act, New Jersey Products Liability Act, New Jersey Uniform Commercial Code and 
        New Jersey Consumer Fraud Act); (b) the limitations of liability for lost profits or loss or misuse of any data (for example, to the 
        extent unenforceable under the New Jersey Identity Theft Protection Act and New Jersey Consumer Fraud Act); © application of the 
        limitations of liability to the recovery of damages that arise under contract and tort, including negligence, strict liability or 
        any other theory (for example, to the extent such damages are recoverable by a consumer under New Jersey law, including the 
        New Jersey Products Liability Act); (iv) the requirement that You indemnify the Published NFT Entities and the Content Provider 
        Entities (for example, to the extent the scope of such indemnity is prohibited under New Jersey law); and (v) the California 
        governing law provision (for example, to the extent that Your rights as a consumer residing in New Jersey are required to be 
        governed by New Jersey law).
        <br/><br/>
        19. Export Laws. You agree that You will not export or re-export, directly or indirectly, the Services, the Digital Collectibles 
        and/or other information or materials provided by Published NFT or Published NFT hereunder, to any country for which the United States 
        or any other relevant jurisdiction requires any export license or other governmental approval at the time of export without first 
        obtaining such license or approval. In particular, but without limitation, the Services and Digital Collectibles may not be exported 
        or re-exported (a) into any U.S. embargoed countries or any country that has been designated by the U.S. Government as a 
        “terrorist supporting” country, or (b) to anyone listed on, or to any entity fifty percent (50%) owned or controlled, directly or 
        indirectly, by any person or entity identified on, any U.S. Government list of prohibited or restricted parties, including the Office 
        of Foreign Assets Control of the U.S. Treasury Department’s list of Specially Designated Nationals and/or Blocked Person, the Bureau 
        of Industry and Security of the U.S. Department of Commerce Denied Person’s List or Entity List, or any similar list promulgated by 
        an official agency or department of the U.S. government. By using the Services or the Digital Collectibles, You represent and warrant 
        that You are not located in any such country or on any such list, nor are You fifty percent (50%) owned or controlled, directly or 
        indirectly, by any person or entity identified on such list. You are responsible for and hereby agree to comply at Your sole expense 
        with all applicable United States export laws and regulations.
        <br/><br/>
        20. Contact Us
        <br/><br/>
        21. General and specific inquiries regarding the Services or these Terms should be directed to:
        <br/><br/>
        22. Published NFT, LLC. (d/b/a Published NFT)<br/>
            1379 West Ridge rd <br/>
            Rochester, NY 14615.<br/>
            support@publishednft.io<br/>
      </p>

      <h5 className='mt-5'>
        End User Content License
      </h5>
      <p>
        Last Updated: May 13, 2022
        <br/><br/>
        This End Content License (“Content License”) is among you and The Non-Fungible Token Company, Inc. (d/b/a Published NFT) (“Published NFT”) 
        and Published NFT Media, LLC (“Published NFT”) (collectively, Published NFT and Published NFT are referred to herein as the “Providers” 
        or “we” or “our”). This Content License sets out the terms and conditions governing your right to access, display and/or perform 
        (as applicable) certain Creative Works (as defined below) made available by the Providers and their licensors as part of your ownership 
        of a unique, non-fungible token (“NFT”) associated with such Media. Each such NFT, as associated with the applicable Media, is referred 
        to in this Media License as a Digital Collectible By purchasing or otherwise obtaining a Digital Collectible from Providers, either 
        through an initial transfer from Providers or a subsequent transfer from the prior owner of the Digital Collectible, you agree to these 
        Content License terms and you affirm that you are of the legal age of majority in your jurisdiction to enter into this Content License 
        (or, if you are not, that you have obtained parental or guardian consent to enter into this Content License).
        <br/><br/>
        THIS CONTENT LICENSE CONTAINS IMPORTANT PROVISIONS INCLUDING AN ARBITRATION PROVISION THAT REQUIRES YOU AND PROVIDERS TO RESOLVE 
        ALL DISPUTES BY BINDING ARBITRATION INSTEAD OF IN COURT. PLEASE SEE SECTION 12 TITLED “DISPUTE RESOLUTION” BELOW. YOU WAIVE 
        YOUR RIGHT TO TRIAL BY JURY, AND EXCEPT AS PERMITTED BY LAW, YOU WILL NOT BE ABLE TO BRING ANY CLAIMS IN STATE OR FEDERAL COURT. 
        YOU HAVE THE RIGHT TO OPT-OUT OF ARBITRATION AS EXPLAINED IN SECTION 12 BELOW.
        <br/><br/>
        We may change this Content License from time to time by notifying you of such changes by any reasonable means, including by posting 
        the revised Content License. Any such changes will not apply to any dispute between you and us (or any one of us) arising prior to 
        the date on which we posted the revised Content License incorporating such changes, or otherwise notified you of such changes. 
        Purchasing or otherwise obtaining a Digital Collectible following any changes to this Content License will constitute your acceptance 
        of such changes. The “Last Updated” legend above indicates when this Content License was last changed.
        <br/><br/>
        1. The Digital Collectible is created by or on behalf of Providers and is specifically associated with one or more works of authorship 
        created by Providers (or any one of them) or their licensors or their affiliates (each Provider, its licensors and their affiliates, 
        collectively, the “Content Providers”) (such work, the “Creative Work”). It is important to understand, and you hereby acknowledge 
        and agree, that the Digital Collectible is separate from the Creative Work. Although the Digital Collectible itself is sold or 
        otherwise transferred to you, the Creative Work associated with the Digital Collectible is licensed to you, and not sold or otherwise 
        transferred to you. The Creative Work is neither stored nor embedded in the Digital Collectible but is licensed to you, subject to 
        compliance with this Content License.
        <br/>
        2. Subject to your compliance with this Content License, each Content Provider hereby grants to you, to the extent of its respective 
        rights in the Creative Work, and solely for so long as you are the legal owner of the Digital Collectible, a non-exclusive, non-transferable, 
        non-sublicensable license to access, view and/or display the Creative Work using the Digital Collectible, solely for your personal, 
        non-commercial purposes. Except for the license granted above, the Content Providers retain all right, title and interest in and to the 
        Creative Work. Further, notwithstanding anything to the contrary in this Content License, each Content Provider is not granting, and 
        does not grant, any rights with respect to any sound recordings or musical compositions, including, without limitation, public performance 
        rights, mechanical rights, other rights of reproduction or any other publishing rights, under this Content License, including with respect 
        to Creative Works (including, for clarity, any underlying musical compositions in Creative Works), and such rights are excluded from the 
        foregoing license.
        <br/>
        3. You will not: (a) separate, unlink or decouple the Creative Work from the Digital Collectible; (b) modify the Creative Work in 
        any way, including, without limitation, the sounds, shapes, designs, drawings, attributes or color schemes; © use the Creative Work, 
        including the name, likeness, image or persona of any artist associated with such Creative Work, or any instrument or other object 
        associated with such artist (“Persona”), to advertise, market, promote, or sell any third party product or service or otherwise 
        use the Creative Work or any Persona associated with the Creative Work for your or any third party’s commercial benefit; (d) 
        trademark, copyright or otherwise acquire additional intellectual property rights in or to the Creative Work or any Persona 
        associated with the Creative Work; (e) use the Creative Work or any Persona associated with the Creative Work in connection with 
        images, videos or other content, including content that is hateful, intolerant, violent, cruel or that could reasonably be found 
        to constitute hate speech or infringe upon the rights of others; or (f) create or sell fractionalized interests in the Digital 
        Collectible or the Creative Work. Additionally, you will neither attempt nor assist others to do any of the foregoing.
        <br/>
        4. The Creative Work may incorporate intellectual property, including copyrighted materials, trade names, trademarks or service marks, 
        and the names, likenesses, images or Personas of Content Providers, or their respective artists or individuals or entities associated 
        with Content Providers (the “IP”). Such IP is owned by the Content Providers. Your use of such IP is subject to the license granted 
        above, and you may not use any such IP in connection with any business, product or service, or in any manner that may imply endorsement 
        of any business, message, product or service, or that is likely to cause confusion or dilute or tarnish such IP. All use of such IP, 
        including any goodwill generated by such use, shall inure to the benefit of the Content Providers.
        <br/>
        5. We may at any time, if we believe such action must be taken to avoid legal liability or we are obligated to do so, remove or modify 
        the Creative Work (or otherwise blur, mute or obscure the Creative Work so that such Creative Work is unviewable or inaudible); 
        in which case we will use good faith efforts to provide you with replacement creative works to be associated with your Digital 
        Collectible subject to your continued compliance with this Content License and the Published NFT ChartStars Terms and Conditions 
        (the “Terms”) (and in such event, such replacement creative work will then be deemed the “Creative Work” associated with your 
        Digital Collectible).
        <br/>
        6. You must not remove, alter or obscure any copyright, trademark or other proprietary rights notices included as part of the 
        Creative Work. This includes, but is not limited to, (a) identification of Published NFT or Published NFT or any others designated 
        to receive attribution and (b) a copyright, trademark or other intellectual property notice. Other than your compliance with the 
        foregoing obligation, you have no right to use the name, trademarks, logos, or other IP rights of Content Providers, or their 
        respective artists or individuals or entities associated with Content Providers.
        <br/>
        7. THE DIGITAL COLLECTIBLE IS INTENDED FOR CONSUMER ENJOYMENT, USE AND CONSUMPTION ONLY. IT IS NOT A “SECURITY,” AS DEFINED UNDER THE 
        SECURITIES ACT OF 1933, AS AMENDED, THE SECURITIES EXCHANGE ACT OF 1934, AS AMENDED, THE INVESTMENT COMPANY ACT OF 1940, AS AMENDED, 
        OR THE SECURITIES LAWS OF ANY U.S. STATE.
        <br/>
        8. You may sell or otherwise transfer the Digital Collectible to a third party, provided that the following conditions are met: 
        <br/>
        9. a. such sale or other transfer must be cryptographically verified (i.e., proof of such sale or other transfer must be recorded on the 
        relevant blockchain); 
        <br/>
        b. such sale or other transfer must comply with (i) any applicable terms of the marketplace or other platform on which such sale or other 
        transfer takes place and (ii) any applicable laws, regulations and rules; and
        <br/>
        c. prior to such sale or other transfer, you must (i) provide written notice to the would-be transferee that such transferee’s use of 
        (including any access to) the Creative Work will be conditional upon such transferee entering into the then-current version of the 
        Content License, and (ii) ensure that such transferee is provided with an opportunity to review the Content License.
        <br/>
        10. Upon any sale or other transfer of the Digital Collectible, your license to the Creative Work will immediately terminate 
        (without the requirement of notice). Your license to the Creative Work will also immediately terminate (without the requirement of notice) 
        if you breach this Content License (or the Terms).
        <br/>
        11. You acknowledge and agree that Content Providers or their designee(s) may receive royalties from your sale of, and any other future 
        sale of, the Digital Collectible via a smart contract embedded in the Digital Collectible.
        <br/>
        12. EACH PROVIDER PROVIDES THE DIGITAL COLLECTIBLE, AND LICENSES THE CREATIVE WORK, TO YOU ON AN “AS IS” BASIS, AND EXPRESSLY DISCLAIMS 
        ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OR CONDITIONS OF TITLE,
        NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. IN NO EVENT WILL ANY CONTENT PROVIDER BE LIABLE FOR ANY INDIRECT, 
        INCIDENTAL, CONSEQUENTIAL OR OTHER NON-DIRECT DAMAGES OF ANY KIND, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE MAXIMUM AGGREGATE 
        LIABILITY OF ANY CONTENT PROVIDER FOR ALL DAMAGES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, 
        SHALL BE ONE HUNDRED U.S. DOLLARS ($100).
        <br/>
        13. You shall defend, indemnify and hold harmless all Content Providers and their respective artists, directors, officers, employees, 
        licensors, content providers, agents and representatives, from any third-party claim or action arising from your breach or alleged 
        breach of this Content License or any unauthorized use of the Creative Work.
        <br/>
        14. This Content License is governed by the laws of the State of New York, without giving effect to its conflict of law principles, 
        and the proper venue for any disputes arising out of or relating to any of the same will be the arbitration venue set forth in Section 12 
        below, or if arbitration does not apply, the state or federal courts sitting in the State of California. You waive any and all objections 
        to such jurisdiction and venue and waive personal service of process and agree that any summons and complaint commencing an action in 
        any such court shall be properly served and shall confer personal jurisdiction if served by registered or certified mail. Any action or 
        suit brought with respect to this Content License shall be tried by a court and not by a jury. YOU WAIVE ANY RIGHT TO A TRIAL BY JURY 
        IN ANY SUCH ACTION OR SUIT. You and Published NFT agree that the United Nations Convention on Contracts for the International Sale of 
        Goods will not apply to the interpretation or construction of this Content License.
        <br/>
        15. Dispute Resolution
        <br/>
        a. Informal Resolution. Each party agrees that before it seeks arbitration or any other form of legal relief related to this Content License
        or the Creative Works it shall provide written notice to the other of the specific issues in dispute (the “Dispute”). Within thirty days 
        after such notice is received, knowledgeable executives of the party or the individuals themselves shall hold at least one meeting 
        (in person or by video- or tele-conference) for the purpose of attempting in good faith to resolve the Dispute. The parties agree to 
        maintain the confidential nature of all disputes and disagreements between them, including, but not limited to, informal negotiations, 
        mediation or arbitration, except as may be necessary to prepare for or conduct these dispute resolution procedures or unless otherwise 
        required by law or judicial decision.
        <br/>
        b. Binding Arbitration.
        <br/>
        i. Where informal resolution fails, you agree that any remaining Dispute relating in any way to this Content License or your use of the 
        Creative Works, shall be submitted to confidential binding arbitration. If there is a dispute about whether this arbitration provision 
        can be enforced or applies to the Dispute, you and Published NFT agree that the arbitrator will decide that issue. However, any claim 
        that all or part of the Class Action Waiver is unenforceable, unconscionable, void or voidable may be determined only by a court of 
        competent jurisdiction and not by an arbitrator.
        <br/>
        ii. Arbitration under this Content License is under the Federal Arbitration Act which governs the interpretation and enforcement of 
        this provision. The arbitration will be administered by the JAMS in accordance with its Comprehensive Arbitration Rules and Procedures 
        and the JAMS Consumer Minimum Standards (together, the “Rules”) then in effect (those rules are deemed to be incorporated by reference 
        to this section, and as of the date of this Content License). Arbitration shall be conducted by one (1) arbitrator as selected pursuant 
        to the Rules; the arbitrator’s award shall be final and binding and may be entered as a judgment in any court of competent jurisdiction. 
        You have a right to have the arbitration conducted via telephone, or as an in-person hearing in your hometown area (if you live in the 
        United States) or another location that is reasonably convenient to you.
        <br/>
        iii. Payment for any and all reasonable JAMS filing, administrative and arbitrator fees will be in accordance with the Rules. 
        If the value of your claim does not exceed $10,000, Published NFT will pay for the reasonable filing, administrative and arbitrator 
        fees associated with the arbitration, unless the arbitrator finds that either the substance of your claim or the relief sought was 
        frivolous or brought for an improper purpose, except that if you have initiated the arbitration claim, you will still be required 
        to pay the lesser of $250 or the maximum amount permitted under the JAMS Rules for arbitration claims initiated by you. you are 
        still responsible for all additional costs that you incur in the arbitration, including without limitation, fees for attorneys or 
        expert witnesses.
        <br/>
        <b>
        16. c. Opt-Out. You have the right to opt out of binding arbitration and the waiver of class provisions set forth in this Content License 
        within thirty (30) days of agreeing to this Content License (or, if this Section 12 is amended hereafter, within thirty (30) days of 
        such amendment being effective) by notifying us of your decision to opt-out with a communication labeled “Arbitration Opt-Out” to 
        legal@publishednft.io or by mail at Published NFT, LLC, 211 North Michigan Ave Lafayette CO 80026
        </b>
        <br/>
        <b>
        17. Attention: Licensing, otherwise you shall be bound to arbitrate disputes in accordance with these this Content License. 
        In order to be effective, the opt-out notice must include your full name and your mailing address. If you opt-out of these 
        arbitration provisions, Published NFT also will not be bound by them. If you have decided to opt-out of Binding Arbitration, 
        all Disputes shall be heard in a court of competent jurisdiction.
        </b>
        <br/>
        18. d. Exceptions to Arbitration. Notwithstanding anything in this Content License to the contrary, you may instead assert your 
        Dispute in “small claims” court, but only if your Dispute qualifies, your Dispute remains only in court, and your Dispute remains 
        on an individual and non-representative and non-class basis. Further, you and Published NFT will have the right to bring an action 
        in a court of proper jurisdiction for: (i) any Dispute related to actual or threatened infringement, misappropriation or violation 
        of a party’s copyrights, trademarks, trade secrets, patents, or other intellectual property rights; or (ii) injunctive or other 
        equitable or conservatory relief. Such claims shall be brought before a judicial proceeding in a court of competent jurisdiction.
        <br/>
        <b>
        19.e. Waiver of Class Actions and Class Arbitrations.
        <br/>
        i. YOU AND Published NFT UNDERSTAND AND AGREE THAT YOU, AND Published NFT MAY EACH BRING CLAIMS AGAINST THE OTHER PARTY ONLY IN AN INDIVIDUAL 
        CAPACITY AND NOT ON A CLASS, COLLECTIVE ACTION, OR REPRESENTATIVE BASIS, INCLUDING, WITHOUT LIMITATION, FEDERAL OR STATE CLASS ACTIONS, 
        OR CLASS ARBITRATIONS. YOU UNDERSTAND AND AGREE THAT YOU AND Published NFT EACH ARE WAIVING THE RIGHT TO PURSUE OR HAVE A DISPUTE 
        RESOLVED AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE OR REPRESENTATIVE PROCEEDING. ACCORDINGLY, UNDER THE 
        ARBITRATION PROCEDURES OUTLINED IN THIS SECTION, AN ARBITRATOR SHALL NOT COMBINE OR CONSOLIDATE MORE THAN ONE PARTY’S CLAIMS WITHOUT 
        THE WRITTEN CONSENT OF ALL AFFECTED PARTIES TO AN ARBITRATION PROCEEDING. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU AND 
        Published NFT AGREE THAT NO DISPUTE SHALL PROCEED BY WAY OF CLASS ARBITRATION WITHOUT THE WRITTEN CONSENT OF ALL AFFECTED PARTIES. 
        <br/>
        ii. NOTWITHSTANDING THE FOREGOING, THIS SUBSECTION SHALL NOT APPLY TO REPRESENTATIVE PRIVATE ATTORNEYS GENERAL ACT CLAIMS BROUGHT 
        AGAINST Published NFT, WHICH ARE ADDRESSED SEPARATELY.
        </b>
        <br/>
        20. f. PAGA Claims. Notwithstanding any other provision of this Content License or the arbitration provisions, to the fullest 
        extent permitted by law: (1) you and Published NFT agree not to bring a representative action on behalf of others under 
        the Private Attorneys General Act of 2004 (“PAGA”), California Labor Code § 2698 et seq., or any subsequent law, in any 
        court or in arbitration, and (2) for any claim brought on a private attorney general basis, including under the California PAGA, 
        both you and Published NFT agree that any such dispute shall be resolved in arbitration on an individual basis only (i.e., 
        to resolve whether you have personally been aggrieved or subject to any violations of law), and that such an action may 
        not be used to resolve the claims or rights of other individuals in a single or collective proceeding (i.e., to resolve 
        whether other individuals have been aggrieved or subject to any violations of law) (collectively, “representative PAGA Waiver”). 
        Notwithstanding any other provision of this Content License, the arbitration section or the associated rules, disputes regarding 
        the scope, applicability, enforceability, revocability or validity of this representative PAGA Waiver may be resolved only by a 
        civil court of competent jurisdiction and not by an arbitrator. If any provision of this representative PAGA Waiver is found to
        be unenforceable or unlawful for any reason: (i) the unenforceable provision shall be severed from this Content License; (ii) 
        severance of the unenforceable provision shall have no impact whatsoever on any arbitration provisions or the requirement that 
        any remaining claims be arbitrated on an individual basis pursuant to the arbitration provisions; and (iii) any such representative 
        PAGA or other representative private attorneys general act claims must be litigated in a civil court of competent jurisdiction 
        and not in arbitration. To the extent that there are any Disputes to be litigated in a civil court of competent jurisdiction 
        because a civil court of competent jurisdiction determines that the representative PAGA Waiver is unenforceable with respect 
        to those Disputes, the parties agree that litigation of those Disputes shall be stayed pending the outcome of any individual 
        Disputes in arbitration.
        <br/>
        21. This Content License does not, and shall not be construed to, create any partnership, joint venture or agency between you and any 
        Content Provider. If any provision of this Content License is found to be unlawful, void or for any reason unenforceable, that 
        provision will be deemed severable from this Content License and will not affect the validity and enforceability of any remaining 
        provisions. You shall not assign, transfer or otherwise dispose of this Content License (including any of your rights or obligations 
        under this Content License), and any purported assignment, transfer or other disposition shall be null and void. This Content License 
        along with the Terms constitute the entire agreement between you and Providers with respect to the Digital Collectible and the 
        Creative Work and supersedes any and all prior or contemporaneous written or oral agreements or understandings between you and 
        Providers relating to the Digital Collectible or the Creative Work.
      </p>
      <p>
        Copyright ©2022 Published NFT, Inc. (d/b/a Published NFT). All rights reserved.
      </p>
    </div>
  )
}