export const GET_GROUND_WITH_DATA = `
import MetadataViews from 0xNFT
import GroundNFTStorefrontV2 from 0xPublishedNFT
import GroundWork29 from 0xPublishedNFT

access(all) struct PurchaseData {
    access(all) let nftGlobalId: UInt64
    access(all) let nftSerialId: UInt64
    access(all) let type: String
    access(all) let listingId: UInt64

    init(nftGlobalId: UInt64, nftSerialId: UInt64, type: String, listingId: UInt64) {
        self.nftGlobalId = nftGlobalId
        self.nftSerialId = nftSerialId
        self.type = type
        self.listingId = listingId
    }
}

access(all) fun main(address: Address): [PurchaseData] {

    let purchaseDataArray: [PurchaseData] = []

    let account = getAccount(address)
    let marketCollectionRef = account.capabilities.get<&GroundNFTStorefrontV2.Storefront>(
      GroundNFTStorefrontV2.StorefrontPublicPath
    ).borrow()
    ?? panic("Could not borrow market collection from address")

    let listId = marketCollectionRef.getListingIDs()

    for id in listId {
        let saleItem = marketCollectionRef.borrowListing(listingResourceID: id)
            ?? panic("No item with that ID")

        let listingDetails = saleItem.getDetails()

        let collection = account.capabilities.get<&GroundWork29.Collection>(GroundWork29.CollectionPublicPath)
            .borrow()
            ?? panic("Could not borrow a reference to the collection")

        let nftId = listingDetails.nftID

        if (collection.borrowGroundWork29(id: nftId) != nil) {
            let nft = collection.borrowGroundWork29(id: nftId)
                ?? panic("Could not borrow a reference to the collection")

            let viewSerial = nft.resolveView(Type<MetadataViews.Serial>())!
            let displayView = viewSerial as! MetadataViews.Serial

            if let view = nft.resolveView(Type<MetadataViews.Display>()) {
                let display = view as! MetadataViews.Display

                let purchaseData = PurchaseData(
                    nftGlobalId: nftId,
                    nftSerialId: displayView.number,
                    type: display.name,
                    listingId: id
                )

                purchaseDataArray.append(purchaseData)
            }
        }
    }

    return purchaseDataArray
}
`