export const INIT_PUBLISHED_DAPPER = `
import NonFungibleToken from 0xNFT
import MetadataViews from 0xNFT
import PublishedNFT from 0xPublishedNFT

transaction {
    prepare(acct: AuthAccount) {
      acct.save(<- PublishedNFT.createEmptyCollection(), to: PublishedNFT.CollectionStoragePath)
      acct.link<&PublishedNFT.Collection{NonFungibleToken.CollectionPublic, PublishedNFT.PublishedNFTCollectionPublic, MetadataViews.ResolverCollection}>(
              PublishedNFT.CollectionPublicPath, 
              target: PublishedNFT.CollectionStoragePath
          )
    }
  
    execute {}
  }
`