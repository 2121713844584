import { useEffect, useState } from 'react';
import { Col, Card, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaCaretSquareRight } from 'react-icons/fa';

export default function NftItem(props) {
  const navigate = useNavigate();
  const [isPlay, setIsPlay] = useState("false");
  const [show, setShow] = useState(false);

  useEffect(() => {
    setIsPlay("false");
  }, []);

  const thumbClick = (type) => {
    if(type === "Genesis Library Pass"){
      navigate("/description/1", { state: { type: 1, nftSerialId: props.data.nftSerialId } });
    }else if(type === "The Wisp"){
      navigate("/description/wisp", { state: { type: 2, nftSerialId: props.data.nftSerialId } });
    }else if(type === "Groundwork 29"){
      navigate("/description/groundwork29", { state: { type: 3, nftSerialId: props.data.nftSerialId } });
    }else if(type === "Boast"){
      navigate("/description/boast6", { state: { type: 4, nftSerialId: props.data.nftSerialId } });
    }else if(type === "49 Syllables on life"){
      navigate("/description/syllables", { state: { type: 5, nftSerialId: props.data.nftSerialId } });
    }
  }
  const playClick = () => {
    setIsPlay("true");
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clickRead = (type) => {
    navigate("/book", { state: { type: type } });
  };

  return (
    <>
      <Col >
        <Card>
          {(props.data.type === "Genesis Library Pass" || props.data.type === "The Wisp") ?
            <>
              <div className='d-flex justify-content-end mb-2'>
                <FaCaretSquareRight className='text-primary' style={{ cursor: 'pointer' }} onClick={() => handleShow()}/>
              </div>
              <>
              {isPlay === "false" ?
              <Card.Img src={props.thumbUrl} style={{ cursor: 'pointer' }} 
                onMouseOver={() => setIsPlay("true")} width="100%" height="auto" 
              />
              :
              <video width="100%" height="auto" controls autoPlay role="button"
              onMouseLeave={() => setIsPlay("false")} onClick={() => thumbClick(props.data.type)}>
                <source src={props.assetUrl} type="video/mp4"/>
              </video>
              }
              </>
            </>     
          :
          <Card.Img className='book-card' src={props.thumbUrl} style={{ cursor: 'pointer' }} 
          onClick={() => thumbClick(props.data.type)} />
          }          

          <div className='mt-3'> 
            {props.data.type === "The Wisp" &&
              <div className='d-flex justify-content-between'>
                <p className='text-white mb-1'>Stories</p>
                <p className='green-font mb-1'>#{props.data.nftSerialId}/100</p>
              </div>            
            }

            {props.data.type === "Genesis Library Pass" &&
              <div className='d-flex justify-content-between mt-4'>
                <p className='text-white mb-1'>Passes</p>
                <p className='green-font mb-1'>#{props.data.nftSerialId}/10K</p>
              </div>            
            }

            {(props.data.type === "Groundwork 29" || props.data.type === "49 Syllables on life") &&
              <div className='d-flex justify-content-between'>
                <p className='text-white mb-1'>eBooks</p>
                <p className='green-font mb-1'>#{props.data.nftSerialId}/2k</p>
              </div>            
            }        

            {props.data.type === "Boast" &&
              <div className='d-flex justify-content-between'>
                <p className='text-white mb-1'>Magazines</p>
                <p className='green-font mb-1'>#{props.data.nftSerialId}/10k</p>
              </div>            
            }  
          </div>

          {props.data.type === "Boast" ?
            <h5 className='text-white'>Boast Magazine Issue #6</h5>
          :
            <h5 className='text-white'>{props.data.type}</h5>
          }
          
          {props.data.type === "Genesis Library Pass" &&
            <Button variant="warning" className="text-white font-weight-bold w-50 m-auto mt-3" disabled>
              Transfer
            </Button>
          }

          {props.data.type === "Groundwork 29" && 
            <Button variant="primary" className="font-weight-bold w-50 m-auto mt-3" 
            onClick={() => clickRead("groundwork29")} >
              Read
            </Button>
          }

          {props.data.type === "Boast" && 
            <Button variant="primary" className="font-weight-bold w-50 m-auto mt-3" 
            onClick={() => clickRead("boast")} >
              Read
            </Button>
          }

          {props.data.type === "49 Syllables on life" && 
            <Button variant="primary" className="font-weight-bold w-50 m-auto mt-3" 
            onClick={() => clickRead("syllables")} >
              Read
            </Button>
          }
        </Card>
      </Col>

      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton closeVariant='white'>
        </Modal.Header>
        <Modal.Body>
          <video width="100%" height="auto" controls autoPlay>
            <source src={props.assetUrl} type="video/mp4"/>
          </video>
        </Modal.Body>
      </Modal>
    </>
    
  )
}