import { Suspense, useRef } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from '@react-three/drei';
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FaGlobe } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from 'react-bootstrap';

{/* <Canvas className='new-book' camera={{ position: [0, 0.5, 1] }}> 
  <ambientLight />
  <Suspense fallback={null}>
    <Model />
  </Suspense>
  <OrbitControls target={[0, 0, 0]} />
</Canvas> */}

function Model() {
  const gltf = useLoader(GLTFLoader, '/models/Prototype1.glb');
  const modelRef = useRef();
  console.log("gltf - ", gltf);

  // Manipulate the loaded model or access its properties here
  // Example: modelRef.current.rotation.y += 0.01;

  return <primitive object={gltf.scene} ref={modelRef} position={[0, -0.5, 0]} scale={4} />;
}

export default function NewBook() {

  return (
    <div className='container'>
      <h1 className="text-center mt-3">
        3D Book
      </h1>      

      <div className="row p-3">
        <div className="col-md-7 mt-5 d-flex justify-content-center align-items-center">
          <div style={{width: "100%"}}>
            <div className='new-book-pad'>
              <h5 className='text-white new-book-title p-2 text-center'>
                3D Book
              </h5>
              <Canvas className='new-book' camera={{ position: [0, 1, 1] }}> 
                <ambientLight />
                <Suspense fallback={null}>
                  <Model />
                </Suspense>
                <OrbitControls target={[0, 0, 0]} />
              </Canvas>
            </div>

            <div className="d-flex justify-content-center mt-3 mb-5">
              <div>
                <a target="_blank" href="https://www.flowty.io/collection/0x52cbea4e6f616b8e/PublishedNFT/activity/rentals">
                  <img
                    className="me-2"
                    alt=""
                    src="/white-flowty.png"
                    width="20"
                    height="25"
                  />
                </a>
                <a href="https://evaluate.xyz/swap" target="_blank">
                  <img
                    className="me-2"
                    alt=""
                    src="/nft_transfer_logo.png"
                    width="50"
                    height="45"
                  />
                </a>
                <a href="https://ipfs-scan.io/?cid=bafybeih4udfjcdrwhbga66ucddajuj7iekoicl4ks7h72jdcrkll2ftvpy">
                  <img className="me-2" alt="" src="/ipfs.png" width="30" height="30" />
                </a>
                <a href="https://flowscan.org/account/0x52cbea4e6f616b8e/transfers" target="_blank">
                  <img
                    className="me-2"
                    alt=""
                    src="/built-on-flow.png"
                    width="150"
                    height="30"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <div className="me-2 description-pad mt-5 mb-5 p-3">
            <h2 className="mb-2">Genesis Library Pass</h2>            

            <p className="font-weight-bold mt-5">DESCRIPTION</p>
            <p className="mb-5" style={{ whiteSpace: "pre-line" }}>
            Library Passes are no longer those ordinary pieces of plastic you have to bring to the library. 
            Instead of library passes once used to sign out eBooks, now you will have access utility to digital NFT eBooks. 
            These membership NFTs can be used on the Published NFT platform; which will give the holder of the NFT full access to most 
            all eBooks, magazines, articles, comics and more. Holders of the library pass will receive lifetime airdrops and VIP metaverse museum access. 
            Users will be able to read and interact with any eBook or items from specific categories. 
            If you own a Genesis Library Pass you will also have full VIP to all categories.
            </p>

            <div className="row">
              <div className="col-md-6">
                <p className="font-weight-bold">PRICE</p>
                <div className="d-flex">
                  <h5 className="green-border font-weight-bold p-3 me-3 mb-0 pt-4">
                    TBD
                  </h5>
                  <div className="white-border font-weight-bold p-3">
                    <p className="font-sm mb-1">TOTAL CREATED</p>
                    <h5 className="mb-0">
                      TBD
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="d-flex">
                  <p className="font-weight-bold me-2">AUTHOR</p>
                  <a className="me-2 text-white" href="https://twitter.com/publishednft" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a className="me-2 text-white" href="https://www.instagram.com/publishednft/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a className="me-2 text-white" href="https://publishednft.io/" target="_blank" rel="noreferrer">
                    <FaGlobe className="text-white mb-1" />
                  </a>
                  <a className="me-4 text-white mb-1" href="" target="_blank" rel="noopener noreferrer"
                  >
                    <img src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Transparent%20White.svg"
                      alt="" width="16px" height="16px" style={{ marginBottom: "3px" }} />
                  </a>
                </div>

                <div className="d-flex align-items-center">
                  <img src="/published_proflile_0-00-00-00.png" className="profile_img" />
                  <p className="mx-2">Published NFT</p>
                </div>
              </div>
            </div>

            <Button
              className="white-border-button text-white font-weight-bold mt-3 p-3"
            >
              VIEW ALL AVAILABLE
            </Button>
          </div>
        </div>
      </div>
    </div>    
  )
}