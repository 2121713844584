import * as fcl from "@onflow/fcl"

// Dapper wallet //
fcl
  .config()
  .put('flow.network', 'testnet')
  .put('accessNode.api', 'https://rest-testnet.onflow.org')
  .put('discovery.wallet', 'https://fcl-discovery.onflow.org/testnet/authn')
  .put('discovery.authn.endpoint', 'https://fcl-discovery.onflow.org/api/testnet/authn')
  .put('discovery.authn.include', ['0x82ec283f88a62e65'])
  .put('walletconnect.projectId', 'c80430ac1a6a853dc9cc310470f7a8aa')
  .put('app.detail.title', 'Published NFT')
  .put('app.detail.icon', 'https://publishednft.io/img/logo.png')
  .put('app.detail.description', 'E-book publishing platform')
  .put('app.detail.url', 'https://publishednft.io/')
  .put('0xFungibleToken', '0x9a0766d93b6608b7')
  .put('0xFUSD', '0xe223d8a629e49c68')
  .put('0xNFT', '0x631e88ae7f1d7c20')
  .put('0xStorefront', '0x94b06cfca1d8a476')
  .put('0xPublishedNFT', '0x00af2ab363fa49e4')
  .put('0xMerchant', '0x90870362be46fb8d')
  .put('0xDapperUtilityCoin', '0x82ec283f88a62e65')

// config({
//   "flow.network": "testnet",
//   "accessNode.api": "https://rest-testnet.onflow.org",
//   "discovery.wallet": "https://fcl-discovery.onflow.org/testnet/authn",
//   "discovery.authn.endpoint": "https://fcl-discovery.onflow.org/api/testnet/authn",
//   "discovery.authn.include": ["0x82ec283f88a62e65"],
//   'discovery.wallet.method': "POP/RPC",
//   '0xFungibleToken': "0x9a0766d93b6608b7",
//   '0xFUSD': "0xe223d8a629e49c68",
//   '0xNFT': "0x631e88ae7f1d7c20",
//   '0xStorefront': "0x94b06cfca1d8a476",
//   '0xPublishedNFT': "0x00af2ab363fa49e4",
//   "0xMerchant": "0x90870362be46fb8d",
//   "0xDapperUtilityCoin": "0x82ec283f88a62e65"
// })
// Dapper wallet //



// Lilico Wallet //
// mainnet
// config({
//   "accessNode.api": "https://access-mainnet-beta.onflow.org",
//   "discovery.wallet": "https://fcl-discovery.onflow.org/authn", 
//   '0xFungibleToken': "0xf233dcee88fe0abe",
//   '0xFUSD': "0x3c5959b568896393",
//   '0xNFT': "0x1d7e57aa55817448",
//   '0xLibraryPass': "0x52cbea4e6f616b8e",
//   '0xPublishedNFT': "0x52cbea4e6f616b8e"
// })

// Configure SDK to use HTTP
// fcl
//   .config()
//   .put("accessNode.api", "https://rest-testnet.onflow.org")
//   .put("sdk.transport", httpSend)

// If you wanna skip the discovery ui to connect with lilico directly
// fcl.config
// .put("discovery.wallet.method", "EXT/RPC")
// .put("discovery.wallet", "chrome-extension://hpclkefagolihohboafpheddmmgdffjm/popup.html")

// fcl.config()
//   .put("app.detail.title", "Published NFT")
//   .put("app.detail.icon", "https://publishednft.io/logo-desktop.png")
// Lilico Wallet //


// testnet
// config({
//   "accessNode.api": "https://access-testnet.onflow.org",
//   "discovery.wallet": "https://flow-wallet-testnet.blocto.app/authn",
//   '0xFungibleToken': "0x9a0766d93b6608b7",
//   '0xFUSD': "0xe223d8a629e49c68",
//   '0xNFT': "0x631e88ae7f1d7c20",
//   '0xLibraryPass': "0x4d79d7aa8c56c615",
//   '0xPublishedNFT': "0x4d79d7aa8c56c615"
// })

// local
// config({
//   "accessNode.api": "http://localhost:8080", 
//   "discovery.wallet": "http://localhost:8701/fcl/authn", 
//   '0xFungibleToken': "0xee82856bf20e2aa6",
//   '0xFUSD': "0xf8d6e0586b0a20c7",
//   '0xNFT': "0xf8d6e0586b0a20c7",
//   '0xLibraryPass': "0xf8d6e0586b0a20c7",
//   '0xNFTStorefront': "0xf8d6e0586b0a20c7"
// })