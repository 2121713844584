export default function Marketplace(){
  return(    
    <div className='coming-pad container text-center'>
      <h1 className='mt-5'>Coming Soon!</h1>
      <div className="d-flex justify-content-center">
        <p className='mt-3 mx-3'>Powered By</p>
        <img className='mt-3' src='/images/dapper-logo.png' alt='' width='100px' height='25px' />
      </div>      
    </div>  
  )
}