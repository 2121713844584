import { useEffect, useState } from 'react';
import * as fcl from "@onflow/fcl";
import { Row, Button } from "react-bootstrap";
import { GET_PUBLISHEDS_WITH_DATA } from '../cadence/script/LibraryPass/getPublishedsWithData';
import { getOwnGround } from "../cadence/script/GroundWork29/getOwnGround";
import { getOwnBoast } from '../cadence/script/Boast/getOwnBoast';
import { getOwnSyllables } from '../cadence/script/Syllable/getOwnSyllables';
import NftItem from '../components/NftItem';
import Category from '../components/Category';
import { INIT_Syllables_DAPPER } from '../cadence/script/Syllable/init_Syllables_dapper';

export default function Collection() {
  const [user, setUser] = useState({ loggedIn: null });
  const [videos, setVideos] = useState([]);
  const [passes, setPasses] = useState([]);
  const [category, setCategory] = useState('All');
  const [passesActive, setPassesActive] = useState(false);
  const [videosActive, setVideosActive] = useState(false);
  const [ebooksActive, setEbooksActive] = useState(false);
  const [magazinesActive, setMagazinesActive] = useState(false);
  const [allActive, setAllActive] = useState(true);
  const [groundData, setGroundData] = useState([]);
  const [boast, setBoast] = useState([]);
  const [syllable, setSyllable] = useState([]);
  var passesContent = '';
  var videosContent = '';
  var eBooksContent = '';
  var magazinesContent = '';
  var content = "";

  useEffect(() => {
    fcl.currentUser.subscribe(setUser);
  }, []);

  useEffect(() => {
    getNFTs();
  }, [user]);

  const getNFTs = async () => {
    if (user.loggedIn === null) return;

    // const res = await fcl.query({
    //   cadence: GET_PUBLISHEDS_WITH_DATA,
    //   args: (arg, t) => [arg(user?.addr, t.Address)],
    // });

    // // console.log(res);

    // let videoTemp = [];
    // let passesTemp = [];

    // res.map((r, index)=>{
    //   if(r.type === "The Wisp"){
    //     r.isPlay = false;
    //     videoTemp.push(r);
    //   }else if(r.type === "Genesis Library Pass"){
    //     r.isPlay = false;
    //     passesTemp.push(r);
    //   }     
    //   return null;
    // });

    // setVideos(videoTemp);
    // setPasses(passesTemp);

    fcl.query({
      cadence: getOwnGround,
      args: (arg, t) => [arg(user?.addr, t.Address)], //mainnet
    }).then(setGroundData).catch(console.log);

    // fcl.query({
    //   cadence: getOwnBoast,
    //   args: (arg, t) => [arg(user?.addr, t.Address)], //mainnet
    // }).then(setBoast).catch(console.log);

    // fcl.query({
    //   cadence: getOwnSyllables,
    //   args: (arg, t) => [arg(user?.addr, t.Address)], //mainnet
    // }).then(setSyllable).catch(console.log);
  };

  const init = async () => {
    const txid = await fcl.mutate({
      cadence: INIT_Syllables_DAPPER,
      args: (arg, t) => [],
      proposer: fcl.currentUser,
      payer: fcl.currentUser,
      authorizations: [fcl.currentUser],
      limit: 999,
    });

    console.log("init syllable - ", txid);
  }

  // console.log('pass - ', passes);
  console.log('ground - ', groundData);
  // console.log('boast - ', boast);
  // console.log("syllable - ", syllable);

  if(allActive || passesActive){
    passesContent = 
    <>
      {passes.map((data, index) => {
        var assetUrl = './NFT Library Pass Final.mp4';
        var thumbUrl = './black_back_pass.png';

        return (
          <NftItem key = {index} assetUrl = {assetUrl} thumbUrl = {thumbUrl} index = {index} data = {data}></NftItem>
        )            
      })}
    </>
  }else{
    passesContent = <></>;
  }

  if(allActive || videosActive){
    videosContent = 
    <>
      {videos.map((data, index) => {
        var assetUrl = './second-type.mp4';
        var thumbUrl = './wilo_with_play.png';

        return (
          <NftItem key = {index} assetUrl = {assetUrl} thumbUrl = {thumbUrl} index = {index} data = {data}></NftItem>
        )            
      })}
    </>
  }else{
    videosContent = <></>;
  }

  if(allActive || ebooksActive){
    eBooksContent = 
    <>
      {groundData.map((data, index) => {
        var assetUrl = './feature-01.png';
        var thumbUrl = './feature-01.png';

        return (
          <NftItem key = {index} assetUrl = {assetUrl} thumbUrl = {thumbUrl} index = {index} data = {data}></NftItem>
        )            
      })}

      {syllable.map((data, index) => {
        var assetUrl = './feature-06.png';
        var thumbUrl = './feature-06.png';

        return (
          <NftItem key = {index} assetUrl = {assetUrl} thumbUrl = {thumbUrl} index = {index} data = {data}></NftItem>
        )            
      })}
    </>
  }else{
    eBooksContent = <></>;
  }

  if(allActive || magazinesActive){
    magazinesContent = 
    <>
      {boast.map((data, index) => {
        var assetUrl = './BOAST_MAGAZINE_ISSUE6.png';
        var thumbUrl = './BOAST_MAGAZINE_ISSUE6.png';

        return (
          <NftItem key = {index} assetUrl = {assetUrl} thumbUrl = {thumbUrl} index = {index} data = {data}></NftItem>
        )            
      })}
    </>
  }else{
    magazinesContent = <></>;
  }

  return (
    <div className='container text-center'>
      <div className='d-flex justify-content-center mt-5 mb-2'>
        <h1>My Library</h1>
      </div>
      <div className='mb-5'>
        <div className='row mb-2'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-6 d-flex mb-3 justify-content-end p-0'>
                <Category text='All' allActive = {allActive} setAllActive = {setAllActive} 
                setPassesActive = {setPassesActive} setVideosActive = {setVideosActive}
                setEbooksActive = {setEbooksActive} setMagazinesActive = {setMagazinesActive}  />
                <Category text='Passes' passesActive = {passesActive} setPassesActive = {setPassesActive}
                setAllActive = {setAllActive} />
                <Category text='Stories' videosActive = {videosActive} setVideosActive = {setVideosActive} 
                setAllActive = {setAllActive} />
                <Category text='eBooks' ebooksActive = {ebooksActive} setEbooksActive = {setEbooksActive} 
                setAllActive = {setAllActive} />
              </div>
              <div className='col-md-6 d-flex mb-3 p-0'>
                <Category text='Magazines' magazinesActive = {magazinesActive}  setMagazinesActive = {setMagazinesActive} 
                setAllActive = {setAllActive} />
                <Category text='Comics' setAllActive = {setAllActive}/>
                <Category text='Subscriptions' setAllActive = {setAllActive}/>
              </div>
            </div>
          </div>          
        </div>
      </div>
            
      <Row xs={1} md={4} className="g-4 mb-5">
        {passesContent}
        {videosContent}
        {eBooksContent}
        {magazinesContent}
      </Row>
    </div>
  )
}